/* eslint-disable import/no-cycle */
import { BoxManager } from './BoxManager';

/**
 * @type {BoxManager|null}
 */
let inst = null;

/**
 * @return {BoxManager}
 */
export const getBoxManager = () => {
  if (inst === null) {
    inst = new BoxManager();

    inst.listenHotkeys();
  }

  return inst;
};

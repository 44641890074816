import { API_URL } from '~/app.config';
import getFormData from '~/assets/libs/getFormData';
import createFetch from '~/assets/libs/createFetch';
import getResponseJson from '~/assets/libs/getResponseJson';
import { getRefreshTokenHeader } from '~/assets/helpers/token';

/**
 * @param {Object} payload
 * @param {Object} payload.authData
 * @param {string} payload.authData.email
 * @param {string} payload.authData.password
 * @return {Promise<TokensResponse>}
 * */
export async function authenticate(payload) {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Authentication failed',
    errors: '',
    data: {
      accessToken: '',
      refreshToken: '',
      expiresIn: '',
    },
  };
  const { authData } = payload;
  const formData = getFormData(authData);
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/authenticate', {
      method: 'POST',
      body: formData,
    });
    if (!raw.ok) {
      response.code = raw.status;
      switch (raw.status) {
        case 403:
          response.errorsTitle = 'User suspended';
          break;
        case 422:
          response.errorsTitle = 'Wrong email or password';
          break;
        default:
          break;
      }
      return response;
    }
    const { token, refreshToken, expiresIn } = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errorsTitle = '';
    response.errors = '';
    response.data.accessToken = token;
    response.data.refreshToken = refreshToken;
    response.data.expiresIn = expiresIn;
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
}

/**
 * @return {Promise<TokensResponse>}
 * */
export async function refreshTokens() {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Session extension failed',
    errors: '',
    data: {
      accessToken: '',
      refreshToken: '',
      expiresIn: '',
    },
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/refresh-token', {
      method: 'POST',
      headers: getRefreshTokenHeader(),
    });
    const data = await getResponseJson(raw);
    if (!raw.ok) {
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errorsTitle = '';
    response.errors = '';
    response.data.accessToken = data.token;
    response.data.refreshToken = data.refreshToken;
    response.data.expiresIn = data.expiresIn;
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
}

/**
 * @typedef {Object} TokensData
 * @property {string} accessToken
 * @property {string} refreshToken
 * @property {string} expiresIn
 * */

/**
 * @typedef {Object} TokensResponse
 * @property {'error'|'success'} status
 * @property {number} code=520
 * @property {string} errorsTitle
 * @property {string} errors
 * @property {TokensData} data
 * */

/* eslint-disable import/no-cycle, no-restricted-syntax */
import isEqual from 'lodash/isEqual';
import { actions as documentActions } from '~/store/reducers/document';
import { UndoRedoHistory } from './UndoRedoHistory';
import { BoxState } from './BoxState';
import { DOMSelectionRange } from './DOMSelectionRange';
import { OnceState } from './OnceState';
import { normalizeKey, checkBoxReplaceable, isSameBoxKey } from './utils';

/**
 * Single Box store
 */
export class BoxViewModel {
    /**
     * @type {BoxFocusCallback}
     * @private
     * @readonly
     */
    _focusCallback;

    /**
     * @type {UndoRedoHistory<BoxState>}
     * @private
     * @readonly
     */
    _history;

    /**
     * @type {import('redux').Store}
     * @private
     * @readonly
     */
    _reduxStore;

    /**
     * Last change caused by input
     *
     * @private
     */
    _didInput = new OnceState();

    /**
     * Updating from snapshot
     *
     * @private
     */
    _ignoreNextSocketUpdate = new OnceState();

    /**
     * @private
     */
    _forceRender = new OnceState();

    /**
     * @type {BoxKey|null}
     * @private
     */
    _boxKey = null;

    /**
     * @type {DOMSelectionRange|null}
     * @private
     */
    _selectionRangeByLastInput = null;

    /**
     * @param {BoxFocusCallback} focusCallback
     * @param {BoxKey} boxKey
     * @param {import('redux').Store} reduxStore
     * @param {number} historyLimit
     */
    constructor(focusCallback, boxKey, reduxStore, historyLimit) {
      this._focusCallback = focusCallback;
      this._boxKey = normalizeKey(boxKey);
      this._reduxStore = reduxStore;
      this._history = new UndoRedoHistory(historyLimit);
    }

    /**
     * @return {boolean}
     */
    checkIgnoreSocketUpdate() {
      return this._ignoreNextSocketUpdate.check();
    }

    /**
     * @return {boolean}
     */
    checkForceRender() {
      return this._forceRender.check();
    }

    /**
     * When user selected text
     *
     * @param {Node} node
     * @return {void}
     */
    onSelect(node) {
      const currentState = this._history.current();
      const withNewRange = BoxState.create(
        currentState.getPayload(),
        DOMSelectionRange.capture(node),
      );

      this._history.replace(withNewRange);
    }

    /**
     * When user did input
     *
     * @param {Node} node
     * @return {void}
     */
    onInput(node) {
      this._didInput.toggleOn();
      this._selectionRangeByLastInput = DOMSelectionRange.capture(node);
    }

    /**
     * @returns {DOMSelectionRange|null}
     */
    getCurrentSelectionRange() {
      return this._history.current().getSelectionRange();
    }

    /**
     * When box updated by current session
     *
     * @note captures box state from Redux so call only when state contains last changes
     */
    onUpdateByCurrentSession() {
      const lastState = this._history.current();
      const capturedState = this._captureCurrentState();
      const lastPayload = lastState.getPayload();
      const capturedPayload = capturedState.getPayload();

      if (isEqual(lastPayload.content, capturedPayload.content)) return;

      if (
        this._history.hasPushed()
            && this._didInput.check()
            && checkBoxReplaceable(lastPayload, capturedPayload)
      ) {
        this._history.replace(capturedState);
        this._history.forgetRedo();
        return;
      }

      this._history.push(capturedState);
    }

    onFocus() {
      this._focusCallback(this._ensureBoxKey());
      this._selectionRangeByLastInput = null;
    }

    /**
     * @return {boolean}
     */
    undo() {
      if (!this._history.canUndo()) return false;

      this._history.undo();
      this._updateFromSnapshot(this._history.current());

      return true;
    }

    /**
     * @return {boolean}
     */
    redo() {
      if (!this._history.canRedo()) return false;

      this._history.redo();
      this._updateFromSnapshot(this._history.current());

      return true;
    }

    /**
     * @return {void}
     */
    reset() {
      const snapshot = this._captureCurrentState();
      this._history.reset(snapshot);
      this._ignoreNextSocketUpdate.reset();
      this._didInput.reset();
      this._forceRender.reset();
      this._selectionRangeByLastInput = null;
    }

    /**
     * @return {BoxKey}
     * @private
     */
    _ensureBoxKey() {
      if (this._boxKey === null) throw new Error('no box key set');

      return this._boxKey;
    }

    /**
     * Has attached DOM Node and its editable by user
     *
     * @private
     * @return {boolean}
     */
    _hasAttachedEditableNode() {
      if (!this._domNode) return false;

      return (
      // attached to ViewModel
        Boolean(this._domNode)

            // connected to DOM
            && this._domNode.isConnected

            // editable
            && (
        // is content editable div
              (this._domNode.isContentEditable === true || this._domNode.contentEditable === 'true')

                // or input/textarea element
                || (['input', 'textarea'].includes(this._domNode.tagName.toLowerCase()))
            )
      );
    }

    /**
     * @return {BoxState}
     * @private
     */
    _captureCurrentState() {
      return BoxState.create(this._findStoreBox(), this._selectionRangeByLastInput);
    }

    /**
     * @param {BoxState} snapshot
     * @return {void}
     * @private
     */
    _updateFromSnapshot(snapshot) {
      const snapshotBox = { ...this._ensureBoxKey(), ...snapshot.getPayload() };

      this._forceRender.toggleOn();
      this._ignoreNextSocketUpdate.toggleOn();
      this._reduxStore.dispatch(documentActions.updateBox(snapshotBox, true));
    }

    /**
     * Find existing box in Redux document state
     *
     * @return {BoxPayload}
     * @private
     */
    _findStoreBox() {
      const storeBoxes = this._reduxStore.getState().document.boxes;
      const boxKey = this._ensureBoxKey();

      for (const storeBox of storeBoxes) {
        if (isSameBoxKey(boxKey, storeBox)) {
          this._boxKey = normalizeKey(storeBox);
          return storeBox;
        }
      }

      throw new Error('box not found in store');
    }
}

import React from 'react';
import propTypes from 'prop-types';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useIsAuthed, usePermissions, useProfile } from '~/assets/hooks/useProfile';
import { ROUTES_PERMISSIONS } from '~/assets/helpers/routes';
import { PROFILE_INNER_STATE, canUser } from '~/assets/helpers/users';
import PageSpinner from '~/components/Shared/Pages/PageSpinner';
import PageNotFound from '~/components/Shared/Pages/PageNotFound';
import PageForbidden from '~/components/Shared/Pages/PageForbidden';
import ErrorBoundary from '~/components/Shared/Pages/ErrorBoundary';
import Layout from '~/components/Shared/Pages/Layout';

RoutesSwitcher.propTypes = {
  pages: propTypes.array,
};

RoutesSwitcher.defaultProps = {
  pages: [],
};

function RoutesSwitcher(props) {
  const { pages } = props;
  const { pathname } = useLocation();
  const isAuthed = useIsAuthed();
  const permissions = usePermissions();
  const { readyState } = useProfile();

  const getPrivatePageComponent = React.useCallback((routeName, Component) => {
    const [subject, actions = []] = ROUTES_PERMISSIONS[routeName] || [];
    const PrivatePage = (routeProps) => {
      if (readyState !== PROFILE_INNER_STATE.FETCHED) {
        return <PageSpinner />;
      }
      const hasPermission = canUser(permissions, subject, actions);
      if (isAuthed && hasPermission) {
        return <Component {...routeProps} />;
      }
      return <PageForbidden />;
    };
    PrivatePage.displayName = 'PrivatePage';
    return PrivatePage;
  }, [
    readyState,
    isAuthed,
    permissions,
  ]);

  return (
    <ErrorBoundary>
      <Layout locationPathname={pathname}>
        <Switch>
          {pages.map((page) => (
            <Route
              key={page.url}
              exact={true}
              path={page.url}
              component={page.private
                ? getPrivatePageComponent(page.url, page.Component)
                : page.Component}
            />
          ))}
          <Route component={PageNotFound} />
        </Switch>
      </Layout>
    </ErrorBoundary>
  );
}

export default RoutesSwitcher;

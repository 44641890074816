import React from 'react';
import Spinner from '~/components/Shared/Utils/Spinner';
import s from './styles.scss';

function PageSpinner() {
  return (
   <div className={s.pageSpinner}>
     <Spinner className={s.spinner} />
   </div>
  );
}

export default PageSpinner;

import {
  storageGet,
  storageSet,
  storageRemove,
} from '~/assets/libs/localStorage';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
const ACCESS_TOKEN_HEADER_KEY = 'X-Access-Token';
const REFRESH_TOKEN_HEADER_KEY = 'X-Refresh-Token';

/**
 * @return {{ accessToken: string, refreshToken: string }}
 * */
export function getTokens() {
  return {
    accessToken: storageGet(ACCESS_TOKEN_KEY, ''),
    refreshToken: storageGet(REFRESH_TOKEN_KEY, ''),
  };
}

/**
 * @return {boolean}
 * */
export function hasTokens() {
  const { accessToken, refreshToken } = getTokens();
  return Boolean(accessToken && refreshToken);
}

/**
 * @param {string} token
 * @return {void}
 * */
export function saveAccessToken(token) {
  storageSet(ACCESS_TOKEN_KEY, token);
}

/**
 * @param {string} token
 * @return {void}
 * */
export function saveRefreshToken(token) {
  storageSet(REFRESH_TOKEN_KEY, token);
}

/**
 * @param {Object} tokensData
 * @param {string} tokensData.accessToken
 * @param {string} tokensData.refreshToken
 * @return {void}
 * */
export function saveTokens({ accessToken, refreshToken }) {
  saveAccessToken(accessToken);
  saveRefreshToken(refreshToken);
}

/**
 * @return {void}
 * */
export function removeTokens() {
  storageRemove(ACCESS_TOKEN_KEY);
  storageRemove(REFRESH_TOKEN_KEY);
}

/**
 * @return {Object<string, string>}
 * */
export function getAccessTokenHeader() {
  const { accessToken } = getTokens();
  return { [ACCESS_TOKEN_HEADER_KEY]: accessToken };
}

/**
 * @return {Object<string, string>}
 * */
export function getRefreshTokenHeader() {
  const { refreshToken } = getTokens();
  return { [REFRESH_TOKEN_HEADER_KEY]: refreshToken };
}

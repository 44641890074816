/* eslint-disable import/no-cycle, no-restricted-syntax */
import { store as reduxStore } from '~/store';
import { CompositeKeyMap } from './CompositeKeyMap';
import { BoxViewModel } from './BoxViewModel';
import { BOX_KEY_PROPS, BOX_HISTORY_LIMIT, normalizeKey, isSameBoxKey } from './utils';

export class BoxManager {
    /**
     * @type {CompositeKeyMap<BoxViewModel>}
     * @private
     * @readonly
     */
    _vms = new CompositeKeyMap(BOX_KEY_PROPS);

    /**
     * @type {BoxKey|null}
     * @private
     */
    _lastInteractedBoxKey = null;

    /**
     * @type {boolean}
     * @private
     */
    _skipSocketAction = false;

    /**
     * @type {BoxFocusCallback}
     * @private
     * @readonly
     */
    _focusCallback = (box) => {
      this._lastInteractedBoxKey = normalizeKey(box);
    };

    /**
     * @return {boolean}
     */
    getSocketActionsState = () => {
      return this._skipSocketAction;
    }

    /**
     * @return {void}
     */
    stopSocketActions = () => {
      this._skipSocketAction = true;
    }

    /**
     * @return {void}
     */
    resumeSocketActions = () => {
      this._skipSocketAction = false;
    }

    /**
     * @return {void}
     */
    clearLastInteractedViewModel() {
      this._lastInteractedBoxKey = null;
    }

    /**
     * Get view model of last interacted box
     *
     * @return {BoxViewModel|null}
     */
    getLastInteractedViewModel() {
      if (this._lastInteractedBoxKey === null) return null;

      return this.getViewModel(this._lastInteractedBoxKey);
    }

    /**
     * @param {BoxKey} boxKey
     * @return {BoxViewModel|null}
     */
    getViewModel(boxKey) {
      return this._vms.get(boxKey) || null;
    }

    /**
     * @note supposed to be called when document initialized in store
     *
     * @param {*[]} boxes
     */
    resetAll(boxes) {
      this._lastInteractedBoxKey = null;
      this._vms.clear();

      for (const box of boxes) this.resetBox(box);
    }

    /**
     * @param {BoxKey} boxKey
     * @return {void}
     */
    resetBox(boxKey) {
      let vm = this._vms.get(boxKey);

      if (!vm) {
        vm = new BoxViewModel(this._focusCallback, boxKey, reduxStore, BOX_HISTORY_LIMIT);
        this._vms.set(boxKey, vm);
      }

      vm.reset();
    }

    /**
     * @param boxKey
     * @return {void}
     */
    forgetBox(boxKey) {
      this._vms.delete(boxKey);

      if (isSameBoxKey(this._lastInteractedBoxKey, boxKey)) this._lastInteractedBoxKey = null;
    }

    /**
     * @return {Promise<void>}
     */
    async listenHotkeys() {
      // early return when platform not a browser
      if (typeof window === 'undefined') return;

      const { default: keyboard } = await import('keyboardjs');

      keyboard.bind('shift + mod + z', (event) => {
        const vm = this.getLastInteractedViewModel();

        if (!vm) return;

        event.preventDefault();
        event.stopPropagation();
        vm.redo();
      });

      keyboard.bind('mod + z', (event) => {
        const vm = this.getLastInteractedViewModel();

        if (!vm) return;

        event.preventDefault();
        event.stopPropagation();
        vm.undo();
      });
    }
}

import fetchIntercept from 'fetch-intercept';
import throttle from 'lodash/throttle';
import { store } from '~/store';
import { actions } from '~/store/reducers/profile';

const refreshTokens = () => {
  const { dispatch, getState } = store;
  actions.refreshTokens({ withInfoMessage: true })(dispatch, getState);
};

const throttledRefreshTokens = throttle(
  refreshTokens,
  2000,
  { leading: false },
);

fetchIntercept.register({
  async response(response) {
    const clonedResponse = response.clone();
    if ([403].includes(clonedResponse.status)) {
      throttledRefreshTokens.cancel();
      throttledRefreshTokens();
    }
    return response;
  },
});

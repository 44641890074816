import { STORAGE_PREFIX } from '~/app.config';

export function storageHas(key) {
  return window.localStorage.getItem(`${STORAGE_PREFIX}_${key}`) !== null;
}

export function storageGet(key, defaultValue = '') {
  try {
    if (storageHas(key)) {
      return JSON.parse(window.localStorage.getItem(`${STORAGE_PREFIX}_${key}`));
    }
    return defaultValue;
  }
  catch (err) {
    console.warn(err);
    return defaultValue;
  }
}

export function storageSet(key, value) {
  try {
    window.localStorage.setItem(`${STORAGE_PREFIX}_${key}`, JSON.stringify(value));
    return true;
  }
  catch (err) {
    console.warn(err);
    return false;
  }
}

export function storageRemove(key) {
  window.localStorage.removeItem(`${STORAGE_PREFIX}_${key}`);
}

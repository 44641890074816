import React from 'react';
import { useTitle } from '~/assets/hooks/useTitle';
import { IconLogoShort } from '~/components/Icons';
import s from './styles.scss';

function PageNotFound() {
  useTitle({ title: 'Page not found' });

  return (
    <div className={s.pageNotFound}>
      <div className={s.wrapper}>
        <IconLogoShort className={s.logo} />
        <span className={s.big}>Oops,</span>
        <span className={s.small}>
          page with this URL not found
        </span>
      </div>
    </div>
  );
}

export default PageNotFound;

import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import {
  NOTIFICATION_TYPES,
  NOTIFICATION_TIMEOUT,
} from '~/assets/helpers/notification';
import { IconCrossRounded } from '~/components/Icons';
import s from './styles.scss';

const TIMER_INTERVAL = Math.floor(1000 / 60);

const MIN_THRESHOLD = 50;

function getClientXFromEvent(e) {
  const touch = e.changedTouches
    ? e.changedTouches[0]
    : e;
  return touch.clientX;
}

Notification.propTypes = {
  isLast: propTypes.bool,
  showCloseButton: propTypes.bool,
  autoClose: propTypes.bool,
  type: propTypes.string,
  title: propTypes.string,
  text: propTypes.string,
  closePercentage: propTypes.number,
  renderContent: propTypes.func,
  onDelete: propTypes.func,
};

Notification.defaultProps = {
  isLast: false,
  showCloseButton: true,
  autoClose: true,
  type: NOTIFICATION_TYPES.INFO,
  title: '',
  text: '',
  renderContent: null,
  onDelete: null,
};

function Notification(props) {
  const {
    isLast,
    showCloseButton,
    autoClose,
    type,
    title,
    text,
    renderContent,
    onDelete,
  } = props;
  const showTimeOut = isLast && autoClose;
  const [hidden, setHidden] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(NOTIFICATION_TIMEOUT);
  const timeLeftRef = React.useRef(timeLeft);
  const closeTimerRef = React.useRef(null);
  const elRef = React.useRef(null);
  const xRef = React.useRef(null);
  const closePercentage = Math.round(100 * (timeLeft / NOTIFICATION_TIMEOUT));

  const handleTimerStop = React.useCallback(() => {
    clearInterval(closeTimerRef.current);
    timeLeftRef.current = NOTIFICATION_TIMEOUT;
    setTimeLeft(NOTIFICATION_TIMEOUT);
  }, []);
  const handleTimerTick = React.useCallback(() => {
    if (timeLeftRef.current <= 0) {
      setHidden(true);
      handleTimerStop();
      return;
    }
    setTimeLeft((prevTimeLeft) => {
      const nextTimeLeft = prevTimeLeft - TIMER_INTERVAL;
      timeLeftRef.current = nextTimeLeft;
      return nextTimeLeft;
    });
  }, [
    handleTimerStop,
  ]);
  const handleTimerStart = React.useCallback(() => {
    handleTimerStop();
    closeTimerRef.current = setInterval(handleTimerTick, TIMER_INTERVAL);
  }, [
    handleTimerStop,
    handleTimerTick,
  ]);
  const handleHiddenAnimationEnd = React.useCallback((e) => {
    if (e.animationName === s.hide && typeof onDelete === 'function') {
      onDelete();
    }
  }, [
    onDelete,
  ]);
  const handlePointerMove = React.useCallback((e) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    const el = elRef.current;
    const prevX = xRef.current;
    const dX = Math.max(0, getClientXFromEvent(e) - prevX);
    el.style.transitionProperty = 'none';
    el.style.transform = `translateX(${dX}px)`;
  }, []);
  const handlePointerUp = React.useCallback((e) => {
    const el = elRef.current;
    const prevX = xRef.current;
    if (Math.max(0, getClientXFromEvent(e) - prevX) > MIN_THRESHOLD) {
      setHidden(true);
    }
    else {
      el.style.transitionProperty = 'transform';
      el.style.transform = 'translateX(0)';
    }
    xRef.current = null;
    window.document.removeEventListener('touchmove', handlePointerMove);
    window.document.removeEventListener('mousemove', handlePointerMove);
    window.document.removeEventListener('touchend', handlePointerUp);
    window.document.removeEventListener('touchcancel', handlePointerUp);
    window.document.removeEventListener('mouseup', handlePointerUp);
  }, [
    handlePointerMove,
  ]);
  const handlePointerDown = React.useCallback((e) => {
    if (typeof onDelete !== 'function') {
      return;
    }
    handleTimerStop();
    xRef.current = getClientXFromEvent(e);
    window.document.addEventListener('touchmove', handlePointerMove);
    window.document.addEventListener('mousemove', handlePointerMove);
    window.document.addEventListener('touchend', handlePointerUp);
    window.document.addEventListener('touchcancel', handlePointerUp);
    window.document.addEventListener('mouseup', handlePointerUp);
  }, [
    onDelete,
    handleTimerStop,
    handlePointerUp,
    handlePointerMove,
  ]);
  const handleTouchStart = React.useCallback((e) => {
    handlePointerDown(e);
  }, [
    handlePointerDown,
  ]);
  const handleMouseDown = React.useCallback((e) => {
    handlePointerDown(e);
  }, [
    handlePointerDown,
  ]);
  const handleMouseOver = React.useCallback(() => {
    handleTimerStop();
  }, [
    handleTimerStop,
  ]);
  const handleMouseOut = React.useCallback(() => {
    handleTimerStart();
  }, [
    handleTimerStart,
  ]);
  const handleClose = React.useCallback(() => {
    clearInterval(closeTimerRef.current);
    setHidden(true);
  }, []);

  React.useEffect(() => {
    if (showTimeOut) {
      handleTimerStart();
    }
    else {
      handleTimerStop();
    }
  }, [
    showTimeOut,
    handleTimerStop,
    handleTimerStart,
  ]);
  React.useEffect(() => {
    return () => {
      clearInterval(closeTimerRef.current);
    };
  }, []);

  return (
    <div
      className={cn(
        s.notification,
        s[type],
        hidden && s.hidden,
      )}
      ref={elRef}
      onTouchStart={handleTouchStart}
      onMouseDown={handleMouseDown}
      onMouseOver={showTimeOut
        ? handleMouseOver
        : null}
      onMouseOut={showTimeOut
        ? handleMouseOut
        : null}
      onAnimationEnd={handleHiddenAnimationEnd}
    >
      <button
        className={cn('btn', s.closeBtn)}
        type="button"
        tabIndex={-1}
        disabled={hidden || !showCloseButton}
        onClick={handleClose}
      >
        <span className="btn__wrp">
          {showTimeOut
          && <svg
            className={s.closeTimeOut}
            viewBox="0 0 32 32"
          >
            <circle
              className={s.closeTimeOutCircle}
              r="16"
              cx="16"
              cy="16"
              style={{ strokeDasharray: `${closePercentage} 100` }}
            />
          </svg>}
          {showCloseButton
          && <IconCrossRounded className={cn('btn__icon', s.closeIcon)} />}
        </span>
      </button>
      <div className={s.wrp}>
        {title
        && <span className={cn(s.title, showCloseButton && s.titlePadded)}>
          {title}
        </span>}
        {text
        && <div className={s.text}>
          {text}
        </div>}
        {typeof renderContent === 'function'
        && <div className={s.text}>
          {renderContent(props)}
        </div>}
        {(!title && !text && typeof renderContent !== 'function')
        && <div className={s.text}>
          &nbsp;
        </div>}
      </div>
    </div>
  );
}

export default Notification;

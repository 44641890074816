import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import * as reducers from '~/store/reducers';

const middlewares = [
  thunkMiddleware,
];

const combinedReducers = combineReducers(reducers);

const rootReducer = (state, action) => {
  return combinedReducers(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initializeStore = () => {
  return createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
};

export const store = initializeStore();

import React from 'react';

/**
 * @typedef {Function} getScrollValues
 * @return {[number, number]} current
 * */

/**
 * @typedef {Function} setLastScrollValues
 * @return {void}
 * */

/**
 * @typedef {Function} resetLastScrollValues
 * @return {void}
 * */

let lastScrollValues = [0, 0];

function getScrollValues() {
  return lastScrollValues;
}

/**
 * Last viewport scroll React hook
 * @return {[getScrollValues, setLastScrollValues, resetLastScrollValues]}
 * */
export function useLastScroll() {
  const setLastScrollValues = React.useCallback(() => {
    const scrollLeft = window.pageXOffset;
    const scrollTop = window.pageYOffset;
    const nextScrollValues = [scrollLeft, scrollTop];
    lastScrollValues = nextScrollValues;
  }, []);
  const resetLastScrollValues = React.useCallback(() => {
    const nextScrollValues = [0, 0];
    lastScrollValues = nextScrollValues;
  }, []);

  return [
    getScrollValues,
    setLastScrollValues,
    resetLastScrollValues,
  ];
}

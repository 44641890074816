import { Settings } from 'luxon';
import { DEFAULT_LOCALE } from '~/app.config';

const preferredLocale = (window.navigator.languages && window.navigator.languages[0])
  || window.navigator.language
  || DEFAULT_LOCALE;

Settings.defaultLocale = preferredLocale === 'ru-RU'
  ? DEFAULT_LOCALE
  : preferredLocale;

Settings.defaultZoneName = 'local';

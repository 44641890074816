import getProperty from '~/assets/libs/getProperty';

export const TEMP_THREAD_KEY = 'TEMP_THREAD_KEY';

export function getThreadPayload(threadData) {
  const payload = {};
  if (threadData.subjectId !== undefined) {
    payload.subject_id = `/${threadData.subjectType || 'documents'}/${threadData.subjectId}`;
  }
  const boxId = threadData.boxId || '';
  const threadKey = threadData.threadKey || '';
  const selectionState = threadData.selectionState || '';
  payload.position = JSON.stringify({
    boxId,
    threadKey,
    selectionState,
  });
  const commentText = getProperty(threadData, 'comments.0.text', '');
  if (commentText) {
    payload.comment_text = commentText;
  }
  return payload;
}

export function getCommentPayload(commentData) {
  const payload = {};
  if (commentData.text) {
    payload.text = commentData.text;
  }
  return payload;
}

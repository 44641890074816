import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { hasCyrillic } from '~/assets/libs/noRussian';
import { USER_ROLES } from '~/assets/helpers/users';
import getProperty from '~/assets/libs/getProperty';

export const DOCUMENT_DEFAULT_TITLE = 'New Article';

export const UNSAVED_PROTECTION_MESSAGE = 'Your changes will be lost. Are you sure you want to leave?';

export const PUBLISH_BEFORE_EMBARGO_MESSAGE = 'This article is embargoed. Are you sure you want to publish it?';

export const PUBLISH_DELAYED_MESSAGE = 'Are you sure you want to publish this article even though it is scheduled for a later date?';

export const DELAYED_BEFORE_EMBARGO_MESSAGE = 'This scheduled article will be published prior to embargo. Please, schedule the publication for a later date.';

export const NOTICE_AFTER_UPDATE_MESSAGE = 'You have made some changes to the article. If you want the changes to take place on the website, click the "Update" button';

export const DOCUMENT_STATES = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  READY_FOR_ILLUSTRATOR: 'ready_for_illustrator',
  READY_TO_REVIEW: 'ready_to_review',
  TEXT_REJECTED: 'text_rejected',
  IMAGE_REJECTED: 'image_rejected',
  READY_TO_PUBLISH: 'ready_to_publish',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled',
  UNPUBLISHED: 'unpublished',
  CANCELED: 'canceled',
};

export const DOCUMENT_STATE_LABELS = {
  [DOCUMENT_STATES.NEW]: 'New',
  [DOCUMENT_STATES.IN_PROGRESS]: 'In Progress',
  [DOCUMENT_STATES.READY_FOR_ILLUSTRATOR]: 'Ready for Art Editor',
  [DOCUMENT_STATES.READY_TO_REVIEW]: 'Ready to Review',
  [DOCUMENT_STATES.TEXT_REJECTED]: 'Text Rejected',
  [DOCUMENT_STATES.IMAGE_REJECTED]: 'Image Rejected',
  [DOCUMENT_STATES.READY_TO_PUBLISH]: 'Ready to Publish',
  [DOCUMENT_STATES.PUBLISHED]: 'Published',
  [DOCUMENT_STATES.SCHEDULED]: 'Scheduled',
  [DOCUMENT_STATES.UNPUBLISHED]: 'Unpublished',
  [DOCUMENT_STATES.CANCELED]: 'Canceled',
};

export const DOCUMENT_FLAGS = {
  NEEDS_PROOFREADING: 'needs_proofreading',
  NEEDS_ORIGINAL_ARTWORK: 'need_original_artwork',
  BREAKING: 'breaking',
  EMBARGO: 'embargo',
  SCHEDULED: 'deferred',
};

export const DOCUMENT_FLAG_LABELS = {
  [DOCUMENT_FLAGS.NEEDS_PROOFREADING]: 'Needs proofreading',
  [DOCUMENT_FLAGS.NEEDS_ORIGINAL_ARTWORK]: 'Needs original artwork',
  [DOCUMENT_FLAGS.BREAKING]: 'Breaking',
  [DOCUMENT_FLAGS.EMBARGO]: 'Embargoed',
  [DOCUMENT_FLAGS.SCHEDULED]: 'Scheduled',
};

export const DOCUMENT_TABS = {
  ARTICLE: 'ARTICLE',
  METADATA: 'METADATA',
  COMMENTS: 'COMMENTS',
  INFO: 'INFO',
};

export const DOCUMENT_TABS_DESKTOP = [
  {
    key: DOCUMENT_TABS.METADATA,
    cap: 'Metadata',
  },
  {
    key: DOCUMENT_TABS.COMMENTS,
    cap: 'Comments',
  },
];

export const DOCUMENT_READY_STATE = {
  INITIAL: 'INITIAL',
  FETCHING_DATA: 'FETCHING_DATA',
  NOT_FOUND: 'NOT_FOUND',
  FETCHING_BOXES: 'FETCHING_BOXES',
  READY: 'READY',
  AUTO_SAVING: 'AUTO_SAVING',
  AUTO_SAVED: 'AUTO_SAVED',
  BOXES_CREATING: 'BOXES_CREATING',
  BOXES_CREATED: 'BOXES_CREATED',
};

export const DOCUMENTS_CATEGORIES = {
  LATEST_NEWS: '2',
  PRESS_RELEASE: '58',
};

export const DOCUMENT_UI_ELEMENTS = {
  START_WORK_BUTTON: 'START_WORK_BUTTON',
  STOP_WORK_BUTTON: 'STOP_WORK_BUTTON',
  BACK_TO_WORK_BUTTON: 'BACK_TO_WORK_BUTTON',
  REVIEW_BUTTON: 'REVIEW_BUTTON',
  REJECT_TEXT_BUTTON: 'REJECT_TEXT_BUTTON',
  REJECT_IMAGE_BUTTON: 'REJECT_IMAGE_BUTTON',
  FINISH_TEXT_BUTTON: 'FINISH_TEXT_BUTTON',
  FINISH_IMAGE_BUTTON: 'FINISH_IMAGE_BUTTON',
  FINISH_PROOFREADING_BUTTON: 'FINISH_PROOFREADING_BUTTON',
  CANCEL_BUTTON: 'CANCEL_BUTTON',
  PUBLISH_BUTTON: 'PUBLISH_BUTTON',
  RE_PUBLISH_BUTTON: 'RE_PUBLISH_BUTTON',
  UNPUBLISH_BUTTON: 'UNPUBLISH_BUTTON',
  AND_UPDATE_PUBLISH_DATE_CHECKBOX: 'AND_UPDATE_PUBLISH_DATE_CHECKBOX',
};

export const ARTICLES_LIST_LENGTH = 2 * 3 * 4;

export const ARTICLES_LIST_READY_STATE = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  READY: 'READY',
  RE_FETCHING: 'RE_FETCHING',
  FILTERING: 'FILTERING',
};

export const ARTICLES_LIST_VIEWS = {
  TABLE: 'TABLE',
  CARDS: 'CARDS',
};

export const ARTICLES_LIST_PUBLISHED_FILTER = {
  PUBLISHED: 'PUBLISHED',
  OTHERS: 'OTHERS',
};

export const ARTICLES_LIST_COLUMNS = {
  ID: 'id',
  TITLE: 'title',
  FLAGS: 'flags',
  STATUS: 'state',
  EMBARGOED: 'embargo',
  DEADLINE: 'deadline',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled',
  CREATED: 'created',
  UPDATED_STATUS: 'statusUpdated',
  EDITOR: 'editor',
  AUTHOR: 'author',
  COPY_EDITOR: 'proofreader',
  BYLINE_AUTHOR: 'publicAuthor',
  CREATOR: 'creator',
  COVER_UPDATER: 'coverUpdater',
  WORDS: 'words',
  CHARACTERS: 'characters',
  CATEGORY: 'category',
};

export const ARTICLES_LIST_DEFAULT_PUBLISHED_FILTER = {
  [ARTICLES_LIST_PUBLISHED_FILTER.PUBLISHED]: [
    DOCUMENT_STATES.PUBLISHED,
  ],
  [ARTICLES_LIST_PUBLISHED_FILTER.OTHERS]: [
    DOCUMENT_STATES.NEW,
    DOCUMENT_STATES.IN_PROGRESS,
    DOCUMENT_STATES.READY_FOR_ILLUSTRATOR,
    DOCUMENT_STATES.READY_TO_REVIEW,
    DOCUMENT_STATES.TEXT_REJECTED,
    DOCUMENT_STATES.IMAGE_REJECTED,
    DOCUMENT_STATES.READY_TO_PUBLISH,
    DOCUMENT_STATES.UNPUBLISHED,
    DOCUMENT_STATES.CANCELED,
  ],
};

export const ARTICLES_LIST_DEFAULT_FILTERS = {
  [ARTICLES_LIST_COLUMNS.ID]: '',
  [ARTICLES_LIST_COLUMNS.TITLE]: '',
  [ARTICLES_LIST_COLUMNS.FLAGS]: '',
  [ARTICLES_LIST_COLUMNS.STATUS]: [],
  [ARTICLES_LIST_COLUMNS.DEADLINE]: ['', ''],
  [ARTICLES_LIST_COLUMNS.EMBARGOED]: ['', ''],
  [ARTICLES_LIST_COLUMNS.PUBLISHED]: ['', ''],
  [ARTICLES_LIST_COLUMNS.CREATED]: ['', ''],
  [ARTICLES_LIST_COLUMNS.UPDATED_STATUS]: ['', ''],
  [ARTICLES_LIST_COLUMNS.CREATOR]: '',
  [ARTICLES_LIST_COLUMNS.EDITOR]: '',
  [ARTICLES_LIST_COLUMNS.AUTHOR]: '',
  [ARTICLES_LIST_COLUMNS.COPY_EDITOR]: '',
  [ARTICLES_LIST_COLUMNS.BYLINE_AUTHOR]: '',
  [ARTICLES_LIST_COLUMNS.CATEGORY]: '',
};

export const ARTICLES_LIST_DEFAULT_SORTING = {
  sortBy: ARTICLES_LIST_COLUMNS.CREATED,
  sortType: 'DESC',
};

export const ARTICLES_LIST_DEFAULT_COLUMNS = [
  ARTICLES_LIST_COLUMNS.ID,
  ARTICLES_LIST_COLUMNS.TITLE,
  ARTICLES_LIST_COLUMNS.FLAGS,
  ARTICLES_LIST_COLUMNS.STATUS,
  ARTICLES_LIST_COLUMNS.EMBARGOED,
  ARTICLES_LIST_COLUMNS.DEADLINE,
  ARTICLES_LIST_COLUMNS.PUBLISHED,
  ARTICLES_LIST_COLUMNS.EDITOR,
  ARTICLES_LIST_COLUMNS.AUTHOR,
  ARTICLES_LIST_COLUMNS.CATEGORY,
  ARTICLES_LIST_COLUMNS.WORDS,
];

export const ARTICLES_LIST_DEFAULT_MOBILE_COLUMNS = [
  ARTICLES_LIST_COLUMNS.FLAGS,
  ARTICLES_LIST_COLUMNS.STATUS,
  ARTICLES_LIST_COLUMNS.CREATED,
  ARTICLES_LIST_COLUMNS.EDITOR,
  ARTICLES_LIST_COLUMNS.AUTHOR,
];

export const ARTICLES_LIST_DEFAULT_PAGING = {
  length: ARTICLES_LIST_LENGTH,
  offset: 0,
};

export const ARTICLES_LIST_COLUMN_ORDER = [
  ARTICLES_LIST_COLUMNS.ID,
  ARTICLES_LIST_COLUMNS.TITLE,
  ARTICLES_LIST_COLUMNS.FLAGS,
  ARTICLES_LIST_COLUMNS.STATUS,
  ARTICLES_LIST_COLUMNS.EMBARGOED,
  ARTICLES_LIST_COLUMNS.DEADLINE,
  ARTICLES_LIST_COLUMNS.PUBLISHED,
  ARTICLES_LIST_COLUMNS.CREATED,
  ARTICLES_LIST_COLUMNS.EDITOR,
  ARTICLES_LIST_COLUMNS.AUTHOR,
  ARTICLES_LIST_COLUMNS.COPY_EDITOR,
  ARTICLES_LIST_COLUMNS.BYLINE_AUTHOR,
  ARTICLES_LIST_COLUMNS.CREATOR,
  ARTICLES_LIST_COLUMNS.WORDS,
  ARTICLES_LIST_COLUMNS.CHARACTERS,
  ARTICLES_LIST_COLUMNS.CATEGORY,
];

export const ARTICLES_LIST_COLUMN_LABELS = {
  [ARTICLES_LIST_COLUMNS.ID]: 'ID',
  [ARTICLES_LIST_COLUMNS.TITLE]: 'Title',
  [ARTICLES_LIST_COLUMNS.FLAGS]: 'Flags',
  [ARTICLES_LIST_COLUMNS.STATUS]: 'Status',
  [ARTICLES_LIST_COLUMNS.EMBARGOED]: 'Embargo',
  [ARTICLES_LIST_COLUMNS.DEADLINE]: 'Deadline',
  [ARTICLES_LIST_COLUMNS.PUBLISHED]: 'Published',
  [ARTICLES_LIST_COLUMNS.SCHEDULED]: 'Scheduled',
  [ARTICLES_LIST_COLUMNS.CREATED]: 'Created',
  [ARTICLES_LIST_COLUMNS.UPDATED_STATUS]: 'Updated at',
  [ARTICLES_LIST_COLUMNS.EDITOR]: 'Editor',
  [ARTICLES_LIST_COLUMNS.AUTHOR]: 'Writer',
  [ARTICLES_LIST_COLUMNS.COPY_EDITOR]: 'Copy Editor',
  [ARTICLES_LIST_COLUMNS.BYLINE_AUTHOR]: 'Public author',
  [ARTICLES_LIST_COLUMNS.CREATOR]: 'Creator',
  [ARTICLES_LIST_COLUMNS.COVER_UPDATER]: 'Cover art set',
  [ARTICLES_LIST_COLUMNS.WORDS]: 'Words',
  [ARTICLES_LIST_COLUMNS.CHARACTERS]: 'Characters',
  [ARTICLES_LIST_COLUMNS.CATEGORY]: 'Category',
};

export const ARTICLES_LIST_SORTABLE_COLUMNS = [
  ARTICLES_LIST_COLUMNS.STATUS,
  ARTICLES_LIST_COLUMNS.DEADLINE,
  ARTICLES_LIST_COLUMNS.EMBARGOED,
  ARTICLES_LIST_COLUMNS.PUBLISHED,
  ARTICLES_LIST_COLUMNS.CREATED,
  ARTICLES_LIST_COLUMNS.UPDATED_STATUS,
  ARTICLES_LIST_COLUMNS.WORDS,
  ARTICLES_LIST_COLUMNS.CHARACTERS,
];

export const ARTICLES_LIST_MOBILE_SORTABLE_COLUMNS = [
  ARTICLES_LIST_COLUMNS.STATUS,
  ARTICLES_LIST_COLUMNS.PUBLISHED,
  ARTICLES_LIST_COLUMNS.CREATED,
];

export function getDocumentFieldsValidators(document) {
  const fieldValidators = getFieldsValidatorsPerDocumentState(document.state);

  return {
    authors: {
      value: document.authors,
      validators: fieldValidators.authors,
    },
    title: {
      value: document.title,
      validators: fieldValidators.title,
    },
    leadText: {
      value: document.leadText,
      validators: fieldValidators.leadText,
    },
    coverTitle: {
      value: document.coverTitle,
      validators: fieldValidators.coverTitle,
    },
    coverAlt: {
      value: document.coverAlt,
      validators: fieldValidators.coverAlt,
    },
    coverExtension: {
      value: document.coverExtension,
      validators: fieldValidators.coverExtension,
    },
    coverName: {
      value: document.coverName,
      validators: fieldValidators.coverName,
    },
    coverGalleryId: {
      value: document.coverGalleryId,
      validators: fieldValidators.coverGalleryId,
    },
    coverId: {
      value: document.coverId,
      validators: fieldValidators.coverId,
    },
    coverType: {
      value: document.coverType,
      validators: fieldValidators.coverType,
    },
    coverUrl: {
      value: document.coverUrl,
      validators: fieldValidators.coverUrl,
    },
    coverCaption: {
      value: document.coverCaption,
      validators: fieldValidators.coverCaption,
    },
    pitch: {
      value: document.pitch,
      validators: fieldValidators.pitch,
    },
    isBreaking: {
      value: document.isBreaking,
      validators: fieldValidators.isBreaking,
    },
    isPinned: {
      value: document.isPinned,
      validators: fieldValidators.isPinned,
    },
    needsOriginalArtwork: {
      value: document.needsOriginalArtwork,
      validators: fieldValidators.needsOriginalArtwork,
    },
    pinnedSlot: {
      value: document.pinnedSlot,
      validators: fieldValidators.pinnedSlot,
    },
    editorId: {
      value: document.editorId,
      validators: fieldValidators.editorId,
    },
    authorId: {
      value: document.authorId,
      validators: fieldValidators.authorId,
    },
    proofreaderId: {
      value: document.proofreaderId,
      validators: fieldValidators.proofreaderId,
    },
    dateDeadlineAt: {
      value: document.dateDeadlineAt,
      validators: fieldValidators.dateDeadlineAt,
    },
    dateEmbargoAt: {
      value: document.dateEmbargoAt,
      validators: fieldValidators.dateEmbargoAt,
    },
    datePublishedAt: {
      value: document.datePublishedAt,
      validators: fieldValidators.datePublishedAt,
    },
    categoryId: {
      value: document.categoryId,
      validators: fieldValidators.categoryId,
    },
    labelId: {
      value: document.labelId,
      validators: fieldValidators.labelId,
    },
    superTags: {
      value: document.superTags,
      validators: fieldValidators.superTags,
    },
    tags: {
      value: document.tags,
      validators: fieldValidators.tags,
    },
    metadataKeywords: {
      value: document.metadataKeywords,
      validators: fieldValidators.metadataKeywords,
    },
    metadataDescription: {
      value: document.metadataDescription,
      validators: fieldValidators.metadataDescription,
    },
    metadataNoIndex: {
      value: document.metadataNoIndex,
      validators: fieldValidators.metadataNoIndex,
    },
    facebookLeadText: {
      value: document.facebookLeadText,
      validators: fieldValidators.facebookLeadText,
    },
    twitterLeadText: {
      value: document.twitterLeadText,
      validators: fieldValidators.twitterLeadText,
    },
    youtubeCoverUrl: {
      value: document.youtubeCoverUrl,
      validators: fieldValidators.youtubeCoverUrl,
    },
    telegramLeadText: {
      value: document.telegramLeadText,
      validators: fieldValidators.telegramLeadText,
    },
    hideDisclaimerForPressRelease: {
      value: document.hideDisclaimerForPressRelease,
      validators: fieldValidators.hideDisclaimerForPressRelease,
    },
    excludeFromFacebookRSS: {
      value: document.excludeFromFacebookRSS,
      validators: fieldValidators.excludeFromFacebookRSS,
    },
    excludeFromTwitterRSS: {
      value: document.excludeFromTwitterRSS,
      validators: fieldValidators.excludeFromTwitterRSS,
    },
    excludeFromTelegramRSS: {
      value: document.excludeFromTelegramRSS,
      validators: fieldValidators.excludeFromTelegramRSS,
    },
    hideFromMainPage: {
      value: document.hideFromMainPage,
      validators: fieldValidators.hideFromMainPage,
    },
    showInMarkets: {
      value: document.showInMarkets,
      validators: fieldValidators.showInMarkets,
    },
    slug: {
      value: document.slug,
      validators: fieldValidators.slug,
    },
    hideFromHotStories: {
      value: document.hideFromHotStories,
      validators: fieldValidators.hideFromHotStories,
    },
  };
}

export function getFieldsValidatorsPerDocumentState(state) {
  switch (state) {
    case DOCUMENT_STATES.NEW:
    case DOCUMENT_STATES.IN_PROGRESS:
      return {
        authors: {
          isEmpty,
        },
        title: {
          isEmpty,
          hasCyrillic,
        },
        leadText: {
          isEmpty,
          hasCyrillic,
        },
        pitch: {
          hasCyrillic,
        },
        editorId: {
          isEmpty,
        },
        superTags: {
          isEmpty,
        },
        tags: {
          isEmpty,
        },
        metadataKeywords: {
          hasCyrillic,
        },
        metadataDescription: {
          hasCyrillic,
        },
        facebookLeadText: {
          hasCyrillic,
        },
        twitterLeadText: {
          isEmpty,
          hasCyrillic,
        },
        youtubeCoverUrl: {
          hasCyrillic,
        },
        telegramLeadText: {
          hasCyrillic,
        },
        categoryId: {
          isEmpty,
        },
        labelId: {
          isEmpty,
        },
      };
    case DOCUMENT_STATES.READY_FOR_ILLUSTRATOR:
    case DOCUMENT_STATES.TEXT_REJECTED:
    case DOCUMENT_STATES.IMAGE_REJECTED:
      return {
        authors: {
          isEmpty,
        },
        title: {
          isEmpty,
          hasCyrillic,
        },
        leadText: {
          isEmpty,
          hasCyrillic,
        },
        pitch: {
          hasCyrillic,
        },
        editorId: {
          isEmpty,
        },
        superTags: {
          isEmpty,
        },
        tags: {
          isEmpty,
        },
        metadataKeywords: {
          hasCyrillic,
        },
        metadataDescription: {
          hasCyrillic,
        },
        facebookLeadText: {
          hasCyrillic,
        },
        twitterLeadText: {
          isEmpty,
          hasCyrillic,
        },
        youtubeCoverUrl: {
          hasCyrillic,
        },
        telegramLeadText: {
          hasCyrillic,
        },
        categoryId: {
          isEmpty,
        },
        labelId: {
          isEmpty,
        },
        coverId: {
          isEmpty,
        },
      };
    case DOCUMENT_STATES.READY_TO_REVIEW:
    case DOCUMENT_STATES.READY_TO_PUBLISH:
    case DOCUMENT_STATES.UNPUBLISHED:
      return {
        authors: {
          isEmpty,
        },
        title: {
          isEmpty,
          hasCyrillic,
        },
        leadText: {
          isEmpty,
          hasCyrillic,
        },
        pitch: {
          hasCyrillic,
        },
        editorId: {
          isEmpty,
        },
        superTags: {
          isEmpty,
        },
        tags: {
          isEmpty,
        },
        metadataKeywords: {
          hasCyrillic,
        },
        metadataDescription: {
          isEmpty,
          hasCyrillic,
        },
        facebookLeadText: {
          hasCyrillic,
        },
        twitterLeadText: {
          isEmpty,
          hasCyrillic,
        },
        youtubeCoverUrl: {
          hasCyrillic,
        },
        telegramLeadText: {
          hasCyrillic,
        },
        categoryId: {
          isEmpty,
        },
        labelId: {
          isEmpty,
        },
        coverId: {
          isEmpty,
        },
      };
    case DOCUMENT_STATES.PUBLISHED:
    case DOCUMENT_STATES.SCHEDULED:
      return {
        authors: {
          isEmpty,
        },
        title: {
          isEmpty,
          hasCyrillic,
        },
        leadText: {
          isEmpty,
          hasCyrillic,
        },
        pitch: {
          hasCyrillic,
        },
        editorId: {
          isEmpty,
        },
        superTags: {
          isEmpty,
        },
        tags: {
          isEmpty,
        },
        metadataKeywords: {
          hasCyrillic,
        },
        metadataDescription: {
          isEmpty,
          hasCyrillic,
        },
        facebookLeadText: {
          hasCyrillic,
        },
        twitterLeadText: {
          isEmpty,
          hasCyrillic,
        },
        youtubeCoverUrl: {
          hasCyrillic,
        },
        telegramLeadText: {
          hasCyrillic,
        },
        categoryId: {
          isEmpty,
        },
        labelId: {
          isEmpty,
        },
        coverId: {
          isEmpty,
        },
        datePublishedAt: {
          isEmpty,
        },
      };
    case DOCUMENT_STATES.CANCELED:
    default:
      return {};
  }
}

export function getFieldsWarningMessages(fields) {
  const messages = [];
  if (fields.title) {
    messages.push('Article title is invalid');
  }
  if (fields.leadText) {
    messages.push('Article dek is invalid');
  }
  if (fields.authors) {
    messages.push('Article author is unassigned');
  }
  if (fields.editorId) {
    messages.push('Editor is unassigned');
  }
  if (fields.superTags) {
    messages.push('Article should have at least one super tag');
  }
  if (fields.tags) {
    messages.push('Article should have at least one tag');
  }
  if (fields.noBoxes) {
    messages.push('Article should have at least one none-empty content box');
  }
  if (fields.emptyTextBoxes) {
    messages.push('Article should not contain empty text boxes');
  }
  if (fields.emptyImageBoxes) {
    messages.push('Article should not contain empty art boxes');
  }
  if (fields.coverId) {
    messages.push('Article main image is empty');
  }
  if (fields.categoryId) {
    messages.push('Category is not chosen');
  }
  if (fields.labelId) {
    messages.push('Label is not chosen');
  }
  if (fields.socialLeadTexts) {
    messages.push('Social networks texts should not be empty');
  }
  if (fields.datePublishedAt) {
    messages.push('Scheduled publication date should not be empty');
  }
  return messages;
}

export function getDocumentPayload(inputData) {
  const payload = {};
  payload.type = 'article';
  if (inputData.title !== undefined) {
    payload.title = inputData.title;
  }
  if (inputData.isBreaking !== undefined) {
    if (payload.settings === undefined) {
      payload.settings = {};
    }
    payload.settings.breaking = inputData.isBreaking;
  }
  if (inputData.isPinned !== undefined) {
    payload.is_pinned = inputData.isPinned;
  }
  if (inputData.pinnedSlot !== undefined) {
    payload.pinned_slot = inputData.pinnedSlot;
  }
  if (inputData.leadText !== undefined) {
    payload.lead = inputData.leadText;
  }
  if (inputData.facebookLeadText !== undefined) {
    payload.facebook_lead = inputData.facebookLeadText;
  }
  if (inputData.twitterLeadText !== undefined) {
    payload.twitter_lead = inputData.twitterLeadText;
  }
  if (inputData.youtubeCoverUrl !== undefined) {
    payload.youtube_url = inputData.youtubeCoverUrl;
  }
  if (inputData.telegramLeadText !== undefined) {
    payload.telegram_lead = inputData.telegramLeadText;
  }
  if (inputData.datePublishedAt !== undefined) {
    payload.published_at = parseInt(inputData.datePublishedAt) || null;
  }
  if (inputData.dateDeadlineAt !== undefined) {
    payload.deadline = parseInt(inputData.dateDeadlineAt) || null;
  }
  if (inputData.dateEmbargoAt !== undefined) {
    payload.embargo = parseInt(inputData.dateEmbargoAt) || null;
  }
  if (inputData.coverTitle !== undefined) {
    if (payload.title_image_properties === undefined) {
      payload.title_image_properties = {};
    }
    payload.title_image_properties.title = inputData.coverTitle;
  }
  if (inputData.coverAlt !== undefined) {
    if (payload.title_image_properties === undefined) {
      payload.title_image_properties = {};
    }
    payload.title_image_properties.alt = inputData.coverAlt;
  }
  if (inputData.coverId !== undefined) {
    payload.title_image_id = inputData.coverId;
  }
  if (inputData.superTags !== undefined) {
    payload.super_tag_ids = inputData.superTags
      .map((item) => parseInt(item))
      .filter((item) => item);
  }
  if (inputData.tags !== undefined) {
    payload.tag_ids = inputData.tags
      .map((item) => parseInt(item))
      .filter((item) => item);
  }
  if (inputData.labelId !== undefined) {
    payload.label_id = parseInt(inputData.labelId) || null;
  }
  if (inputData.categoryId !== undefined) {
    payload.category_id = parseInt(inputData.categoryId) || null;
  }
  if (inputData.authors !== undefined) {
    payload.author_ids = inputData.authors
      .map((item) => parseInt(item))
      .filter((item) => item);
  }
  if (inputData.metadataNoIndex !== undefined) {
    if (payload.metadata === undefined) {
      payload.metadata = {};
    }
    payload.metadata.noindex = inputData.metadataNoIndex;
  }
  if (inputData.metadataKeywords !== undefined) {
    if (payload.metadata === undefined) {
      payload.metadata = {};
    }
    payload.metadata.keywords = inputData.metadataKeywords;
  }
  if (inputData.metadataDescription !== undefined) {
    if (payload.metadata === undefined) {
      payload.metadata = {};
    }
    payload.metadata.description = inputData.metadataDescription;
  }
  if (inputData.pitch !== undefined) {
    payload.pitch = inputData.pitch;
  }
  if (inputData.needPublishDateUpdate !== undefined) {
    payload.update_published_at = inputData.needPublishDateUpdate;
  }
  if (inputData.hideDisclaimerForPressRelease !== undefined) {
    payload.hide_disclaimer = inputData.hideDisclaimerForPressRelease;
  }
  if (inputData.excludeFromFacebookRSS !== undefined) {
    if (payload.rss === undefined) {
      payload.rss = {};
    }
    payload.rss.facebook = inputData.excludeFromFacebookRSS;
  }
  if (inputData.excludeFromTwitterRSS !== undefined) {
    if (payload.rss === undefined) {
      payload.rss = {};
    }
    payload.rss.twitter = inputData.excludeFromTwitterRSS;
  }
  if (inputData.excludeFromTelegramRSS !== undefined) {
    if (payload.rss === undefined) {
      payload.rss = {};
    }
    payload.rss.telegram = inputData.excludeFromTelegramRSS;
  }
  if (inputData.hideFromMainPage !== undefined) {
    payload.hide_from_main_page = inputData.hideFromMainPage;
  }
  if (inputData.showInMarkets !== undefined) {
    payload.is_market = inputData.showInMarkets;
  }
  if (inputData.hideFromHotStories !== undefined) {
    payload.hide_from_hot_stories = inputData.hideFromHotStories;
  }
  if (inputData.needsOriginalArtwork !== undefined) {
    payload.need_original_artwork = inputData.needsOriginalArtwork;
  }

  if (inputData.slug !== undefined) {
    payload.slug = inputData.slug || null;
  }

  return payload;
}

export function getDocumentCreatePayload(inputData) {
  const payload = {};
  payload.type = 'article';
  if (inputData.pitch) {
    payload.pitch = inputData.pitch;
  }
  if (inputData.dateDeadlineAt) {
    payload.deadline = parseInt(inputData.dateDeadlineAt) || '';
  }
  if (inputData.isBreaking !== undefined) {
    if (payload.settings === undefined) {
      payload.settings = {};
    }
    payload.settings.breaking = inputData.isBreaking;
  }
  if (inputData.needsOriginalArtwork !== undefined) {
    payload.need_original_artwork = inputData.needsOriginalArtwork;
  }
  if (inputData.editorId) {
    payload.assigned_editor_id = inputData.editorId;
  }
  if (inputData.authorId) {
    payload.assigned_author_id = inputData.authorId;
  }
  if (inputData.categoryId) {
    payload.category_id = parseInt(inputData.categoryId) || '';
  }
  if (inputData.authors) {
    payload.author_ids = inputData.authors
      .map((item) => parseInt(item))
      .filter((item) => item);
  }

  return payload;
}

export function getBylineAuthorCreatePayload(inputData) {
  const payload = {};
  payload.gender = inputData.gender;
  payload.title = inputData.name;
  payload.description = inputData.bio;
  if (inputData.image) {
    payload.avatar = inputData.image;
  }
  if (inputData.email) {
    payload.email = inputData.email;
  }
  if (inputData.twitter) {
    payload.twitter = inputData.twitter;
  }
  if (inputData.linkedin) {
    payload.linkedin = inputData.linkedin;
  }
  return payload;
}

export function getArticlesQueryPayload(queryData = {}) {
  const payload = {};
  if (queryData.id) {
    payload.id = queryData.id;
  }
  if (queryData.title) {
    payload.title = queryData.title;
  }
  if (queryData.flags) {
    payload.flags = queryData.flags;
  }
  if (queryData.state) {
    payload.state = queryData.state;
  }
  if (queryData.deadline && !isEqual(['', ''], queryData.deadline)) {
    const from = parseInt(queryData.deadline[0]) || null;
    const to = parseInt(queryData.deadline[1]) || null;
    payload.deadline = [from, to];
  }
  if (queryData.embargo && !isEqual(['', ''], queryData.embargo)) {
    const from = parseInt(queryData.embargo[0]) || null;
    const to = parseInt(queryData.embargo[1]) || null;
    payload.embargo = [from, to];
  }
  if (queryData.published && !isEqual(['', ''], queryData.published)) {
    const from = parseInt(queryData.published[0]) || null;
    const to = parseInt(queryData.published[1]) || null;
    payload.published = [from, to];
  }
  if (queryData.created && !isEqual(['', ''], queryData.created)) {
    const from = parseInt(queryData.created[0]) || null;
    const to = parseInt(queryData.created[1]) || null;
    payload.created = [from, to];
  }
  if (queryData.statusUpdated && !isEqual(['', ''], queryData.statusUpdated)) {
    const from = parseInt(queryData.statusUpdated[0]) || null;
    const to = parseInt(queryData.statusUpdated[1]) || null;
    payload.statusUpdated = [from, to];
  }
  if (queryData.publicAuthor) {
    payload.publicAuthor = queryData.publicAuthor;
  }
  if (queryData.proofreader) {
    payload.proofreader = queryData.proofreader;
  }
  if (queryData.editor) {
    payload.editor = queryData.editor;
  }
  if (queryData.author) {
    payload.author = queryData.author;
  }
  if (queryData.creator) {
    payload.creator = queryData.creator;
  }
  if (queryData.category) {
    payload.category = queryData.category;
  }
  if (queryData.sortBy) {
    payload.order = queryData.sortBy;
    if (queryData.sortType) {
      payload.orderType = queryData.sortType === 'DESC'
        ? 'DESC'
        : 'ASC';
    }
  }
  if (queryData.length) {
    payload.limit = queryData.length;
  }
  if (queryData.offset) {
    payload.skip = queryData.offset;
  }
  return payload;
}

export function getArticlesFiltersFromQuery(queryData = {}) {
  const filters = {};
  const sorting = {};
  const paging = {};
  if (queryData.id) {
    filters.id = queryData.id;
  }
  if (queryData.title) {
    filters.title = queryData.title;
  }
  if (queryData.flags) {
    filters.flags = queryData.flags;
  }
  if (queryData.state) {
    filters.state = typeof queryData.state === 'string'
      ? [queryData.state]
      : queryData.state;
  }
  if (Array.isArray(queryData.deadline) && queryData.deadline.length === 2) {
    filters.deadline = queryData.deadline;
  }
  if (Array.isArray(queryData.embargo) && queryData.embargo.length === 2) {
    filters.embargo = queryData.embargo;
  }
  if (Array.isArray(queryData.published) && queryData.published.length === 2) {
    filters.published = queryData.published;
  }
  if (Array.isArray(queryData.created) && queryData.created.length === 2) {
    filters.created = queryData.created;
  }
  if (Array.isArray(queryData.created) && queryData.created.length === 2) {
    filters.created = queryData.created;
  }
  if (Array.isArray(queryData.statusUpdated) && queryData.statusUpdated.length === 2) {
    filters.statusUpdated = queryData.statusUpdated;
  }
  if (queryData.publicAuthor) {
    filters.publicAuthor = queryData.publicAuthor;
  }
  if (queryData.proofreader) {
    filters.proofreader = queryData.proofreader;
  }
  if (queryData.editor) {
    filters.editor = queryData.editor;
  }
  if (queryData.author) {
    filters.author = queryData.author;
  }
  if (queryData.creator) {
    filters.creator = queryData.creator;
  }
  if (queryData.category) {
    filters.category = queryData.category;
  }
  if (queryData.order) {
    sorting.sortBy = queryData.order;
    if (queryData.orderType) {
      sorting.sortType = queryData.orderType === 'DESC'
        ? 'DESC'
        : 'ASC';
    }
  }
  if (queryData.limit) {
    paging.length = parseInt(queryData.limit);
  }
  if (queryData.skip) {
    paging.offset = parseInt(queryData.skip);
  }
  return {
    filters,
    sorting,
    paging,
  };
}

export function initUnsavedProtection() {
  window.onbeforeunload = (e) => {
    e.preventDefault();
    const message = UNSAVED_PROTECTION_MESSAGE;
    if (e) {
      e.returnValue = message;
    }
    return message;
  };
}

export function stopUnsavedProtection() {
  window.onbeforeunload = null;
}

const UI_PERMISSIONS = {
  [USER_ROLES.ILLUSTRATOR]: {
    [DOCUMENT_STATES.READY_FOR_ILLUSTRATOR]: [
      DOCUMENT_UI_ELEMENTS.FINISH_IMAGE_BUTTON,
    ],
    [DOCUMENT_STATES.IMAGE_REJECTED]: [
      DOCUMENT_UI_ELEMENTS.FINISH_IMAGE_BUTTON,
    ],
  },
  [USER_ROLES.AUTHOR]: {
    [DOCUMENT_STATES.NEW]: [
      DOCUMENT_UI_ELEMENTS.START_WORK_BUTTON,
    ],
    [DOCUMENT_STATES.IN_PROGRESS]: [
      DOCUMENT_UI_ELEMENTS.STOP_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_TEXT_BUTTON,
    ],
    [DOCUMENT_STATES.READY_FOR_ILLUSTRATOR]: [
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
    ],
    [DOCUMENT_STATES.TEXT_REJECTED]: [
      DOCUMENT_UI_ELEMENTS.FINISH_TEXT_BUTTON,
    ],
    [DOCUMENT_STATES.UNPUBLISHED]: [
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
    ],
    [DOCUMENT_STATES.CANCELED]: [
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
    ],
  },
  [USER_ROLES.PROOF_READER]: {
    [DOCUMENT_STATES.READY_FOR_ILLUSTRATOR]: [
      DOCUMENT_UI_ELEMENTS.FINISH_PROOFREADING_BUTTON,
    ],
    [DOCUMENT_STATES.READY_TO_REVIEW]: [
      DOCUMENT_UI_ELEMENTS.FINISH_PROOFREADING_BUTTON,
    ],
    [DOCUMENT_STATES.READY_TO_PUBLISH]: [
      DOCUMENT_UI_ELEMENTS.FINISH_PROOFREADING_BUTTON,
    ],
    [DOCUMENT_STATES.PUBLISHED]: [
      DOCUMENT_UI_ELEMENTS.FINISH_PROOFREADING_BUTTON,
      DOCUMENT_UI_ELEMENTS.RE_PUBLISH_BUTTON,
    ],
    [DOCUMENT_STATES.SCHEDULED]: [
      DOCUMENT_UI_ELEMENTS.FINISH_PROOFREADING_BUTTON,
      DOCUMENT_UI_ELEMENTS.RE_PUBLISH_BUTTON,
    ],
  },
  [USER_ROLES.EDITOR]: {
    [DOCUMENT_STATES.NEW]: [
      DOCUMENT_UI_ELEMENTS.START_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
    ],
    [DOCUMENT_STATES.IN_PROGRESS]: [
      DOCUMENT_UI_ELEMENTS.STOP_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_TEXT_BUTTON,
    ],
    [DOCUMENT_STATES.READY_FOR_ILLUSTRATOR]: [
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_IMAGE_BUTTON,
    ],
    [DOCUMENT_STATES.READY_TO_REVIEW]: [
      DOCUMENT_UI_ELEMENTS.REVIEW_BUTTON,
      DOCUMENT_UI_ELEMENTS.REJECT_TEXT_BUTTON,
      DOCUMENT_UI_ELEMENTS.REJECT_IMAGE_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
    ],
    [DOCUMENT_STATES.READY_TO_PUBLISH]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.PUBLISH_BUTTON,
    ],
    [DOCUMENT_STATES.TEXT_REJECTED]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_TEXT_BUTTON,
    ],
    [DOCUMENT_STATES.IMAGE_REJECTED]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_IMAGE_BUTTON,
    ],
    [DOCUMENT_STATES.PUBLISHED]: [
      DOCUMENT_UI_ELEMENTS.RE_PUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.UNPUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.AND_UPDATE_PUBLISH_DATE_CHECKBOX,
    ],
    [DOCUMENT_STATES.SCHEDULED]: [
      DOCUMENT_UI_ELEMENTS.RE_PUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.UNPUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.AND_UPDATE_PUBLISH_DATE_CHECKBOX,
    ],
    [DOCUMENT_STATES.UNPUBLISHED]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.PUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
    ],
    [DOCUMENT_STATES.CANCELED]: [
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
    ],
  },
  [USER_ROLES.EDITOR_IN_CHIEF]: {
    [DOCUMENT_STATES.NEW]: [
      DOCUMENT_UI_ELEMENTS.START_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
    ],
    [DOCUMENT_STATES.IN_PROGRESS]: [
      DOCUMENT_UI_ELEMENTS.STOP_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_TEXT_BUTTON,
    ],
    [DOCUMENT_STATES.READY_FOR_ILLUSTRATOR]: [
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_IMAGE_BUTTON,
    ],
    [DOCUMENT_STATES.READY_TO_REVIEW]: [
      DOCUMENT_UI_ELEMENTS.REVIEW_BUTTON,
      DOCUMENT_UI_ELEMENTS.REJECT_TEXT_BUTTON,
      DOCUMENT_UI_ELEMENTS.REJECT_IMAGE_BUTTON,
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
    ],
    [DOCUMENT_STATES.READY_TO_PUBLISH]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.PUBLISH_BUTTON,
    ],
    [DOCUMENT_STATES.TEXT_REJECTED]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_TEXT_BUTTON,
    ],
    [DOCUMENT_STATES.IMAGE_REJECTED]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.FINISH_IMAGE_BUTTON,
    ],
    [DOCUMENT_STATES.PUBLISHED]: [
      DOCUMENT_UI_ELEMENTS.RE_PUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.UNPUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.AND_UPDATE_PUBLISH_DATE_CHECKBOX,
    ],
    [DOCUMENT_STATES.SCHEDULED]: [
      DOCUMENT_UI_ELEMENTS.RE_PUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.UNPUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.AND_UPDATE_PUBLISH_DATE_CHECKBOX,
    ],
    [DOCUMENT_STATES.UNPUBLISHED]: [
      DOCUMENT_UI_ELEMENTS.CANCEL_BUTTON,
      DOCUMENT_UI_ELEMENTS.PUBLISH_BUTTON,
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
      DOCUMENT_UI_ELEMENTS.AND_UPDATE_PUBLISH_DATE_CHECKBOX,
    ],
    [DOCUMENT_STATES.CANCELED]: [
      DOCUMENT_UI_ELEMENTS.BACK_TO_WORK_BUTTON,
    ],
  },
};

export function isArticleUIShown(options) {
  const {
    userRole = '',
    documentState = '',
  } = options || {};
  return (elementName = '') => {
    const path = `${userRole}.${documentState}`;
    const allowedElements = getProperty(UI_PERMISSIONS, path, [])
      || [];
    return allowedElements.includes(elementName);
  };
}

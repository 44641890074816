/* eslint-disable no-restricted-syntax */

/**
 * @callback BoxFocusCallback
 * @param {*} box
 * @return {void}
 */

/**
 * @typedef BoxWordAndCharacterCount
 *
 * @property {number} words
 * @property {number} characters
 */

/**
 * @typedef BoxKey Box properties to identify box
 *
 * @property {*} id server-generated ID
 * @property {*} boxKey optimistic ID
 */

import { BOX_TYPES } from '~/assets/helpers/boxes';
import { TextCounter } from '~/assets/libs/BoxManager/TextCounter';

/**
 * @typedef BoxPayload Box state snapshot
 * @extends BoxKey
 * @property {string} type
 * @property {object} content
 * @property {number} [words]
 * @property {number} [characters]
 */

export const BOX_HISTORY_LIMIT = 100;

export const BOX_KEY_PROPS = ['id', 'boxKey'];

/**
 * Box properties containing state
 * @type {string[]}
 */
export const BOX_STATE_PAYLOAD_KEYS = [
  'type',
  'content',
  'words',
  'characters',
];

/**
 * Copy props from source to target
 *
 * @param {object} source
 * @param {object} target
 * @param {string[]} props
 * @return {void}
 */
export const pickTo = (source, target, props) => {
  for (const prop of props) {
    if (prop in source) {
      target[prop] = source[prop];
    }
  }
};

/**
 * @param {*} box Box state
 * @return {BoxKey}
 */
export const normalizeKey = (box) => {
  const result = {};

  for (const prop of BOX_KEY_PROPS) result[prop] = box[prop] ? String(box[prop]) : null;

  return result;
};

/**
 * Build DOM XPath expression from root to target node
 *
 * @param {Node} root
 * @param {Node} target
 * @return {string} string representation of xpath expression
 */
export const buildXPathToTarget = (root, target) => {
  if (root === target || !root.contains(target)) return '.';

  const path = [];
  let node = target;

  while (node !== root) {
    if (!node.parentNode) throw new Error('Target is not a descendant of the root node.');

    const index = Array.from(node.parentNode.childNodes).indexOf(node);
    path.unshift(`child::node()[${index + 1}]`);
    node = node.parentNode;

    if (node === root) break;
  }

  return path.join('/');
};

/**
 * Find target node by given XPath
 *
 * @param {Node} root
 * @param {string} xpath
 * @return {Node}
 */
export const findTargetByXPath = (root, xpath) => {
  if (!xpath) return root;

  const result = document.evaluate(xpath, root, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
  const target = result.singleNodeValue;

  if (!target) throw new Error(`Target not found in root by xpath: ${xpath}`);

  return target;
};

/**
 * @param {BoxPayload} payload
 * @return {string|null}
 */
export const getBoxText = (payload) => {
  switch (payload.type) {
    case BOX_TYPES.TITLE:
    case BOX_TYPES.LEAD_TEXT:
    case BOX_TYPES.TEXT:
    case BOX_TYPES.HEADER:
    case BOX_TYPES.LIST:
    case BOX_TYPES.QUOTE:
    case BOX_TYPES.CONTAINER:
    case BOX_TYPES.AUTHOR_DESC:
    case BOX_TYPES.DISCLAIMER:
      return payload.content.text;
    case BOX_TYPES.WIDE_IMAGE:
      return payload.content.imageCaption;
    case BOX_TYPES.YOUTUBE:
    case BOX_TYPES.VIMEO:
      return payload.content.embedCaption;

    default:
      return null;
  }
};

/**
 * Can left be replaced with right?
 *
 * @param {BoxPayload} left
 * @param {BoxPayload} right
 * @return {boolean}
 */
export const checkBoxReplaceable = (left, right) => {
  const sameType = left.type === right.type;
  const leftText = getBoxText(left);
  const rightText = getBoxText(right);
  const leftTextCount = TextCounter.count(leftText);
  const rightTextCount = TextCounter.count(rightText);
  const bothContainsText = Boolean(leftText) && Boolean(rightText);

  return (
    sameType
    && bothContainsText
    && leftTextCount.getWords() === rightTextCount.getWords()

    // added new characters to word
    && rightTextCount.getChars() > leftTextCount.getChars()
  );
};

/**
 * @param {*} left
 * @param {*} right
 * @return {boolean}
 * @private
 */
export const equalsAsString = (left, right) => Boolean(left) && Boolean(right) && String(left) === String(right);

/**
 * @param {BoxKey} left
 * @param {BoxKey} right
 * @return {boolean}
 */
export const isSameBoxKey = (left, right) => Boolean(left) && (
  equalsAsString(left.id, right.id) || equalsAsString(left.boxKey, right.boxKey)
);

import CSSVars from '~/assets/styles/export.scss';
import { getDeviceType } from '~/assets/libs/device';

export const BREAKPOINTS = {
  XXS: 'XXS',
  XS: 'XS',
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
};

export const BREAKPOINT_WIDTHS = {
  [BREAKPOINTS.XXS]: parseInt(CSSVars['breakpoint-xxs']),
  [BREAKPOINTS.XS]: parseInt(CSSVars['breakpoint-xs']),
  [BREAKPOINTS.SM]: parseInt(CSSVars['breakpoint-sm']),
  [BREAKPOINTS.MD]: parseInt(CSSVars['breakpoint-md']),
  [BREAKPOINTS.LG]: parseInt(CSSVars['breakpoint-lg']),
};

export function getBreakpoint(width = 0) {
  switch (true) {
    case (width <= BREAKPOINT_WIDTHS[BREAKPOINTS.XS] - 1):
      return BREAKPOINTS.XXS;
    case (width >= BREAKPOINT_WIDTHS[BREAKPOINTS.XS]
      && width <= BREAKPOINT_WIDTHS[BREAKPOINTS.SM] - 1):
      return BREAKPOINTS.XS;
    case (width >= BREAKPOINT_WIDTHS[BREAKPOINTS.SM]
      && width <= BREAKPOINT_WIDTHS[BREAKPOINTS.MD] - 1):
      return BREAKPOINTS.SM;
    case (width >= BREAKPOINT_WIDTHS[BREAKPOINTS.MD]
      && width <= BREAKPOINT_WIDTHS[BREAKPOINTS.LG] - 1):
      return BREAKPOINTS.MD;
    case (width >= BREAKPOINT_WIDTHS[BREAKPOINTS.LG]):
      return BREAKPOINTS.LG;
    default:
      return BREAKPOINTS.XS;
  }
}

export function isMobileBreakpoint(breakpoint) {
  return [
    BREAKPOINTS.XXS,
    BREAKPOINTS.XS,
    BREAKPOINTS.SM,
  ].includes(breakpoint);
}

export function getDeviceViewportData() {
  const breakpoint = getBreakpoint(window.innerWidth);
  const isMobile = isMobileBreakpoint(breakpoint);
  const deviceType = getDeviceType(window.navigator.userAgent);
  return {
    isMobile,
    isDesktop: !isMobile,
    breakpoint,
    width: window.innerWidth,
    height: window.innerHeight,
    deviceType,
  };
}

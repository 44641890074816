import { nanoid as getId } from 'nanoid';
import React from 'react';
import { getDeviceViewportData } from '~/assets/helpers/viewport';

/**
 * @typedef {Object} deviceType
 * @property {Object} apple
 * @property {Boolean} apple.phone
 * @property {Boolean} apple.ipod
 * @property {Boolean} apple.tablet
 * @property {Boolean} apple.device
 * @property {Boolean} apple.mac
 * @property {Object} amazon
 * @property {Boolean} amazon.phone
 * @property {Boolean} amazon.tablet
 * @property {Boolean} amazon.device
 * @property {Object} android
 * @property {Boolean} android.phone
 * @property {Boolean} android.tablet
 * @property {Boolean} android.device
 * @property {Boolean} windows
 * @property {Boolean} windows.phone
 * @property {Boolean} windows.tablet
 * @property {Boolean} windows.device
 * @property {Object} other
 * @property {Boolean} other.blackberry
 * @property {Boolean} other.blackberry10
 * @property {Boolean} other.opera
 * @property {Boolean} other.firefox
 * @property {Boolean} other.chrome
 * @property {Boolean} other.safari
 * @property {Boolean} other.device
 * @property {Boolean} any
 * @property {Boolean} phone
 * @property {Boolean} tablet
 * */

/**
 * @typedef {Object} viewportData
 * @property {Boolean} isMobile - Does viewport width inferred as mobile
 * @property {Boolean} isDesktop - Does viewport width inferred as desktop
 * @property {String} breakpoint - Current breakpoint name
 * @property {Number} width - Current viewport width
 * @property {Number} height - Current viewport width
 * @property {deviceType} deviceType - Current device
 * */

let sharedViewportData = getDeviceViewportData();

const settersMap = new Map();

window.addEventListener('resize', () => {
  sharedViewportData = getDeviceViewportData();
  settersMap.forEach((setter) => {
    if (typeof setter === 'function') {
      setter(sharedViewportData);
    }
  });
}, {
  passive: true,
  capture: false,
});

/**
 * Viewport data hook
 * @return {viewportData}
 * */
export function useViewport() {
  const [viewportData, setViewportData] = React.useState(sharedViewportData);
  const keyRef = React.useRef(getId());

  React.useEffect(() => {
    const key = keyRef.current;
    if (!settersMap.has(key)) {
      settersMap.set(key, setViewportData);
    }
    return () => {
      settersMap.delete(key);
    };
  }, []);

  return viewportData;
}

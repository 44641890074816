import React from 'react';
import { createSharedDataHook } from '~/assets/hooks/useSharedData';
import { useLastScroll } from '~/assets/hooks/useLastScroll';

const useSharedData = createSharedDataHook({
  currentModalKey: '',
  openedModalKeys: [],
});

/**
 * @typedef {Object} modalsData
 * @property {Boolean} opened
 * @property {String} currentModalKey
 * @property {String[]} openedModalKeys
 * */

/**
 * @typedef {Function} openModal
 * @param {String} nextModalKey
 * @return {void}
 * */

/**
 * @typedef {Function} closeModal
 * @param {String} modalKey
 * @return {void}
 * */

/**
 * Modals hooks
 * @return {[modalsData, openModal, closeModal]}
 * */
export function useModals() {
  const [{ currentModalKey, openedModalKeys }, setData] = useSharedData();
  const opened = openedModalKeys.length > 0;
  const [, setLastScroll] = useLastScroll();

  const modalsData = React.useMemo(() => {
    return {
      opened,
      currentModalKey,
      openedModalKeys,
    };
  }, [
    opened,
    currentModalKey,
    openedModalKeys,
  ]);

  const openModal = React.useCallback((nextModalKey) => {
    if (!nextModalKey) {
      return;
    }
    setData((prevModalData) => {
      if (prevModalData.openedModalKeys.length === 0) {
        setLastScroll();
      }
      const nextOpenedKeys = prevModalData.openedModalKeys.concat([nextModalKey]);
      return {
        currentModalKey: nextModalKey,
        openedModalKeys: nextOpenedKeys,
      };
    });
  }, [
    setData,
    setLastScroll,
  ]);
  const closeModal = React.useCallback((modalKey) => {
    if (!modalKey) {
      return;
    }
    setData((prevModalData) => {
      const nextOpenedKeys = prevModalData.openedModalKeys.filter((key) => key !== modalKey);
      const [nextCurrentKey = ''] = nextOpenedKeys.slice(-1);
      return {
        currentModalKey: nextCurrentKey,
        openedModalKeys: nextOpenedKeys,
      };
    });
  }, [
    setData,
  ]);

  return [
    modalsData,
    openModal,
    closeModal,
  ];
}

import React from 'react';
import cn from 'classnames';
import { useModals } from '~/components/Shared/Modals/hooks/useModals';
import s from './styles.scss';

function ModalContainer() {
  const [{ opened }] = useModals();

  return (
    <div className={cn(s.modalContainer, opened && s.modalContainerShown)}>
      <div className={s.aligner}>
        <div
          className={s.wrapper}
          id="modal-wrapper"
        />
      </div>
    </div>
  );
}

export default ModalContainer;

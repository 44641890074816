import React from 'react';
import { ROUTES } from '~/assets/helpers/routes';

export const pages = [
  {
    url: ROUTES.signOutPage,
    Component: React.lazy(() => import('~/pages/SignOut')),
  },
  {
    url: ROUTES.signInPage,
    Component: React.lazy(() => import('~/pages/SignIn')),
  },
  {
    url: ROUTES.invitePage,
    Component: React.lazy(() => import('~/pages/Invite')),
  },
  {
    url: ROUTES.resetPasswordPage,
    Component: React.lazy(() => import('~/pages/ResetPassword')),
  },
  {
    private: true,
    url: ROUTES.usersPage,
    Component: React.lazy(() => import('~/pages/Users/List')),
  },
  {
    private: true,
    url: ROUTES.userPage,
    Component: React.lazy(() => import('~/pages/Users/View')),
  },
  {
    private: true,
    url: ROUTES.userEditPage,
    Component: React.lazy(() => import('~/pages/Users/Edit')),
  },
  {
    private: true,
    url: ROUTES.dashboard,
    Component: React.lazy(() => import('~/pages/Dashboard')),
  },
  {
    private: true,
    url: ROUTES.articlesList,
    Component: React.lazy(() => import('~/pages/ArticlesList')),
  },
  {
    private: true,
    url: ROUTES.documentPage,
    Component: React.lazy(() => import('~/pages/Documents/Edit')),
  },
  {
    private: true,
    url: ROUTES.profilePage,
    Component: React.lazy(() => import('~/pages/Profile/View')),
  },
  {
    private: true,
    url: ROUTES.profileEditPage,
    Component: React.lazy(() => import('~/pages/Profile/Edit')),
  },
  {
    private: true,
    url: ROUTES.media,
    Component: React.lazy(() => import('~/pages/Media')),
  },
  {
    private: true,
    url: ROUTES.image,
    Component: React.lazy(() => import('~/pages/Image')),
  },
];

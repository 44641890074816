import React from 'react';
import cn from 'classnames';
import { useViewport } from '~/assets/hooks/useViewport';
import { useMenu } from '~/components/Shared/Menus/hooks/useMenu';
import { BREAKPOINTS } from '~/assets/helpers/viewport';
import MenuTop from '~/components/Shared/Menus/Top';
import MenuDrawer from '~/components/Shared/Menus/Drawer';
import s from './styles.scss';

function MenuContainer() {
  const [closing, setClosing] = React.useState(false);
  const [opened, setOpened] = useMenu();
  const { breakpoint } = useViewport();
  const mobile = [BREAKPOINTS.XXS, BREAKPOINTS.XS].includes(breakpoint);

  const handleClose = React.useCallback(() => {
    setClosing(true);
  }, []);
  const handleCloseAnimationEnd = React.useCallback((e) => {
    if (e.animationName === s['hide-menu-container']) {
      setOpened(false);
      setClosing(false);
    }
  }, [
    setOpened,
  ]);
  if (!mobile) {
    return null;
  }
  return (
    <div
      className={cn(
        s.menuContainer,
        opened && s.shown,
        closing && s.closing,
      )}
      onAnimationEnd={handleCloseAnimationEnd}
    >
      <div className={s.wrapper}>
        <button
          className={cn('btn', s.backdropBtn)}
          type="button"
          tabIndex="-1"
          onClick={handleClose}
        />
        <MenuTop
          className={s.top}
          opened={true}
          onToggle={handleClose}
        />
        <MenuDrawer
          className={s.drawer}
          opened={true}
          mobile={mobile}
          onToggle={handleClose}
        />
      </div>
    </div>
  );
}

export default React.memo(MenuContainer);

export const USERS_INNER_STATE = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  RE_FETCHING: 'RE_FETCHING',
};

export const PROFILE_INNER_STATE = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
};

export const USERS_TABS = {
  ACTIVE: 'ACTIVE',
  INVITED: 'INVITED',
  SUSPENDED: 'SUSPENDED',
};

export const USER_COLORS = [
  '#d5365a', '#8f77f8', '#f07239', '#f5af43',
  '#ed4079', '#429246', '#ed3833', '#2e3191',
  '#f17c7a', '#facd49', '#46a17f', '#5236bc',
  '#57cabe', '#662e8c', '#44a9c5', '#0b53ae',
];

export const USER_ROLES = {
  AUTHOR: 'author',
  EDITOR: 'editor',
  ILLUSTRATOR: 'illustrator',
  EDITOR_IN_CHIEF: 'editor_in_chief',
  PROOF_READER: 'proof_reader',
  TESTER: 'tester',
};

export const USER_ROLE_LABELS = {
  [USER_ROLES.AUTHOR]: 'Writer',
  [USER_ROLES.EDITOR]: 'Editor',
  [USER_ROLES.ILLUSTRATOR]: 'Art Editor',
  [USER_ROLES.EDITOR_IN_CHIEF]: 'Editor-in-Chief',
  [USER_ROLES.PROOF_READER]: 'Copy Editor',
  [USER_ROLES.TESTER]: 'Tester',
};

export function getProfilePayload(profileData) {
  const payload = {};
  if (profileData.role !== undefined) {
    payload.role = profileData.role;
  }
  if (profileData.phone !== undefined) {
    payload.phone = profileData.phone;
  }
  if (profileData.socialsFacebook !== undefined) {
    payload.facebook = profileData.socialsFacebook;
  }
  if (profileData.socialsTelegram !== undefined) {
    payload.telegram = profileData.socialsTelegram;
  }
  if (profileData.socialsTwitter !== undefined) {
    payload.twitter = profileData.socialsTwitter;
  }
  if (profileData.userName !== undefined) {
    payload.name = profileData.userName;
  }
  return payload;
}

export function getUserPayload(userData) {
  const payload = {};
  if (userData.role !== undefined) {
    payload.role = userData.role;
  }
  if (userData.phone !== undefined) {
    payload.phone = userData.phone;
  }
  if (userData.socialsFacebook !== undefined) {
    payload.facebook = userData.socialsFacebook;
  }
  if (userData.socialsTelegram !== undefined) {
    payload.telegram = userData.socialsTelegram;
  }
  if (userData.socialsTwitter !== undefined) {
    payload.twitter = userData.socialsTwitter;
  }
  if (userData.userName !== undefined) {
    payload.name = userData.userName;
  }
  return payload;
}

export function getUserCreatePayload(userData) {
  const payload = {};
  if (userData.email !== undefined) {
    payload.email = userData.email;
  }
  if (userData.password !== undefined) {
    payload.password = userData.password;
  }
  if (userData.userName !== undefined) {
    payload.name = userData.userName;
  }
  if (userData.role !== undefined) {
    payload.role = userData.role;
  }
  if (userData.hashCode !== undefined) {
    payload.invite_code = userData.hashCode;
  }
  return payload;
}

export function getUserContacts(userData) {
  const list = [];
  if (!userData) {
    return list;
  }
  const {
    email,
    phone,
    socialsTwitter,
    socialsFacebook,
    socialsTelegram,
  } = userData;
  if (email) {
    list.push({
      key: 'email',
      to: `mailto:${email}`,
      Icon: null,
      cap: email,
      target: '_blank',
      rel: 'noopener noreferrer',
    });
  }
  if (phone) {
    list.push({
      key: 'phone',
      to: `tel:${phone}`,
      Icon: null,
      cap: `+${phone}`,
      target: '',
      rel: '',
    });
  }
  if (socialsTwitter) {
    const username = socialsTwitter.split('/').reverse()[0];
    list.push({
      key: 'twitter',
      to: `//${socialsTwitter}`,
      cap: `@${username}`,
      target: '_blank',
      rel: 'noopener noreferrer',
    });
  }
  if (socialsFacebook) {
    const username = socialsFacebook.split('/').reverse()[0];
    list.push({
      key: 'facebook',
      to: `//${socialsFacebook}`,
      cap: username,
      target: '_blank',
      rel: 'noopener',
    });
  }
  if (socialsTelegram) {
    const username = socialsTelegram.split('/').reverse()[0];
    list.push({
      key: 'telegram',
      to: `//${socialsTelegram}`,
      cap: `@${username}`,
      target: '_blank',
      rel: 'noopener',
    });
  }
  return list;
}

export function canUser(permissions, subject, actions) {
  if (!permissions || !subject || !actions || actions.length === 0) {
    return false;
  }
  return actions.every((action) => (permissions[subject] || []).includes(action));
}

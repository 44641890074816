import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

const DEFAULT_ARRAY = [];

/**
 * @param {Function} action
 * @return {Function}
 * */
export function useAction(action) {
  const dispatch = useDispatch();

  return React.useMemo(() => {
    if (typeof action !== 'function') {
      return Function.prototype;
    }
    return bindActionCreators(action, dispatch);
  }, [
    action,
    dispatch,
  ]);
}

/**
 * @param {Array<Function>} actions
 * @return {Array<Function>}
 * */
export function useActions(actions) {
  const dispatch = useDispatch();

  return React.useMemo(() => {
    if (!Array.isArray(actions)) {
      return DEFAULT_ARRAY;
    }
    return actions.map((item) => bindActionCreators(item, dispatch));
  }, [
    actions,
    dispatch,
  ]);
}

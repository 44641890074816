import { hot } from 'react-hot-loader/root';
import React from 'react';
import propTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css/normalize.css';
import '~/assets/styles/index.scss';
import '~/app/timeZone';
import { pages } from '~/pages';
import RoutesSwitcher from '~/components/Shared/Pages/RoutesSwitcher';

App.propTypes = {
  store: propTypes.object,
};

App.defaultProps = {
  store: {},
};

function App(props) {
  const {
    store,
  } = props;

  return (
    <Provider store={store}>
      <BrowserRouter>
        <RoutesSwitcher pages={pages} />
      </BrowserRouter>
    </Provider>
  );
}

export default hot(App);

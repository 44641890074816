export const KEYBOARD_KEYS = {
  BACKSPACE: 8,
  ENTER: 13,
  ESC: 27,
  UP: 38,
  DOWN: 40,
  DELETE: 46,
  K: 75,
  U: 85,
};

export const MOUSE_KEYS = {
  LEFT: 0,
};

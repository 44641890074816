import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import s from './styles.scss';

Spinner.propTypes = {
  className: propTypes.string,
};

Spinner.defaultProps = {
  className: '',
};

function Spinner(props) {
  const { className } = props;

  return (
    <span className={cn(s.spinner, className)}>
      <svg
        className={s.spinnerIcon}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.9422 32C10.8578 32 6.02222 29.5467 3 25.4222L8.15556 21.6533C10.0044 24.1422 12.8844 25.6 15.9422 25.6C21.24 25.6 25.5422 21.2978 25.5422 16C25.5422 10.7022 21.24 6.4 15.9422 6.4H15.9067V0V3.2V0C24.76 0 31.9422 7.18222 31.9422 16C31.9422 24.8178 24.76 32 15.9422 32Z" />
      </svg>
    </span>
  );
}

export default Spinner;

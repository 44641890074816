import React from 'react';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useAction } from '~/assets/hooks/useActions';
import { useProfile, useCanUser } from '~/assets/hooks/useProfile';
import { USER_ROLES } from '~/assets/helpers/users';
import { ROUTES } from '~/assets/helpers/routes';
import { actions as profileActions } from '~/store/reducers/profile';
import {
  IconLogoExample,
  IconMenuDashboard,
  IconMenuArticles,
  IconMenuMedia,
  IconMenuUsers,
  IconLogout,
} from '~/components/Icons';
import UserAvatar from '~/components/Shared/Utils/UserAvatar';
import s from './styles.scss';

MenuDrawer.propTypes = {
  className: propTypes.string,
  mobile: propTypes.bool,
};

MenuDrawer.defaultProps = {
  className: '',
  mobile: false,
};

function MenuDrawer(props) {
  const {
    className,
    mobile,
  } = props;
  const { data: profileData } = useProfile();
  const isCopyEditor = profileData.role === USER_ROLES.PROOF_READER;
  const canViewDashboard = useCanUser('dashboard', ['list']) && !isCopyEditor;
  const canViewArticles = useCanUser('tasks', ['list']);
  // const canViewEditorsChoice = useCanUser('editors_choice', ['list']);
  // const canViewRelatedNews = useCanUser('related_news', ['list']);
  const canViewMedia = useCanUser('gallery_images', ['list']);
  const canViewUsers = useCanUser('users', ['list']);
  const canViewProfile = useCanUser('profile', ['view']);
  const signOut = useAction(profileActions.signOut);
  const menu = React.useMemo(() => {
    return [
      {
        key: 'dashboard',
        shown: canViewDashboard,
        title: 'Dashboard',
        type: 'link',
        to: ROUTES.dashboard,
        isActive: (_, location) => location.pathname === '/',
        Icon: IconMenuDashboard,
      },
      {
        key: 'articles',
        shown: canViewArticles,
        title: 'Articles',
        type: 'link',
        to: ROUTES.articlesList,
        Icon: IconMenuArticles,
      },
      {
        key: 'media',
        shown: canViewMedia,
        title: 'Media',
        type: 'link',
        to: ROUTES.media,
        Icon: IconMenuMedia,
      },
      {
        key: 'users',
        shown: canViewUsers,
        title: 'Users',
        type: 'link',
        to: ROUTES.usersPage,
        Icon: IconMenuUsers,
      },
    ].filter(({ shown }) => shown);
  }, [
    // mobile,
    canViewDashboard,
    canViewArticles,
    // canViewEditorsChoice,
    // canViewRelatedNews,
    canViewMedia,
    canViewUsers,
  ]);

  const handleSignOut = React.useCallback((e) => {
    e.preventDefault();
    signOut();
  }, [
    signOut,
  ]);

  if (!canViewProfile) {
    return null;
  }

  return (
    <div className={cn(
      s.menuDrawer,
      !mobile && s.desktop,
      className,
    )}>
      <div className={s.wrapper}>
        <ul className={s.list}>
          {!mobile
            && <li className={s.item}>
              <span className={cn('btn__wrp', s.actionWrp)}>
                <IconLogoExample className={cn('btn__icon', s.logoIcon)} />
              </span>
            </li>
          }
          {menu.map((item) => (
            <li
              className={s.item}
              key={item.key}
            >
              {item.type === 'link'
              && <NavLink
                className={cn(
                  'btn',
                  s.action,
                  item.disabled && s.actionDisabled,
                )}
                activeClassName={s.actionActive}
                to={item.to}
                isActive={item.isActive}
                exact={true}
                tabIndex={item.disabled
                  ? '-1'
                  : '0'}
                title={item.title}
              >
                <span className={cn('btn__wrp', s.actionWrp)}>
                  {item.Icon
                    ? <item.Icon className={cn('btn__icon', s.actionIcon)} />
                    : <span className={cn('btn__icon', s.actionIcon)} />
                  }
                  <span className={cn('btn__label', s.actionLabel)}>
                    {item.title}
                  </span>
                </span>
              </NavLink>}
            </li>
          ))}
          <li className={cn(s.item, s.itemBottom)}>
            {canViewProfile
            && <NavLink
              className={cn('btn', s.action)}
              activeClassName={s.actionActive}
              to={ROUTES.profilePage}
              exact={true}
              title="My profile"
            >
              <span className={cn('btn__wrp', s.actionWrp)}>
                <UserAvatar
                  className={cn('btn__icon', s.actionIcon, s.avatarIcon)}
                  withRing={true}
                  userName={profileData.userName}
                  initials={profileData.initials}
                  color={profileData.color}
                />
                <span className={cn('btn__label', s.actionLabel)}>
                  My profile
                </span>
              </span>
            </NavLink>}
          </li>
          <li className={s.item}>
            <button
              className={cn('btn', s.action)}
              type="button"
              onClick={handleSignOut}
            >
              <span className={cn('btn__wrp', s.actionWrp)}>
                <IconLogout className={cn('btn__icon', s.actionIcon)} />
                <span className={cn('btn__label', s.actionLabel)}>
                  Log out
                </span>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MenuDrawer;

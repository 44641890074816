import { pickTo, BOX_STATE_PAYLOAD_KEYS } from './utils';

/**
 * Box state snapshot
 */
export class BoxState {
  /**
   * @param {BoxPayload} payload
   * @param {DOMSelectionRange|null} selectionRange
   * @return {BoxState}
   */
  static create(payload, selectionRange) {
    const snapshot = {};

    pickTo(payload, snapshot, BOX_STATE_PAYLOAD_KEYS);

    return new this(snapshot, selectionRange);
  }

  /**
   * @param {BoxPayload}
   * @private
   * @readonly
   */
  _payload;

  /**
   * @type {DOMSelectionRange|null}
   * @private
   * @readonly
   */
  _selectionRange;

  /**
   * @param {BoxPayload} payload
   * @param {DOMSelectionRange|null} selectionRange
   */
  constructor(payload, selectionRange) {
    this._payload = payload;
    this._selectionRange = selectionRange;
  }

  /**
   * @return {BoxPayload}
   */
  getPayload() {
    return this._payload;
  }

  /**
   * @return {DOMSelectionRange|null}
   */
  getSelectionRange() {
    return this._selectionRange;
  }

  /**
   * @param {BoxPayload} box
   * @return {BoxPayload}
   */
  applyTo(box) {
    const result = { ...box };

    pickTo(this._payload, result, BOX_STATE_PAYLOAD_KEYS);

    return result;
  }
}

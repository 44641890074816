import isEmpty from 'lodash/isEmpty';
import htmlParser from 'html-parse-stringify2';
import sanitizeHtml from 'sanitize-html';
import getProperty from '~/assets/libs/getProperty';
import { cyrillicRegExp, hasCyrillic } from '~/assets/libs/noRussian';
import { htmlTagsRegExp } from '~/assets/libs/noHTML';
import { KEYBOARD_KEYS } from '~/assets/helpers/keys';
import { DOCUMENT_STATES } from '~/assets/helpers/documents';
import { pipeFunctions } from '~/assets/libs/utils';

const INLINE_TAGS = [
  'a',
  'b',
  'strong',
  'i',
  'em',
  'cite',
  'u',
  'ins',
  's',
  'del',
  'strike',
  'font',
  'span',
];

const ALLOWED_ATTRS = {
  a: [
    'href',
    'type',
    'document_id',
    'style',
  ],
  font: [
    'color',
  ],
  span: [
    'style',
  ],
};

const ALLOWED_STYLES = {
  '*': {
    'font-weight': [/^.+$/i],
    'font-style': [/^.+$/i],
    'text-decoration': [/^underline$/i, /^line-through$/i],
    'text-decoration-line': [/^.+$/i],
  },
};

const CONVERTABLE_TAGS = [
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'ul',
  'ol',
  'blockquote',
];

const ALLOWED_TAGS = [
  ...INLINE_TAGS,
  ...CONVERTABLE_TAGS,
  'li',
  'br',
];

const NODE_NAMES = {
  REGULAR: 'regular',
  BOLD: 'bold',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'strikethrough',
  ITALIC: 'italic',
  FONT: 'font',
  LIST_ORDERED: 'ordered_list',
  LIST_UNORDERED: 'unordered_list',
  LIST_ITEM: 'list_item',
  LINK: 'link',
  LINK_EXTERNAL: 'external_link',
  LINK_INTERNAL: 'internal_link',
};

export const BOX_TYPES = {
  TITLE: 'title',
  LEAD_TEXT: 'lead_text',
  COVER_IMAGE: 'cover_image',
  TEXT: 'text',
  HEADER: 'header',
  LIST: 'list',
  QUOTE: 'quote',
  CONTAINER: 'jumbotron',
  DISCLAIMER: 'disclaimer',
  EMBED: 'embed',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  VIMEO: 'vimeo',
  YOUTUBE: 'youtube',
  DOCUMENTCLOUD: 'documentcloud',
  BUZZSPROUT: 'buzzsprout',
  WIDE_IMAGE: 'wide_image',
  MEDIA_CAPTION: 'media_caption',
  AUTHOR_DESC: 'author_desc',
  UNKNOWN: 'unknown',
  SUBSCRIPTION_FORM: 'subscription_form',
};

export const BOX_SANITIZE_CONFIGS = {
  [BOX_TYPES.TEXT]: {
    allowedTags: [
      ...INLINE_TAGS,
    ],
    allowedAttributes: {
      ...ALLOWED_ATTRS,
    },
  },
  [BOX_TYPES.HEADER]: {
    allowedTags: [],
    allowedAttributes: {},
  },
  [BOX_TYPES.LIST]: {
    allowedTags: [
      ...INLINE_TAGS,
      'li',
    ],
    allowedAttributes: {
      ...ALLOWED_ATTRS,
    },
  },
  [BOX_TYPES.QUOTE]: {
    allowedTags: [
      'a',
      'b',
      'strong',
      'u',
      'ins',
      's',
      'del',
      'strike',
      'font',
      'span',
    ],
    allowedAttributes: {
      ...ALLOWED_ATTRS,
    },
  },
  [BOX_TYPES.CONTAINER]: {
    allowedTags: [
      'a',
      'i',
      'em',
      'cite',
      'u',
      'ins',
      's',
      'del',
      'strike',
      'font',
    ],
    allowedAttributes: {
      ...ALLOWED_ATTRS,
    },
  },
  [BOX_TYPES.DISCLAIMER]: {
    allowedTags: [
      'a',
      'i',
      'em',
      'cite',
      'u',
      'ins',
      's',
      'del',
      'strike',
      'font',
    ],
    allowedAttributes: {
      ...ALLOWED_ATTRS,
    },
  },
  [BOX_TYPES.MEDIA_CAPTION]: {
    allowedTags: [
      ...INLINE_TAGS,
    ],
    allowedAttributes: {
      ...ALLOWED_ATTRS,
    },
  },
  [BOX_TYPES.AUTHOR_DESC]: {
    allowedTags: [
      'b',
      'strong',
      'i',
      'em',
      'cite',
      'span',
    ],
    allowedAttributes: {
      span: ALLOWED_ATTRS.span,
    },
  },
  [BOX_TYPES.UNKNOWN]: {
    allowedTags: [],
    allowedAttributes: {},
  },
};

export const IMAGE_BOX_TYPES = {
  COVER: 'title_image',
  WIDE: 'wide_box',
};

export const LIST_BOX_TYPES = {
  ORDERED: 'ordered',
  UNORDERED: 'unordered',
};

export const HEADER_BOX_TYPES = {
  LEVEL_2: 2,
  LEVEL_3: 3,
};

export const CONTAINER_BOX_TYPES = {
  SMALL: 'small',
  BIG: 'big',
};

export const SUBSCRIPTION_FORM_BOX_TYPES = {
  LAW_DECODED: 'law_decoded',
  MARKETS_OUTLOOK: 'markets_outlook',
  DEFI_NEWSLETTER: 'defi_newsletter',
  CONSULTING_NEWSLETTER: 'consulting_newsletter',
  NIFTY_NEWSLETTER: 'nifty_newsletter',
  CRYPTO_BIZ: 'crypto_biz',
};

export const LINK_TYPES = {
  EXTERNAL: 'external_link',
  INTERNAL: 'internal_link',
};

export const TEXT_TYPE_CHANGE_MENU = [
  {
    key: `${BOX_TYPES.TEXT}`,
    label: 'Text',
    type: BOX_TYPES.TEXT,
  },
  {
    key: `${BOX_TYPES.HEADER}`,
    label: 'Header',
    children: [
      {
        key: `${BOX_TYPES.HEADER}-${HEADER_BOX_TYPES.LEVEL_2}`,
        label: 'Header 2',
        className: 'header2',
        type: BOX_TYPES.HEADER,
        content: {
          level: HEADER_BOX_TYPES.LEVEL_2,
        },
      },
      {
        key: `${BOX_TYPES.HEADER}-${HEADER_BOX_TYPES.LEVEL_3}`,
        label: 'Header 3',
        className: 'header3',
        type: BOX_TYPES.HEADER,
        content: {
          level: HEADER_BOX_TYPES.LEVEL_3,
        },
      },
    ],
  },
  {
    key: `${BOX_TYPES.QUOTE}`,
    label: 'Quote',
    type: BOX_TYPES.QUOTE,
  },
  {
    key: `${BOX_TYPES.CONTAINER}`,
    label: 'Container',
    children: [
      {
        key: `${BOX_TYPES.CONTAINER}-${CONTAINER_BOX_TYPES.BIG}`,
        label: 'Big',
        type: BOX_TYPES.CONTAINER,
        content: {
          size: CONTAINER_BOX_TYPES.BIG,
        },
      },
      {
        key: `${BOX_TYPES.CONTAINER}-${CONTAINER_BOX_TYPES.SMALL}`,
        label: 'Small',
        type: BOX_TYPES.CONTAINER,
        content: {
          size: CONTAINER_BOX_TYPES.SMALL,
        },
      },
    ],
  },
  {
    key: `${BOX_TYPES.AUTHOR_DESC}`,
    label: 'Author Description',
    type: BOX_TYPES.AUTHOR_DESC,
  },
];

export const LIST_TYPE_CHANGE_MENU = [
  {
    key: `${BOX_TYPES.LIST}`,
    label: 'List',
    children: [
      {
        key: `${BOX_TYPES.LIST}-${LIST_BOX_TYPES.ORDERED}`,
        label: 'Numbered',
        type: BOX_TYPES.LIST,
        content: {
          listType: LIST_BOX_TYPES.ORDERED,
        },
      },
      {
        key: `${BOX_TYPES.LIST}-${LIST_BOX_TYPES.UNORDERED}`,
        label: 'Bullets',
        type: BOX_TYPES.LIST,
        content: {
          listType: LIST_BOX_TYPES.UNORDERED,
        },
      },
    ],
  },
];

export function getTypedLinkNode(node) {
  let attrs = {};
  const type = node.attrs.type || LINK_TYPES.EXTERNAL;
  switch (type) {
    case LINK_TYPES.INTERNAL:
      attrs = {
        document_id: node.attrs.document_id
          ? parseInt(node.attrs.document_id)
          : null,
      };
      break;
    case LINK_TYPES.EXTERNAL:
    default:
      attrs = {
        url: node.attrs.href || '',
      };
      break;
  }
  return {
    type,
    children: getTypedNodes(node.children || []),
    ...attrs,
  };
}

export function getTypedColorNode(node) {
  const { name, children, attrs } = node;
  return {
    type: name,
    children: getTypedNodes(children),
    color: attrs.color,
  };
}

export function getTypedNodeName(name) {
  switch (name) {
    case 'ol':
      return NODE_NAMES.LIST_ORDERED;
    case 'ul':
      return NODE_NAMES.LIST_UNORDERED;
    case 'li':
      return NODE_NAMES.LIST_ITEM;
    case 'b':
    case 'strong':
      return NODE_NAMES.BOLD;
    case 'u':
    case 'ins':
      return NODE_NAMES.UNDERLINE;
    case 's':
    case 'del':
    case 'strike':
      return NODE_NAMES.STRIKETHROUGH;
    case 'i':
    case 'em':
    case 'cite':
      return NODE_NAMES.ITALIC;
    case 'a':
      return NODE_NAMES.LINK;
    case 'font':
      return NODE_NAMES.FONT;
    case 'r':
    default:
      return NODE_NAMES.REGULAR;
  }
}

export function getTypedNodes(nodes) {
  return nodes.map((node) => {
    if (node.type !== 'tag') {
      return {
        type: NODE_NAMES.REGULAR,
        text: node.content,
      };
    }
    if (node.name === 'wrap') {
      return getTypedNodes(node.children || []);
    }
    const nodeName = getTypedNodeName(node.name);
    switch (nodeName) {
      case NODE_NAMES.FONT:
        return getTypedColorNode(node);
      case NODE_NAMES.LINK:
      case NODE_NAMES.LINK_INTERNAL:
      case NODE_NAMES.LINK_EXTERNAL:
        return getTypedLinkNode(node);
      default:
        return {
          type: nodeName,
          children: getTypedNodes(node.children || []),
        };
    }
  });
}

export function getTagString(str, tag, attrs) {
  return `<${tag}${getAttributesString(attrs)}>${str}</${tag}>`;
}

export function getTagStringFromStyledNode(node, parentNode) {
  const allowedAttributes = [
    'font-weight',
    'font-style',
    'text-decoration-line',
    'text-decoration',
  ];
  let innerHTML = node.children
    .map(getTagStringFromNode)
    .join('');
  const parentNodeName = getProperty(parentNode, 'name', '');
  const styleString = getProperty(node, 'attrs.style', '');
  const {
    'font-weight': fontWeight,
    'font-style': fontStyle,
    'text-decoration-line': textDecorationLine,
    'text-decoration': textDecoration,
  } = styleString.split(';').reduce((result, pair) => {
    const [key, value] = pair.split(':').map((item) => item.trim());
    if (allowedAttributes.includes(key)) {
      result[key] = value !== undefined
        ? value
        : '';
    }
    return result;
  }, {
    'font-weight': '',
    'font-style': '',
    'text-decoration-line': '',
    'text-decoration': '',
  });
  if (parentNodeName !== 'a') {
    if (
      textDecoration.includes('line-through')
      || textDecorationLine.includes('line-through')
    ) {
      innerHTML = getTagString(innerHTML, 'strike');
    }
    if (
      textDecoration.includes('underline')
      || textDecorationLine.includes('underline')
    ) {
      innerHTML = getTagString(innerHTML, 'u');
    }
  }
  if (fontStyle === 'italic') {
    innerHTML = getTagString(innerHTML, 'i');
  }
  if (fontWeight.includes('bold') || parseInt(fontWeight) > 400) {
    innerHTML = getTagString(innerHTML, 'b');
  }
  return innerHTML;
}

export function getTagStringFromTypedNode(node) {
  const innerHTML = node.children
    ? node.children
      .map(getTagStringFromTypedNode)
      .join('')
    : node.text;
  switch (node.type) {
    case NODE_NAMES.FONT:
      return getTagString(innerHTML, 'font', { color: node.color });
    case NODE_NAMES.LIST_ORDERED:
      return getTagString(innerHTML, 'ol');
    case NODE_NAMES.LIST_UNORDERED:
      return getTagString(innerHTML, 'ul');
    case NODE_NAMES.LIST_ITEM:
      return getTagString(innerHTML, 'li');
    case NODE_NAMES.BOLD:
      return getTagString(innerHTML, 'b');
    case NODE_NAMES.ITALIC:
      return getTagString(innerHTML, 'i');
    case NODE_NAMES.UNDERLINE:
      return getTagString(innerHTML, 'u');
    case NODE_NAMES.STRIKETHROUGH:
      return getTagString(innerHTML, 'strike');
    case NODE_NAMES.LINK_EXTERNAL:
      return getTagString(innerHTML, 'a', {
        type: 'external_link',
        href: node.url,
        target: '_blank',
        rel: 'noopener noreferrer',
      });
    case NODE_NAMES.LINK_INTERNAL:
      return getTagString(innerHTML, 'a', {
        type: 'internal_link',
        document_id: node.document_id,
      });
    case NODE_NAMES.REGULAR:
    default:
      return node.text;
  }
}

export function getTagStringFromTypedNodes(nodes) {
  if (!nodes) {
    return '';
  }
  return nodes
    .map(getTagStringFromTypedNode)
    .join('');
}

export function getTagStringFromNode(node, parentNode) {
  if (node.type === 'text') {
    return node.content;
  }
  if (node.type !== 'tag') {
    return '';
  }
  const innerHTML = node.children
    .map(getTagStringFromNode)
    .join('');
  switch (node.name) {
    case 'b':
    case 'strong':
      return getTagString(innerHTML, 'b');
    case 'i':
    case 'em':
    case 'cite':
      return getTagString(innerHTML, 'i');
    case 'u':
      return getTagString(innerHTML, 'u');
    case 'strike':
      return getTagString(innerHTML, 'strike');
    case 'a':
      return getLinkStringFromNode(node);
    case 'ol':
      return getTagString(innerHTML, 'ol');
    case 'ul':
      return getTagString(innerHTML, 'ul');
    case 'li':
      return getTagString(innerHTML, 'li');
    case 'span':
    default:
      return getTagStringFromStyledNode(node, parentNode);
  }
}

export function getTextFromHtml(html) {
  const node = window.document.createElement('div');
  node.innerHTML = html;
  return node.textContent || '';
}

export function getTextFromNode(node) {
  if (node.type === 'text') {
    return node.content;
  }
  return node.children
    .map(getTextFromNode)
    .join('');
}

export function getAttributesString(attributes) {
  if (isEmpty(attributes)) {
    return '';
  }
  return ` ${Object.entries(attributes)
    .map(([key, value]) => `${key}="${value}"`)
    .join(' ')}`;
}

export function getLinkStringFromNode(node) {
  const innerHTML = node.children
    .map((childNode) => getTagStringFromNode(childNode, node))
    .join('');
  return getTagString(innerHTML, 'a', {
    type: 'external_link',
    href: node.attrs.href || '',
  });
}

export function findNodesParentByTagName(node, parentTagName) {
  if (!node || !parentTagName) {
    return null;
  }
  if (node.tagName === parentTagName) {
    return node;
  }
  return findNodesParentByTagName(node.parentElement, parentTagName);
}

export function findLinkParent(node) {
  return findNodesParentByTagName(node, 'A');
}

export function createTagNodeByName(name) {
  const [node] = htmlParser.parse(`<${name}>`);
  return node;
}

export function getConvertableNodesFromString(string) {
  const nodes = htmlParser.parse(string);
  return getFlattenNodes(nodes);
}

export function getFlattenNodes(nodes) {
  return nodes.reduce((convertableNodes, node) => {
    if (!['tag', 'text'].includes(node.type)) {
      return convertableNodes;
    }
    if (node.type === 'tag') {
      if (hasConvertableChildNodes(node) || hasSpanWithSingleBreakTagChild(node)) {
        return convertableNodes.concat(getFlattenNodes(node.children));
      }
      if (isSpanWithSingleBreakTag(node)) {
        const virtualParagraph = createTagNodeByName('virtual-paragraph');
        return convertableNodes.concat([virtualParagraph]);
      }
      if (CONVERTABLE_TAGS.includes(node.name)) {
        return convertableNodes.concat([node]);
      }
    }
    const previousVirtualParagraph = convertableNodes[convertableNodes.length - 1];
    if (previousVirtualParagraph && previousVirtualParagraph.name === 'virtual-paragraph') {
      previousVirtualParagraph.children.push(node);
      return convertableNodes;
    }
    const virtualParagraph = createTagNodeByName('virtual-paragraph');
    virtualParagraph.children.push(node);
    return convertableNodes.concat([virtualParagraph]);
  }, []);
}

export function hasSpanWithSingleBreakTagChild(node) {
  return node.children.some((childNode) => {
    return isSpanWithSingleBreakTag(childNode);
  });
}

export function isSpanWithSingleBreakTag(node) {
  return node.type === 'tag'
    && node.name === 'span'
    && node.children.length === 1
    && node.children[0].type === 'tag'
    && node.children[0].name === 'br';
}

export function hasConvertableChildNodes(node) {
  return node.children.some((childNode) => {
    return childNode.type === 'tag'
      && CONVERTABLE_TAGS.includes(childNode.name);
  });
}

export function getTextFromNodes(nodes) {
  return nodes
    .reduce((result, node) => {
      let text = '';
      if (node.type !== 'tag') {
        return result;
      }
      switch (node.name) {
        case 'p':
        case 'virtual-paragraph':
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          text += node.children
            .map((item) => getTagStringFromNode(item))
            .join('');
          break;
        case 'blockquote':
          text += getTextFromNode(node);
          break;
        default:
          text += getTagStringFromNode(node);
          break;
      }
      return result + text;
    }, '');
}

export function getBoxesFromNodes(nodes) {
  return nodes
    .reduce((result, node) => {
      if (node.type !== 'tag') {
        return result;
      }
      switch (node.name) {
        case 'p':
        case 'virtual-paragraph':
          result.push({
            type: BOX_TYPES.TEXT,
            content: {
              text: node.children
                .map((item) => getTagStringFromNode(item))
                .join(''),
            },
          });
          break;
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          result.push({
            type: BOX_TYPES.HEADER,
            content: {
              text: node.children
                .map((item) => getTagStringFromNode(item))
                .join(''),
              level: node.name[1] > 2
                ? HEADER_BOX_TYPES.LEVEL_3
                : HEADER_BOX_TYPES.LEVEL_2,
            },
          });
          break;
        case 'ol':
        case 'ul':
          result.push({
            type: BOX_TYPES.LIST,
            content: {
              text: getTagStringFromNode(node),
              listType: node.name === 'ol'
                ? LIST_BOX_TYPES.ORDERED
                : LIST_BOX_TYPES.UNORDERED,
            },
          });
          break;
        case 'blockquote':
          result.push({
            type: BOX_TYPES.QUOTE,
            content: {
              text: getTextFromNode(node),
            },
          });
          break;
        default:
          result.push({
            type: BOX_TYPES.TEXT,
            content: {
              text: getTagStringFromNode(node),
            },
          });
          break;
      }
      return result;
    }, [])
    .map((item) => {
      switch (item.type) {
        case BOX_TYPES.HEADER:
        case BOX_TYPES.LIST:
        case BOX_TYPES.QUOTE:
        case BOX_TYPES.TEXT: {
          const html = sanitizeHtml(item.content.text, BOX_SANITIZE_CONFIGS[item.type]);
          const { words, characters } = getTextFieldWordsCount(html);
          return {
            ...item,
            content: {
              ...item.content,
              text: html,
            },
            words,
            characters,
          };
        }
        default:
          return item;
      }
    })
    .filter((item) => {
      switch (item.type) {
        case BOX_TYPES.HEADER:
        case BOX_TYPES.LIST:
        case BOX_TYPES.QUOTE:
        case BOX_TYPES.TEXT: {
          const textWithoutHTMLTags = item.content.text.replace(htmlTagsRegExp, '');
          return /[\w\d]/gi.test(textWithoutHTMLTags);
        }
        default:
          return true;
      }
    });
}

export function unwrapNode(node) {
  if (!(node instanceof HTMLElement)) {
    return;
  }
  const parentNode = node.parentNode;
  if (!parentNode) {
    return;
  }
  while (node.firstChild) {
    parentNode.insertBefore(node.firstChild, node);
  }
  parentNode.removeChild(node);
}

export function getTextTypeChangeMenu(onTypeChange, type, menuKey) {
  const itemsMapper = (items, key) => {
    return items
      .filter((item) => item.key !== key)
      .map((item) => {
        if (item.children !== undefined) {
          return {
            ...item,
            children: itemsMapper(item.children, key),
          };
        }
        return {
          ...item,
          handler: () => {
            if (typeof onTypeChange === 'function') {
              onTypeChange(item.type, item.content);
            }
          },
        };
      });
  };
  switch (type) {
    case BOX_TYPES.TEXT:
    case BOX_TYPES.DISCLAIMER:
    case BOX_TYPES.CONTAINER:
    case BOX_TYPES.HEADER:
    case BOX_TYPES.AUTHOR_DESC:
      return itemsMapper(TEXT_TYPE_CHANGE_MENU, menuKey);
    case BOX_TYPES.LIST:
      return itemsMapper(LIST_TYPE_CHANGE_MENU, menuKey);
    default:
      return [];
  }
}

/**
 * @param {BoxData} prevBoxData
 * @param {BoxData} nextBoxData
 * @return {BoxData} nextBoxData
 * */
export function mergeBoxesData(prevBoxData, nextBoxData) {
  const mergedData = { ...prevBoxData };
  if (nextBoxData.isCreating !== undefined) {
    mergedData.isCreating = nextBoxData.isCreating;
  }
  if (nextBoxData.isDeleting !== undefined) {
    mergedData.isDeleting = nextBoxData.isDeleting;
  }
  if (nextBoxData.id !== undefined) {
    mergedData.id = nextBoxData.id;
  }
  if (nextBoxData.type !== undefined) {
    mergedData.type = nextBoxData.type;
  }
  if (nextBoxData.content !== undefined) {
    mergedData.content = {
      ...mergedData.content,
      ...nextBoxData.content,
    };
  }
  if (nextBoxData.lockedByUser !== undefined) {
    mergedData.lockedByUser = nextBoxData.lockedByUser;
  }
  if (nextBoxData.characters !== undefined) {
    mergedData.characters = nextBoxData.characters;
  }
  if (nextBoxData.words !== undefined) {
    mergedData.words = nextBoxData.words;
  }
  if (nextBoxData.nextBoxId !== undefined) {
    mergedData.nextBoxId = nextBoxData.nextBoxId;
  }
  if (nextBoxData.nonBreakable !== undefined) {
    mergedData.nonBreakable = nextBoxData.nonBreakable;
  }
  if (nextBoxData.nonBreakableLast !== undefined) {
    mergedData.nonBreakableLast = nextBoxData.nonBreakableLast;
  }
  return mergedData;
}

export function getTypedNodesFromString(string) {
  const [wrapNode] = htmlParser.parse(`<wrap>${string}</wrap>`);
  return getTypedNodes(wrapNode.children);
}

export function getBoxesPayload(boxData) {
  const payload = {};

  if (boxData.socketId !== undefined) {
    payload.socketId = boxData.socketId;
  }
  if (boxData.id !== undefined) {
    payload.id = parseInt(boxData.id) || null;
  }
  if (boxData.type !== undefined) {
    payload.type = boxData.type;
  }
  if (boxData.documentId !== undefined) {
    payload.document_id = parseInt(boxData.documentId) || null;
  }
  if (boxData.nextBoxId) {
    payload.insert_before = parseInt(boxData.nextBoxId) || null;
  }
  if (boxData.nonBreakable !== undefined) {
    payload.non_breakable = boxData.nonBreakable;
  }
  if (boxData.nonBreakableLast !== undefined) {
    payload.non_breakable_last = boxData.nonBreakableLast;
  }
  const content = boxData.content;
  switch (boxData.type) {
    case BOX_TYPES.TEXT:
      return {
        ...payload,
        content: {
          ...content,
          text: getTypedNodesFromString(content.text),
        },
      };
    case BOX_TYPES.HEADER:
      return {
        ...payload,
        content: {
          level: content.level,
          text: getTextFromHtml(content.text),
        },
      };
    case BOX_TYPES.LIST: {
      const items = content.text.replace(/<\/?(ol|ul)>/gi, '');
      const tagName = content.listType === LIST_BOX_TYPES.ORDERED
        ? 'ol'
        : 'ul';
      content.text = `<${tagName}>${items}</${tagName}>`;
      return {
        ...payload,
        content: {
          ...content,
          text: getTypedNodesFromString(content.text),
        },
      };
    }
    case BOX_TYPES.QUOTE:
      return {
        ...payload,
        content: {
          ...content,
          text: getTypedNodesFromString(content.text),
        },
      };
    case BOX_TYPES.CONTAINER:
      return {
        ...payload,
        content: {
          ...content,
          text: getTypedNodesFromString(content.text),
        },
      };
    case BOX_TYPES.DISCLAIMER:
      return {
        ...payload,
        content: {
          ...content,
          text: getTypedNodesFromString(content.text),
        },
      };
    case BOX_TYPES.EMBED:
    case BOX_TYPES.DOCUMENTCLOUD:
    case BOX_TYPES.TWITTER:
    case BOX_TYPES.INSTAGRAM:
    case BOX_TYPES.BUZZSPROUT:
      return {
        ...payload,
        content: {
          id: content.embedId,
        },
      };
    case BOX_TYPES.YOUTUBE:
    case BOX_TYPES.VIMEO:
      return {
        ...payload,
        content: {
          id: content.embedId,
          caption: getTypedNodesFromString(content.embedCaption),
        },
      };
    case BOX_TYPES.WIDE_IMAGE: {
      payload.content = {
        image_properties: {},
      };
      if (content.imageId !== undefined) {
        payload.content.image_id = content.imageId;
      }
      if (content.imageCaption !== undefined) {
        payload.content.image_properties.caption = getTypedNodesFromString(content.imageCaption);
      }
      if (content.imageAlt !== undefined) {
        payload.content.image_properties.alt = content.imageAlt;
      }
      if (content.imageTitle !== undefined) {
        payload.content.image_properties.title = content.imageTitle;
      }
      return payload;
    }
    case BOX_TYPES.AUTHOR_DESC:
      return {
        ...payload,
        content: {
          ...content,
          text: getTypedNodesFromString(content.text),
        },
      };
    case BOX_TYPES.SUBSCRIPTION_FORM:
      return {
        ...payload,
        content,
      };
    case BOX_TYPES.UNKNOWN:
    default:
      return payload;
  }
}

export function getImageUsagePayload(imageData) {
  const payload = {};
  if (imageData.content.documentId) {
    payload.document_id = imageData.content.documentId;
  }
  if (imageData.content.imageGalleryId) {
    payload.gallery_image_id = imageData.content.imageGalleryId;
  }
  if (imageData.content.imageType) {
    payload.type = imageData.content.imageType;
  }
  return payload;
}

export function getBoxesReorderPayload(boxesIds) {
  const ids = Array.isArray(boxesIds)
    ? boxesIds
    : [];
  return ids
    .map((item) => parseInt(item) || null)
    .filter((item) => !!item);
}

export function getBoxesValidatorsPerDocumentState(documentState, type) {
  const validators = {
    hasCyrillic,
  };
  switch (documentState) {
    case DOCUMENT_STATES.NEW:
    case DOCUMENT_STATES.IN_PROGRESS: {
      if ([
        BOX_TYPES.TEXT,
        BOX_TYPES.HEADER,
        BOX_TYPES.LIST,
        BOX_TYPES.QUOTE,
        BOX_TYPES.CONTAINER,
        BOX_TYPES.DISCLAIMER,
        BOX_TYPES.AUTHOR_DESC,
      ].includes(type)) {
        validators.isEmpty = isBoxEmpty;
      }
      break;
    }
    case DOCUMENT_STATES.READY_FOR_ILLUSTRATOR: {
      if ([
        BOX_TYPES.EMBED,
        BOX_TYPES.WIDE_IMAGE,
      ].includes(type)) {
        validators.isEmpty = isBoxEmpty;
      }
      break;
    }
    case DOCUMENT_STATES.READY_TO_REVIEW:
    case DOCUMENT_STATES.TEXT_REJECTED:
    case DOCUMENT_STATES.IMAGE_REJECTED:
    case DOCUMENT_STATES.READY_TO_PUBLISH:
    case DOCUMENT_STATES.PUBLISHED:
    case DOCUMENT_STATES.UNPUBLISHED: {
      validators.isEmpty = isBoxEmpty;
      break;
    }
    case DOCUMENT_STATES.CANCELED:
    default:
      break;
  }
  return validators;
}

export function getBoxFieldsValidators(documentState, boxes) {
  return boxes.reduce((result, boxData) => {
    result[boxData.boxKey] = {
      value: boxData,
      validators: getBoxesValidatorsPerDocumentState(documentState, boxData.type),
    };
    return result;
  }, {});
}

export function validateBoxFields(boxFields) {
  return Object
    .values(boxFields)
    .filter((item) => typeof item === 'object')
    .reduce((results, boxField) => {
      const type = boxField.value.type;
      if (results.noBoxes) {
        results.noBoxes = false;
      }
      if (!results.emptyTextBoxes
        && [
          BOX_TYPES.TEXT,
          BOX_TYPES.HEADER,
          BOX_TYPES.LIST,
          BOX_TYPES.QUOTE,
          BOX_TYPES.CONTAINER,
          BOX_TYPES.DISCLAIMER,
          BOX_TYPES.AUTHOR_DESC,
        ].includes(type)
        && boxField.validations.isEmpty) {
        results.emptyTextBoxes = true;
      }
      if (!results.emptyImageBoxes
        && [
          BOX_TYPES.EMBED,
          BOX_TYPES.WIDE_IMAGE,
        ].includes(type)
        && boxField.validations.isEmpty) {
        results.emptyImageBoxes = true;
      }
      return results;
    }, {
      noBoxes: true,
      emptyTextBoxes: false,
      emptyImageBoxes: false,
    });
}

export function validateAdSpace(boxFields) {
  const items = Object
    .values(boxFields)
    .filter((item) => typeof item === 'object');
  const every = items.every((item) => item.nonBreakable);
  const last = items.at(-1).nonBreakableLast;
  return every && last;
}

export function isBoxEmpty(boxData) {
  const isHtmlStringEmpty = (string) => {
    return (string || '')
      .trim()
      .replace(htmlTagsRegExp, '')
      .length === 0;
  };
  switch (boxData.type) {
    case BOX_TYPES.TEXT:
    case BOX_TYPES.HEADER:
    case BOX_TYPES.LIST:
    case BOX_TYPES.QUOTE:
    case BOX_TYPES.CONTAINER:
    case BOX_TYPES.DISCLAIMER:
    case BOX_TYPES.AUTHOR_DESC:
      return isHtmlStringEmpty(boxData.content.text);
    case BOX_TYPES.WIDE_IMAGE:
      return isEmpty(boxData.content.imageId);
    case BOX_TYPES.EMBED:
    case BOX_TYPES.TWITTER:
    case BOX_TYPES.INSTAGRAM:
    case BOX_TYPES.DOCUMENTCLOUD:
    case BOX_TYPES.YOUTUBE:
    case BOX_TYPES.VIMEO:
      return isEmpty(boxData.content.embedId);
    case BOX_TYPES.UNKNOWN:
    default:
      return false;
  }
}

export function isBoxDraggable(type) {
  return ![
    BOX_TYPES.TITLE,
    BOX_TYPES.LEAD_TEXT,
    BOX_TYPES.COVER_IMAGE,
    BOX_TYPES.UNKNOWN,
  ].includes(type);
}

export function isBoxCommentable(type) {
  return ![
    BOX_TYPES.UNKNOWN,
  ].includes(type);
}

export function isBoxTextCommentable(type) {
  return [
    BOX_TYPES.HEADER,
    BOX_TYPES.TEXT,
    BOX_TYPES.LIST,
    BOX_TYPES.QUOTE,
    BOX_TYPES.CONTAINER,
    BOX_TYPES.DISCLAIMER,
    BOX_TYPES.AUTHOR_DESC,
  ].includes(type);
}

export function isBoxDeletableByKeyPress(type) {
  return [
    BOX_TYPES.HEADER,
    BOX_TYPES.TEXT,
    BOX_TYPES.LIST,
    BOX_TYPES.QUOTE,
    BOX_TYPES.CONTAINER,
    BOX_TYPES.DISCLAIMER,
    BOX_TYPES.AUTHOR_DESC,
  ].includes(type);
}

export function isBoxDeletable(type) {
  return [
    BOX_TYPES.TEXT,
    BOX_TYPES.HEADER,
    BOX_TYPES.LIST,
    BOX_TYPES.QUOTE,
    BOX_TYPES.CONTAINER,
    BOX_TYPES.DISCLAIMER,
    BOX_TYPES.EMBED,
    BOX_TYPES.TWITTER,
    BOX_TYPES.INSTAGRAM,
    BOX_TYPES.BUZZSPROUT,
    BOX_TYPES.VIMEO,
    BOX_TYPES.DOCUMENTCLOUD,
    BOX_TYPES.YOUTUBE,
    BOX_TYPES.WIDE_IMAGE,
    BOX_TYPES.AUTHOR_DESC,
    BOX_TYPES.SUBSCRIPTION_FORM,
  ].includes(type);
}

export function doesBoxHasMenu(type) {
  return ![
    BOX_TYPES.TITLE,
    BOX_TYPES.LEAD_TEXT,
    BOX_TYPES.UNKNOWN,
    BOX_TYPES.SUBSCRIPTION_FORM,
  ].includes(type);
}

export function getTextFieldWordsCount(text) {
  let words = 0;
  let characters = 0;
  if (typeof text === 'string') {
    const textWithoutHTML = text.replace(htmlTagsRegExp, '');
    words = textWithoutHTML
      .replace(/(\n|\s+)/g, ' ')
      .trim()
      .split(' ')
      .filter((item) => item)
      .length;
    characters = textWithoutHTML
      .replace(/\n/g, '')
      .length;
  }
  return {
    words,
    characters,
  };
}

export function getWordsCountByBoxType(boxData) {
  switch (boxData.type) {
    case BOX_TYPES.TITLE:
    case BOX_TYPES.LEAD_TEXT:
    case BOX_TYPES.TEXT:
    case BOX_TYPES.HEADER:
    case BOX_TYPES.LIST:
    case BOX_TYPES.QUOTE:
    case BOX_TYPES.CONTAINER:
    case BOX_TYPES.AUTHOR_DESC:
      return getTextFieldWordsCount(boxData.content.text);
    case BOX_TYPES.WIDE_IMAGE:
      return getTextFieldWordsCount(boxData.content.imageCaption);
    case BOX_TYPES.YOUTUBE:
    case BOX_TYPES.VIMEO:
      return getTextFieldWordsCount(boxData.content.embedCaption);
    case BOX_TYPES.DISCLAIMER:
    case BOX_TYPES.DOCUMENTCLOUD:
    case BOX_TYPES.EMBED:
    case BOX_TYPES.TWITTER:
    case BOX_TYPES.INSTAGRAM:
    case BOX_TYPES.BUZZSPROUT:
    case BOX_TYPES.UNKNOWN:
    default:
      return {
        characters: 0,
        words: 0,
      };
  }
}

/**
 * @param {BoxData} boxData
 * @param {Array<BoxData>} prevBoxes
 * @return {Array<BoxData>}
 * */
export function insertBoxBefore(boxData, prevBoxes) {
  if (boxData.nextBoxKey) {
    return prevBoxes.reduce((result, item) => {
      if (item.boxKey === boxData.nextBoxKey) {
        result.push(boxData);
      }
      result.push(item);
      return result;
    }, []);
  }
  if (boxData.nextBoxId) {
    return prevBoxes.reduce((result, item) => {
      if (item.id === boxData.nextBoxId) {
        result.push(boxData);
      }
      result.push(item);
      return result;
    }, []);
  }
  return prevBoxes.concat([boxData]);
}

export function getSelectionRange() {
  const selection = window.getSelection();
  if (!selection || selection.type === 'None') {
    return null;
  }
  return selection
    .getRangeAt(0)
    .cloneRange();
}

export function getSelectionTextAndRange() {
  const range = getSelectionRange();
  const text = range
    ? range.toString()
    : '';
  if (text.replace(/\s/g, '').length === 0) {
    return {
      range: null,
      text: '',
    };
  }
  return {
    range,
    text,
  };
}

export function getSelectedLink(selectionRange = null) {
  const defaultLink = {
    documentId: '',
    url: '',
    text: '',
    type: LINK_TYPES.EXTERNAL,
    linkNode: null,
  };
  if (!selectionRange) {
    return defaultLink;
  }
  let text = selectionRange.toString();
  const linkNode = findLinkParent(selectionRange.commonAncestorContainer);
  if (!linkNode) {
    return {
      ...defaultLink,
      text,
    };
  }
  if (text.replace(/\s/g, '').length === 0) {
    text = linkNode.textContent;
  }
  const linkAttrs = Object
    .keys(linkNode.attributes)
    .reduce((acc, index) => {
      const attr = linkNode.attributes[index];
      acc[attr.name] = attr.value;
      return acc;
    }, {});
  return {
    // TODO: get rid off invalid attribute syntax
    documentId: linkAttrs.document_id,
    url: linkAttrs.href,
    text,
    type: linkAttrs.type,
    linkNode,
  };
}

export function getTextFromPasteEvent(e) {
  const data = e.clipboardData;
  const isRichText = data.types.includes('text/rtf');
  let text = '';
  if (!isRichText) {
    text = data.getData('text/html');
  }
  if (!text) {
    text = data.getData('text/plain');
  }
  if (!text) {
    text = data.getData('text');
  }
  return text;
}

/**
 * Makes sanitized text from Google docs great again!
 * @description: Unfortunately Google produces some garbage markup.
 */
const transformGoogleDocsTags = ({ tagName, attribs }) => {
  const { id } = attribs;
  return id && id.indexOf('docs-internal-guid') > -1
    ? {}
    : {
      tagName,
      attribs,
    };
};

const transformAllTagsPipeFunctions = pipeFunctions(
  transformGoogleDocsTags,
);

export function getSanitizedPastedText(string) {
  const options = {
    allowedTags: ALLOWED_TAGS,
    allowedAttributes: ALLOWED_ATTRS,
    allowedStyles: ALLOWED_STYLES,
    transformTags: {
      '*': (tagName, attribs) => transformAllTagsPipeFunctions({ tagName, attribs }),
    },
  };
  return sanitizeHtml(string, options)
    .trim()
    .replace(/(\n+|\s+)/g, ' ')
    .replace(cyrillicRegExp, '');
}

export function getBoxesFromPasteEvent(e) {
  const text = getTextFromPasteEvent(e);
  const sanitizedText = getSanitizedPastedText(text);
  const AST = getConvertableNodesFromString(sanitizedText);
  return getBoxesFromNodes(AST);
}

export function getHTMLFromPasteEvent(e) {
  const text = getTextFromPasteEvent(e);
  const sanitizedText = getSanitizedPastedText(text);
  const AST = getConvertableNodesFromString(sanitizedText);
  return getTextFromNodes(AST);
}

export function preventIllegalEventOfKeyPress(e) {
  const key = e.which;
  const isCyrillic = String.fromCodePoint(key).match(cyrillicRegExp);
  const isNewLine = key === KEYBOARD_KEYS.ENTER;
  if (isCyrillic) {
    e.preventDefault();
  }
  else if (isNewLine) {
    e.preventDefault();
    window.document.execCommand('insertText', false, ' ');
  }
}

export function getAncestorsTagNames(start, end = null) {
  const tagNames = [];
  let element = start;
  while (element && !element.isEqualNode(end)) {
    if (element.tagName) {
      tagNames.push(element.tagName.toLowerCase());
    }
    element = element.parentElement;
  }
  return tagNames;
}

/**
 * @param {BoxData} box
 * @param {string} [id]
 * @param {string} [boxKey]
 * @return {boolean}
 * */
export function hasBoxId(box, id, boxKey) {
  if (!box) {
    return false;
  }
  return (!!box.id && !!id && box.id === id)
    || (!!box.boxKey && !!boxKey && box.boxKey === boxKey);
}

export const wordFormatter = new window.Intl.NumberFormat().format;

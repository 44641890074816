import { ERROR_MESSAGE } from '~/app.config';

export default async function getResponseJson(rawResponse) {
  try {
    return await rawResponse.json();
  }
  catch (err) {
    console.warn(err);
    return {
      message: ERROR_MESSAGE,
    };
  }
}

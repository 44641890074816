import { createSelector } from 'reselect';
import getProperty from '~/assets/libs/getProperty';
import { isArticleUIShown } from '~/assets/helpers/documents';
import {
  getTextFieldWordsCount,
  getWordsCountByBoxType,
} from '~/assets/helpers/boxes';

const DEFAULT_THREADS = [];

export const threadsSelector = createSelector(
  (state) => getProperty(state, 'comments.threads', []),
  (state) => getProperty(state, 'comments.activeThread.threadKey', ''),
  (state) => state.document.boxes.map((item) => item.id),
  (threads, activeThreadKey, boxesIds) => {
    if (!threads.length) {
      return DEFAULT_THREADS;
    }
    return threads
      .reduce((result, item) => {
        result.push({
          ...item,
          isActive: item.threadKey === activeThreadKey,
        });
        return result;
      }, [])
      .sort((a, b) => {
        let aIndex;
        if (/\d+t/.test(a.boxId)) {
          aIndex = -3;
        }
        else if (/\d+l/.test(a.boxId)) {
          aIndex = -2;
        }
        else if (/\d+i/.test(a.boxId)) {
          aIndex = -1;
        }
        else {
          aIndex = boxesIds.indexOf(a.boxId);
        }
        let bIndex;
        if (/\d+t/.test(b.boxId)) {
          bIndex = -3;
        }
        else if (/\d+l/.test(b.boxId)) {
          bIndex = -2;
        }
        else if (/\d+i/.test(b.boxId)) {
          bIndex = -1;
        }
        else {
          bIndex = boxesIds.indexOf(b.boxId);
        }
        return aIndex - bIndex;
      });
  },
);

export const boxShownThreadSelector = createSelector(
  (state) => getProperty(state, 'comments.activeThread.threadKey', ''),
  (state) => threadsSelector(state),
  (_, props) => (props || {}).id || '',
  (activeThreadKey, threads, boxId) => {
    if (!activeThreadKey || !threads.length || !boxId) {
      return null;
    }
    return threads.find((item) => item.boxId === boxId
      && item.threadKey === activeThreadKey);
  },
);

export const activeUsersSelector = createSelector(
  (state) => state.users.list,
  (users) => users.filter(({ isActive }) => isActive),
);

export const suspendedUsersSelector = createSelector(
  (state) => state.users.list,
  (users) => users.filter(({ isActive }) => !isActive),
);

export const userSelector = createSelector(
  (state) => state.users.list,
  (_, props) => (props || {}).userId || '',
  (users, userId) => {
    if (!userId) {
      return null;
    }
    return users.find((item) => item.id === userId);
  },
);

export const permissionsSelector = createSelector(
  (state) => state.profile.permissions,
  (state) => state.profile.data.role,
  (permissions, role) => {
    if (!role || !permissions[role]) {
      return {};
    }
    return permissions[role];
  },
);

export const elementShownSelector = createSelector(
  (state) => state.profile.data.role,
  (state) => state.document.data.state,
  (userRole, documentState) => isArticleUIShown({
    userRole,
    documentState,
  }),
);

export const boxWordsCountSelector = createSelector(
  (state) => state.document.data,
  (state) => state.document.boxes,
  (_, props) => (props || {}).boxId || '',
  (data, boxes, boxId) => {
    const defaultCount = {
      characters: 0,
      words: 0,
    };
    if (!boxes.length || !boxId) {
      return defaultCount;
    }
    if (/\d+t/.test(boxId)) {
      return getTextFieldWordsCount(data.title);
    }
    if (/\d+l/.test(boxId)) {
      return getTextFieldWordsCount(data.leadText);
    }
    if (/\d+i/.test(boxId)) {
      return defaultCount;
    }
    const box = boxes.find((item) => item.id === boxId);
    if (box) {
      return getWordsCountByBoxType(box);
    }
    return defaultCount;
  },
);

export const boxFieldSelector = createSelector(
  (state) => state.document.boxFields,
  (_, props) => (props || {}).boxKey || '',
  (boxFields, boxKey) => {
    const {
      error: hasErrors = false,
      validations = null,
    } = boxFields[boxKey] || {};
    return {
      hasErrors,
      validations,
    };
  },
);

export const boxContentTextSelector = createSelector(
  (state) => state.document.boxes,
  (_, props) => (props || {}).id || '',
  (boxes, boxId) => {
    const { content } = boxes.find((item) => item.id === boxId)
      || { content: {} };
    const { text } = content || {};
    return text || '';
  },
);

export const siblingKeysSelector = createSelector(
  (state) => state.document.boxes,
  (_, props) => (props || {}).documentId || '',
  (_, props) => (props || {}).boxKey || '',
  (boxes, documentId, boxKey) => {
    return boxes.reduce((result, item, index) => {
      const isItemCurrent = item.boxKey === boxKey;
      const prevBoxData = boxes[index - 1];
      const nextBoxData = boxes[index + 1];
      if (!result[0] && isItemCurrent) {
        result[0] = prevBoxData && prevBoxData.boxKey
          ? prevBoxData.boxKey
          : `${documentId}i`;
      }
      if (!result[1] && isItemCurrent && nextBoxData && nextBoxData.boxKey) {
        result[1] = nextBoxData.boxKey;
      }
      if (!result[2] && isItemCurrent && nextBoxData && nextBoxData.id) {
        result[2] = nextBoxData.id;
      }
      return result;
    }, ['', '', '']);
  },
);

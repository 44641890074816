import * as firebase from 'firebase/app';
import 'firebase/messaging';
import uniq from 'lodash/uniq';
import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PUSH_VAPID_KEY } from '~/app.config';
import { usePrevious } from '~/assets/hooks/usePrevious';
import { mapPushNotification } from '~/assets/helpers/mappers';
import { ROUTES } from '~/assets/helpers/routes';
import {
  FIREBASE_CONFIG,
  PUSH_PERMISSIONS,
  NOTIFICATION_TYPES,
  wasFirebaseTokenAlreadySent,
  setFirebaseTokenAlreadySent,
} from '~/assets/helpers/notification';
import { setPushToken } from '~/api/users';
import { actions as notificationActions } from '~/store/reducers/notification';

let SHOWN_NOTIFICATION_IDS = [];

function getMessagingInstance() {
  let instance = null;
  try {
    if (!firebase.apps.length) {
      firebase.initializeApp(FIREBASE_CONFIG);
      instance = firebase.messaging();
      instance.usePublicVapidKey(PUSH_VAPID_KEY);
    }
    else {
      firebase.app();
      instance = firebase.messaging();
    }
    return instance;
  }
  catch (e) {
    console.warn(e);
    return instance;
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile.data,
  pushPermission: state.notification.pushNotificationPermission,
  notificationsList: state.notification.list,
});

const mapActionsToProps = {
  addNotification: (payload) => notificationActions.add(payload),
};

PushNotifications.propTypes = {
  profile: propTypes.object,
  pushPermission: propTypes.string,
  notificationsList: propTypes.array,
  addNotification: propTypes.func,
};

PushNotifications.defaultProps = {
  profile: null,
  pushPermission: PUSH_PERMISSIONS.DEFAULT,
  notificationsList: [],
  addNotification: null,
};

function PushNotifications(props) {
  const {
    profile,
    pushPermission,
    notificationsList,
    addNotification,
  } = props;
  const {
    id: userId = '',
  } = profile || {};
  const messagingRef = React.useRef(getMessagingInstance());
  const prevPermission = usePrevious(pushPermission);

  const handleTokenRefresh = React.useCallback(async () => {
    const messaging = messagingRef.current;
    if (!messaging || !userId || wasFirebaseTokenAlreadySent()) {
      return;
    }
    try {
      const token = await messaging.getToken();
      if (token) {
        await setPushToken({
          id: userId,
          token,
        });
        setFirebaseTokenAlreadySent(true);
      }
    }
    catch (e) {
      console.warn(e);
      setFirebaseTokenAlreadySent(false);
    }
  }, [
    userId,
  ]);
  const handleMessageReceive = React.useCallback((messageData) => {
    const {
      id,
      documentId,
      title,
      message,
    } = mapPushNotification(messageData);
    if (SHOWN_NOTIFICATION_IDS.includes(id)) {
      return;
    }
    SHOWN_NOTIFICATION_IDS.push(id);
    const pathname = `${ROUTES.articlesList}/${documentId}`;
    const renderContent = () => {
      return (
        <React.Fragment>
          {message
          && <p>
            {message}
          </p>}
          {documentId
          && <Link
            className="link"
            to={pathname}
          >
            Open document
          </Link>}
        </React.Fragment>
      );
    };
    addNotification({
      id,
      type: NOTIFICATION_TYPES.INFO,
      showCloseButton: true,
      autoClose: false,
      title,
      text: null,
      renderContent,
    });
  }, [
    addNotification,
  ]);

  React.useEffect(() => {
    const ids = notificationsList.map((item) => item.id);
    SHOWN_NOTIFICATION_IDS = uniq(SHOWN_NOTIFICATION_IDS.concat(ids));
  }, [
    notificationsList,
  ]);
  React.useEffect(() => {
    const messaging = messagingRef.current;
    if (messaging) {
      messaging.onTokenRefresh(handleTokenRefresh);
      messaging.onMessage(handleMessageReceive);
    }
  }, [
    handleTokenRefresh,
    handleMessageReceive,
  ]);
  React.useEffect(() => {
    const messaging = messagingRef.current;
    if (pushPermission === PUSH_PERMISSIONS.GRANTED
      && pushPermission !== prevPermission
      && messaging) {
      handleTokenRefresh();
    }
  }, [
    pushPermission,
    prevPermission,
    handleTokenRefresh,
  ]);

  return null;
}

export default connect(mapStateToProps, mapActionsToProps)(PushNotifications);

import { stringify } from 'query-string';
import { API_URL, ERROR_MESSAGE } from '~/app.config';
import getFormData from '~/assets/libs/getFormData';
import createFetch from '~/assets/libs/createFetch';
import { getAccessTokenHeader } from '~/assets/helpers/token';
import {
  getProfilePayload,
  getUserPayload,
  getUserCreatePayload,
} from '~/assets/helpers/users';
import {
  mapPermissions,
  mapInvite,
  mapInvites,
  mapUser,
  mapUsers,
} from '~/assets/helpers/mappers';

export const fetchPermissions = async () => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: mapPermissions(null),
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/permissions', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data = mapPermissions(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const setPushToken = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const fetchTransport = createFetch(API_URL);
  const { id, token } = payload;
  const query = stringify({ token }, { arrayFormat: 'bracket' });
  try {
    const raw = await fetchTransport(`/users/${id}/set_web_push_token?${query}`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const fetchProfile = async () => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: null,
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/profile', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data = mapUser(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const updateProfile = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { profileData } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/profile', {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getProfilePayload(profileData)),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const changePassword = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { passwordsData } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/profile/password', {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(passwordsData),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const resetPassword = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { passwordData: { hashCode, ...passwordData } } = payload;
  const formData = getFormData(passwordData);
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/reset_password/${hashCode}`, {
      method: 'POST',
      body: formData,
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const startShift = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const fetchTransport = createFetch(API_URL);
  const { userId } = payload;
  try {
    const raw = await fetchTransport(`/users/${userId}/set_online`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const stopShift = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const fetchTransport = createFetch(API_URL);
  const { userId } = payload;
  try {
    const raw = await fetchTransport(`/users/${userId}/set_offline`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const checkInviteHashCode = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: null,
  };
  const { hashCode } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/invites/${hashCode}`, {
      method: 'GET',
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data = mapInvite(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const checkPasswordResetHashCode = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: null,
  };
  const { hashCode } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/password_reset_tokens/${hashCode}`, {
      method: 'GET',
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapUser(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const fetchInvites = async () => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: [],
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/invites', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data = mapInvites(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const createInvite = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: {
      id: '',
    },
  };
  const { inviteData } = payload;
  const formData = getFormData(inviteData);
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/invites', {
      method: 'POST',
      headers: getAccessTokenHeader(),
      body: formData,
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data.id = raw.headers.get('X-Created-Resource-Id');
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const revokeInvite = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { inviteId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/invites/${inviteId}/revoke`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const fetchUsers = async () => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: [],
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/users', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data = mapUsers(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const fetchUser = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: mapUser(null),
  };
  const { userId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/users/${userId}`, {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data = mapUser(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const createUser = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: {
      id: '',
    },
  };
  const { userData } = payload;
  const formData = getFormData(getUserCreatePayload(userData));
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/users', {
      method: 'POST',
      body: formData,
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data.id = raw.headers.get('X-Created-Resource-Id');
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const updateUser = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { userData: { id: userId, ...userData } } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/users/${userId}`, {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getUserPayload(userData)),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const enableUser = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const fetchTransport = createFetch(API_URL);
  const { userId } = payload;
  try {
    const raw = await fetchTransport(`/users/${userId}/enable`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const disableUser = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const fetchTransport = createFetch(API_URL);
  const { userId } = payload;
  try {
    const raw = await fetchTransport(`/users/${userId}/disable`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const sendPasswordResetLink = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const fetchTransport = createFetch(API_URL);
  const { userId } = payload;
  try {
    const raw = await fetchTransport(`/users/${userId}/send_password_reset_link`, {
      method: 'POST',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

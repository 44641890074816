export class OnceState {
    /**
     * @type {boolean}
     * @private
     */
    _state = false;

    /**
     * @return {boolean}
     */
    check() {
      const state = this.get();
      this.reset();

      return state;
    }

    /**
     * @return {boolean}
     */
    get() {
      return this._state;
    }

    /**
     * @return {void}
     */
    reset() {
      this._state = false;
    }

    /**
     * @return {void}
     */
    toggleOn() {
      this._state = true;
    }
}

import React from 'react';
import { useNotificationsShow } from '~/assets/hooks/useNotificationsShow';
import { storageGet, storageSet, storageRemove } from '~/assets/libs/localStorage';
import createFetch from '~/assets/libs/createFetch';
import { RELEASE_VERSION } from '~/app.config';

const STORAGE_KEY = 'release-version';

const INTERVAL = 1000 * 60 * 5;

const APP_STATE = {
  INITIAL: 'INITIAL',
  UNKNOWN: 'UNKNOWN',
  FRESH: 'FRESH',
  STALE: 'STALE',
};

// TODO: rollback usecase?
export function useReleaseVersion() {
  const { showNotification } = useNotificationsShow();
  const [appState, setAppState] = React.useState(APP_STATE.INITIAL);
  const intervalRef = React.useRef(-1);
  const notificationShownRef = React.useRef(false);

  const showStaleNotification = React.useCallback(() => {
    if (notificationShownRef.current) {
      return;
    }
    const version = storageGet(STORAGE_KEY)
      .replace(/^\D+/, '');
    showNotification({
      type: 'warning',
      showCloseButton: false,
      autoClose: false,
      title: `New version is available (v${version})`,
      text: 'Please, reload the page to avoid app crushing and weird bugs',
    });
    notificationShownRef.current = true;
  }, [
    showNotification,
  ]);
  const storeReleaseVersion = React.useCallback(() => {
    setAppState(APP_STATE.UNKNOWN);
    const versionNumber = getVersionNumber(storageGet(STORAGE_KEY));
    if (versionNumber === 0) {
      storageRemove(STORAGE_KEY);
    }
    const stale = isAppStale();
    if (stale) {
      setAppState(APP_STATE.STALE);
      showStaleNotification();
    }
    else {
      storageSet(STORAGE_KEY, RELEASE_VERSION);
    }
  }, [
    showStaleNotification,
  ]);
  const handleManifestCheck = React.useCallback(async () => {
    const nextReleaseVersion = await getReleaseVersionFromManifest();
    storageSet(STORAGE_KEY, nextReleaseVersion);
    const stale = isAppStale();
    if (stale) {
      setAppState(APP_STATE.STALE);
      showStaleNotification();
    }
  }, [
    showStaleNotification,
  ]);
  const handleIntervalStart = React.useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(handleManifestCheck, INTERVAL);
  }, [
    handleManifestCheck,
  ]);
  const handleVisibilityChange = React.useCallback(() => {
    if (window.document.visibilityState === 'hidden') {
      clearInterval(intervalRef.current);
      return;
    }
    const stale = isAppStale();
    if (stale) {
      setAppState(APP_STATE.STALE);
      showStaleNotification();
    }
    else {
      setAppState(APP_STATE.FRESH);
    }
    handleManifestCheck();
    handleIntervalStart();
  }, [
    showStaleNotification,
    handleManifestCheck,
    handleIntervalStart,
  ]);

  React.useEffect(() => {
    if (appState === APP_STATE.INITIAL) {
      storeReleaseVersion();
      handleIntervalStart();
    }
  }, [
    appState,
    storeReleaseVersion,
    handleIntervalStart,
  ]);
  React.useEffect(() => {
    window.document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    };
  }, [
    handleVisibilityChange,
  ]);
  React.useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
}

function isAppStale() {
  const storedReleaseVersion = storageGet(STORAGE_KEY);
  const current = getVersionNumber(RELEASE_VERSION);
  const stored = getVersionNumber(storedReleaseVersion);
  return current - stored < 0;
}

function getVersionNumber(version) {
  if (!version || typeof version !== 'string') {
    return 0;
  }
  const string = version.replace(/\D+/, '');
  if (!string) {
    return 0;
  }
  const number = string
    .split('.')
    .map((value, index) => {
      if ((!value || +value === 0) && index === 0) {
        return 1;
      }
      return +value;
    })
    .reduce((result, value, index, { length }) => {
      return result + (value * 1000 ** (length - index - 1));
    }, 0);
  return Number.isNaN(number)
    ? 0
    : number;
}

async function getReleaseVersionFromManifest() {
  try {
    const fetchTransport = createFetch();
    const raw = await fetchTransport('/manifest.json', {
      method: 'GET',
      cache: 'no-store',
    });
    const manifest = await raw.json();
    const versionNumber = getVersionNumber(manifest.releaseVersion);
    if (versionNumber === 0) {
      return RELEASE_VERSION;
    }
    return manifest.releaseVersion;
  }
  catch (err) {
    return RELEASE_VERSION;
  }
}

/* eslint-disable */
import throttle from 'lodash/throttle';
import jsondiff from 'json0-ot-diff';
import cloneDepp from 'lodash/cloneDeep';
import promiseReduce from 'promise-reduce';
import { type as otJson } from 'ot-json0';
import { getModel } from '~/assets/libs/getModel';
import { getDeviceViewportData } from '~/assets/helpers/viewport';
import { USER_ROLES } from '~/assets/helpers/users';
import {
  DOCUMENT_STATES,
  DOCUMENT_READY_STATE,
  DOCUMENT_TABS,
  getDocumentFieldsValidators,
  getFieldsWarningMessages,
} from '~/assets/helpers/documents';
import {
  BOX_TYPES,
  mergeBoxesData,
  insertBoxBefore,
  getBoxFieldsValidators,
  validateBoxFields, validateAdSpace,
} from '~/assets/helpers/boxes';
import {
  EMPTY_DOCUMENT,
  mapBoxesIds,
  mapBoxes,
  mapBox,
  mapDocumentUsers,
} from '~/assets/helpers/mappers';
import {
  fetchDocument,
  createDocument,
  fetchBoxTemplates,
  fetchAuthors,
  fetchCategories,
  fetchLabels,
  assignEditor,
  assignAuthor,
  assignProofreader,
  finishProofreading,
  publishDocument,
  unpublishDocument,
  rePublishDocument,
  updateDocumentState,
  updateDocument,
  createBox,
  updateBox,
  deleteBox,
  reorderBoxes,
  createImageUsage,
  createBylineAuthor,
} from '~/api/documents';
import collabSocket from '~/api/collab';
import { DOCUMENT } from '~/store/reducers/types';
import { actions as notificationActions, showError } from '~/store/reducers/notification';
import { actions as usersActions } from '~/store/reducers/users';
import { actions as commentsActions } from '~/store/reducers/comments';
// eslint-disable-next-line import/no-cycle
import { getBoxManager } from '~/assets/libs/BoxManager';

const emptyDocumentModelInput = getDocumentFieldsValidators(EMPTY_DOCUMENT);
const emptyBoxModelInput = getBoxFieldsValidators(EMPTY_DOCUMENT.state, []);
const documentModel = getModel();
const boxModel = getModel();
documentModel.set(emptyDocumentModelInput);
boxModel.set(emptyBoxModelInput);

export const initialState = {
  readyState: DOCUMENT_READY_STATE.INITIAL,
  socket: null,
  tab: DOCUMENT_TABS.ARTICLE,
  selectionRange: null,
  changedWhenPublished: false,
  boxes: [],
  data: EMPTY_DOCUMENT,
  fields: documentModel.get(),
  sockedFields: documentModel.get(),
  boxFields: boxModel.get(),
  boxNeedsFocus: '',
  boxesUploading: false,
  boxTemplates: [],
  bylineAuthors: [],
  categories: [],
  labels: [],
  workingUsers: [],
  hasAdSpace: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT.MUTATE_READY_STATE:
      return {
        ...state,
        readyState: action.payload,
      };
    case DOCUMENT.MUTATE_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };
    case DOCUMENT.MUTATE_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case DOCUMENT.MUTATE_SELECTION_RANGE: {
      return {
        ...state,
        selectionRange: action.payload,
      };
    }
    case DOCUMENT.MUTATE_CHANGED_WHEN_PUBLISHED: {
      return {
        ...state,
        changedWhenPublished: action.payload,
      };
    }
    case DOCUMENT.MUTATE_DOCUMENT: {
      const nextData = action.payload;
      return {
        ...state,
        data: nextData,
      };
    }
    case DOCUMENT.UPDATE_DOCUMENT_STATE: {
      const nextData = {
        ...state.data,
        state: action.payload,
      };
      return {
        ...state,
        data: nextData,
      };
    }
    case DOCUMENT.UPDATE_DOCUMENT: {
      const prevDocumentState = state.data.state;

      const nextData = {
        ...action.payload,
        isScheduled: prevDocumentState === DOCUMENT_STATES.SCHEDULED,
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...nextData,
        },
      };
    }
    case DOCUMENT.MUTATE_WORDS_COUNT: {
      const { words, characters } = state.boxes.reduce((result, item) => {
        result.words += item.words;
        result.characters += item.characters;
        return result;
      }, { words: 0, characters: 0 });

      return {
        ...state,
        data: {
          ...state.data,
          words,
          characters,
        },
      };
    }
    case DOCUMENT.ASSIGN_EDITOR: {
      const nextData = {
        editorId: action.payload,
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...nextData,
        },
      };
    }
    case DOCUMENT.ASSIGN_AUTHOR: {
      const nextData = {
        authorId: action.payload,
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...nextData,
        },
      };
    }
    case DOCUMENT.ASSIGN_PROOFREADER: {
      const nextData = {
        proofreaderId: action.payload,
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...nextData,
        },
      };
    }
    case DOCUMENT.FINISH_PROOFREADING: {
      const nextData = {
        needsProofReading: false,
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...nextData,
        },
      };
    }
    case DOCUMENT.PUBLISH_DOCUMENT: {
      const {
        isProofReader,
        needPublishDateUpdate,
        publishLater,
      } = action.payload;
      const nextDocumentState = publishLater
        ? DOCUMENT_STATES.SCHEDULED
        : DOCUMENT_STATES.PUBLISHED;
      let isScheduled = state.data.isScheduled;
      let needsProofReading = state.data.needsProofReading;
      let datePublishedAt = state.data.datePublishedAt;
      if (isProofReader) {
        needsProofReading = false;
      }
      if (!datePublishedAt || needPublishDateUpdate) {
        datePublishedAt = Math.round(Date.now() / 1000).toString();
      }
      if (needPublishDateUpdate && !publishLater) {
        isScheduled = false;
      }
      if (publishLater) {
        isScheduled = true;
      }
      const nextData = {
        state: nextDocumentState,
        isScheduled,
        needsProofReading,
        datePublishedAt,
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...nextData,
        },
      };
    }
    case DOCUMENT.UNPUBLISH_DOCUMENT: {
      const nextData = {
        state: DOCUMENT_STATES.UNPUBLISHED,
        isScheduled: false,
        datePublishedAt: '',
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...nextData,
        },
      };
    }
    case DOCUMENT.MUTATE_BOXES: {
      const nextBoxes = action.payload;
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.MUTATE_FIELDS: {
      const nextFields = action.payload;
      return {
        ...state,
        fields: nextFields,
      };
    }
    case DOCUMENT.MUTATE_BOX_FIELDS: {
      const nextBoxFields = action.payload;
      return {
        ...state,
        boxFields: nextBoxFields,
      };
    }
    case DOCUMENT.MUTATE_BOX_NEEDS_FOCUS: {
      return {
        ...state,
        boxNeedsFocus: action.payload,
      };
    }
    case DOCUMENT.MUTATE_BOX_TEMPLATES:
      return {
        ...state,
        boxTemplates: action.payload,
      };
    case DOCUMENT.MUTATE_BYLINE_AUTHORS:
      return {
        ...state,
        bylineAuthors: action.payload,
      };
    case DOCUMENT.CREATE_BYLINE_AUTHOR:
      return {
        ...state,
        bylineAuthors: state.bylineAuthors.concat([action.payload]),
      };
    case DOCUMENT.MUTATE_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case DOCUMENT.MUTATE_LABELS:
      return {
        ...state,
        labels: action.payload,
      };
    case DOCUMENT.MUTATE_WORKING_USERS:
      return {
        ...state,
        workingUsers: action.payload,
      };
    case DOCUMENT.CREATE_OPTIMISTIC_BOX: {
      const nextBoxes = insertBoxBefore(action.payload, state.boxes);
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.REPLACE_OPTIMISTIC_BOX: {
      const nextBoxes = state.boxes.map((item) => {
        if (item.boxKey === action.payload.boxKey) {
          return mergeBoxesData(item, action.payload);
        }
        return item;
      });
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.DELETE_OPTIMISTIC_BOX: {
      const nextBoxes = state.boxes.filter((item) => item.boxKey !== action.payload.boxKey);
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.CREATE_BOX: {
      const alreadyExist = state.boxes.find((item) => item.id === action.payload.id);
      if (alreadyExist) {
        return state;
      }
      const nextBoxes = insertBoxBefore(action.payload, state.boxes);
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.UPDATE_BOX: {
      const itemId = action.payload.id;
      const itemKey = action.payload.boxKey;
      const nextBoxes = state.boxes.map((item) => {
        const isItemCurrent = (itemId && item.id === itemId)
          || (itemKey && item.boxKey === itemKey);
        if (isItemCurrent) {
          return mergeBoxesData(item, action.payload);
        }
        return item;
      });
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.DELETE_BOX: {
      const nextBoxes = state.boxes.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.UNLOCK_BOX: {
      const nextBoxes = state.boxes.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            lockedByUser: '',
          };
        }
        return item;
      });
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.LOCK_BOX: {
      const nextBoxes = state.boxes.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            lockedByUser: action.payload.userId,
          };
        }
        return item;
      });
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.REORDER_BOXES: {
      const boxesOrder = action.payload;
      const nextBoxes = state.boxes.slice().sort((a, b) => {
        const aIndex = boxesOrder.indexOf(a.id);
        const bIndex = boxesOrder.indexOf(b.id);
        return aIndex - bIndex;
      });
      return {
        ...state,
        boxes: nextBoxes,
      };
    }
    case DOCUMENT.CREATE_BOXES: {
      return {
        ...state,
        boxesUploading: action.payload === DOCUMENT_READY_STATE.BOXES_CREATING,
      };
    }
    case DOCUMENT.MUTATE_AD_SPACE: {
      const hasAdSpace = !validateAdSpace(state.boxes);
      return {
        ...state,
        hasAdSpace,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  prepare: (documentId, blocking = false) => (dispatch, getState) => {
    const { isMobile } = getDeviceViewportData();
    const defaultTab = isMobile
      ? DOCUMENT_TABS.ARTICLE
      : DOCUMENT_TABS.METADATA;
    dispatch({
      type: DOCUMENT.UPDATE_DOCUMENT,
      payload: {
        id: documentId,
      },
    });
    actions.closeSocket()(dispatch, getState);
    usersActions.fetch()(dispatch, getState);
    actions.setTab(defaultTab)(dispatch, getState);
    if (!blocking) {
      actions.fetchDocument()(dispatch, getState);
    }

    if (blocking) {
      dispatch({
        type: DOCUMENT.CREATE_BOXES,
        payload: DOCUMENT_READY_STATE.BOXES_CREATING,
      });
    }

    actions.fetchBoxTemplates()(dispatch, getState);
    actions.fetchBylineAuthors()(dispatch, getState);
    actions.fetchCategories()(dispatch, getState);
    actions.fetchLabels()(dispatch, getState);
    commentsActions.fetchThreads(documentId)(dispatch, getState);

    if (blocking) {
      dispatch({
        type: DOCUMENT.MUTATE_READY_STATE,
        payload: DOCUMENT_READY_STATE.AUTO_SAVED,
      });

      dispatch({
        type: DOCUMENT.CREATE_BOXES,
        payload: DOCUMENT_READY_STATE.BOXES_CREATED,
      });
    }
  },
  clear: () => (dispatch, getState) => {
    documentModel.set(emptyDocumentModelInput);
    boxModel.set(emptyBoxModelInput);
    actions.closeSocket()(dispatch, getState);
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.INITIAL,
    });
    dispatch({
      type: DOCUMENT.MUTATE_DOCUMENT,
      payload: EMPTY_DOCUMENT,
    });
    dispatch({
      type: DOCUMENT.MUTATE_BOXES,
      payload: [],
    });
    dispatch({
      type: DOCUMENT.MUTATE_FIELDS,
      payload: documentModel.get(),
    });
    dispatch({
      type: DOCUMENT.MUTATE_BOX_FIELDS,
      payload: boxModel.get(),
    });
    dispatch({
      type: DOCUMENT.MUTATE_TAB,
      payload: DOCUMENT_TABS.METADATA,
    });
    dispatch({
      type: DOCUMENT.MUTATE_WORKING_USERS,
      payload: [],
    });
    commentsActions.clear()(dispatch, getState);
  },
  change: (name, value) => (dispatch, getState) => {
    const prevState = getState();
    documentModel.updateValues({
      [name]: { value },
    });
    dispatch({
      type: DOCUMENT.UPDATE_DOCUMENT,
      payload: {
        [name]: value,
      },
    });
    dispatch({
      type: DOCUMENT.MUTATE_FIELDS,
      payload: documentModel.get(),
    });
    actions.autoSave(dispatch, getState);
    actions.setChangedWhenPublished(true)(dispatch, getState);
    const { document: { socket, data } } = getState();
    const diff = jsondiff(prevState.document.data, data);
    if (socket?.documentUpdate) {
      socket.documentUpdate(diff);
    }
  },
  autoSave: throttle(async (dispatch, getState) => {
    const { document: { data: { id: documentId } } } = getState();
    const updateData = documentModel.getChangedValues();
    if (!documentId || !Object.keys(updateData).length) {
      return;
    }
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVING,
    });
    const response = await updateDocument({
      documentId,
      updateData,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: DOCUMENT.MUTATE_READY_STATE,
        payload: DOCUMENT_READY_STATE.READY,
      });
    }
    else {
      actions.setDocumentModelValues(updateData)(dispatch, getState);
      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });
      dispatch({
        type: DOCUMENT.MUTATE_READY_STATE,
        payload: DOCUMENT_READY_STATE.AUTO_SAVED,
      });
    }
  }, 1000, { leading: false, trailing: true }),
  validateData: () => (dispatch) => {
    documentModel.touch();
    dispatch({
      type: DOCUMENT.MUTATE_FIELDS,
      payload: documentModel.get(),
    });
  },
  validateBoxes: () => (dispatch) => {
    boxModel.touch();
    dispatch({
      type: DOCUMENT.MUTATE_BOX_FIELDS,
      payload: boxModel.get(),
    });
  },
  validateBeforePreview: () => (dispatch, getState) => {
    actions.validateData()(dispatch, getState);
    actions.validateBoxes()(dispatch, getState);
    actions.checkAdSpace()(dispatch, getState);
    const { document: { fields, boxFields } } = getState();
    const boxesValidation = validateBoxFields(boxFields);
    getFieldsWarningMessages({
      title: fields.title.invalid,
      leadText: fields.leadText.invalid,
      authors: fields.authors.invalid,
      editorId: fields.editorId.invalid,
      superTags: fields.superTags.invalid,
      tags: fields.tags.invalid,
      socialLeadTexts: fields.facebookLeadText.invalid
        || fields.twitterLeadText.invalid
        || fields.telegramLeadText.invalid,
      categoryId: fields.categoryId.invalid,
      labelId: fields.labelId.invalid,
      noBoxes: boxesValidation.noBoxes,
      emptyTextBoxes: boxesValidation.emptyTextBoxes,
      emptyImageBoxes: boxesValidation.emptyImageBoxes,
      coverId: fields.coverId.invalid,
    }).forEach((message) => notificationActions.showWarning(message)(dispatch, getState));
  },
  validateBeforeTextFinish: () => (dispatch, getState) => {
    actions.validateData()(dispatch, getState);
    actions.validateBoxes()(dispatch, getState);
    actions.checkAdSpace()(dispatch, getState);
    const { document: { fields, boxFields } } = getState();
    const allNonBreakingSpaceSelected = validateAdSpace(boxFields);
    const boxesValidation = validateBoxFields(boxFields);
    getFieldsWarningMessages({
      title: fields.title.invalid,
      leadText: fields.leadText.invalid,
      authors: fields.authors.invalid,
      editorId: fields.editorId.invalid,
      superTags: fields.superTags.invalid,
      tags: fields.tags.invalid,
      socialLeadTexts: fields.facebookLeadText.invalid
        || fields.twitterLeadText.invalid
        || fields.telegramLeadText.invalid,
      categoryId: fields.categoryId.invalid,
      labelId: fields.labelId.invalid,
      noBoxes: boxesValidation.noBoxes,
      emptyTextBoxes: boxesValidation.emptyTextBoxes,
      allNonBreakingSpaceSelected,
    }).forEach((message) => notificationActions.showWarning(message)(dispatch, getState));
  },
  validateBeforeArtWorkFinish: () => (dispatch, getState) => {
    actions.validateData()(dispatch, getState);
    actions.validateBoxes()(dispatch, getState);
    actions.checkAdSpace()(dispatch, getState);
    const { document: { fields, boxFields } } = getState();
    const boxesValidation = validateBoxFields(boxFields);
    getFieldsWarningMessages({
      title: fields.title.invalid,
      leadText: fields.leadText.invalid,
      authors: fields.authors.invalid,
      editorId: fields.editorId.invalid,
      superTags: fields.superTags.invalid,
      tags: fields.tags.invalid,
      socialLeadTexts: fields.facebookLeadText.invalid
        || fields.twitterLeadText.invalid
        || fields.telegramLeadText.invalid,
      categoryId: fields.categoryId.invalid,
      labelId: fields.labelId.invalid,
      noBoxes: boxesValidation.noBoxes,
      emptyTextBoxes: boxesValidation.emptyTextBoxes,
      emptyImageBoxes: boxesValidation.emptyImageBoxes,
      coverId: fields.coverId.invalid,
    }).forEach((message) => notificationActions.showWarning(message)(dispatch, getState));
  },
  validateBeforePublish: () => (dispatch, getState) => {
    actions.validateData()(dispatch, getState);
    actions.validateBoxes()(dispatch, getState);
    actions.checkAdSpace()(dispatch, getState);
    const { document: { fields, boxFields } } = getState();
    const boxesValidation = validateBoxFields(boxFields);
    getFieldsWarningMessages({
      title: fields.title.invalid,
      leadText: fields.leadText.invalid,
      authors: fields.authors.invalid,
      editorId: fields.editorId.invalid,
      superTags: fields.superTags.invalid,
      tags: fields.tags.invalid,
      socialLeadTexts: fields.facebookLeadText.invalid
        || fields.twitterLeadText.invalid
        || fields.telegramLeadText.invalid,
      categoryId: fields.categoryId.invalid,
      labelId: fields.labelId.invalid,
      noBoxes: boxesValidation.noBoxes,
      emptyTextBoxes: boxesValidation.emptyTextBoxes,
      emptyImageBoxes: boxesValidation.emptyImageBoxes,
      coverId: fields.coverId.invalid,
      datePublishedAt: fields.datePublishedAt.invalid,
    }).forEach((message) => notificationActions.showWarning(message)(dispatch, getState));
  },
  updateDocumentAndBoxModels: () => (dispatch, getState) => {
    const { document: { data, boxes } } = getState();
    const documentState = data.state;
    const documentModelInput = getDocumentFieldsValidators(data);
    const boxModelInput = getBoxFieldsValidators(documentState, boxes);
    documentModel.set(documentModelInput);
    boxModel.set(boxModelInput);
    dispatch({
      type: DOCUMENT.MUTATE_FIELDS,
      payload: documentModel.get(),
    });
    dispatch({
      type: DOCUMENT.MUTATE_BOX_FIELDS,
      payload: boxModel.get(),
    });
  },
  updateDocumentModels: () => (dispatch, getState) => {
    const { document: { data } } = getState();
    const documentModelInput = getDocumentFieldsValidators(data);
    documentModel.set(documentModelInput);
    dispatch({
      type: DOCUMENT.MUTATE_FIELDS,
      payload: documentModel.get(),
    });
  },
  updateBoxModel: () => (dispatch, getState) => {
    const { document: { data: { state: documentState }, boxes } } = getState();
    const boxModelInput = getBoxFieldsValidators(documentState, boxes);
    boxModel.set(boxModelInput);
    dispatch({
      type: DOCUMENT.MUTATE_BOX_FIELDS,
      payload: boxModel.get(),
    });
  },
  setDocumentModelValues: (updateData) => (dispatch) => {
    const documentModelInput = Object
      .entries(updateData)
      .reduce((result, [key, value]) => {
        result[key] = { value };
        return result;
      }, {});
    documentModel.setValues(documentModelInput);
    dispatch({
      type: DOCUMENT.MUTATE_FIELDS,
      payload: documentModel.get(),
    });
  },
  setTab: (tabKey) => (dispatch, getState) => {
    const {
      document: {
        tab: prevTabKey,
        data: {
          documentId,
        },
      },
    } = getState();
    const areCommentsShown = tabKey === DOCUMENT_TABS.COMMENTS;
    const prevAreCommentsShown = prevTabKey === DOCUMENT_TABS.COMMENTS;
    if (areCommentsShown && areCommentsShown !== prevAreCommentsShown && documentId) {
      commentsActions.fetchThreads(documentId)(dispatch, getState);
    }
    dispatch({
      type: DOCUMENT.MUTATE_TAB,
      payload: tabKey,
    });
  },
  setSelectionRange: (range) => (dispatch) => {
    dispatch({
      type: DOCUMENT.MUTATE_SELECTION_RANGE,
      payload: range,
    });
  },
  setChangedWhenPublished: (changed) => (dispatch, getState) => {
    const { document: { data: { state: documentState } } } = getState();
    dispatch({
      type: DOCUMENT.MUTATE_CHANGED_WHEN_PUBLISHED,
      payload: changed
        && [DOCUMENT_STATES.PUBLISHED, DOCUMENT_STATES.SCHEDULED].includes(documentState),
    });
  },
  createDocument: (documentData) => async (dispatch) => {
    const response = await createDocument({
      documentData,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    return response;
  },
  fetchDocument: () => async (dispatch, getState) => {
    const { document: { data: { id: documentId } } } = getState();
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.FETCHING_DATA,
    });
    const response = await fetchDocument({
      documentId,
    });
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    if (data.id) {
      dispatch({
        type: DOCUMENT.MUTATE_DOCUMENT,
        payload: data,
      });
      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });
      actions.updateDocumentModels()(dispatch, getState);
      actions.openSocket()(dispatch, getState);
    }
    else {
      dispatch({
        type: DOCUMENT.MUTATE_READY_STATE,
        payload: DOCUMENT_READY_STATE.NOT_FOUND,
      });
    }
    return response;
  },
  publishDocument: (documentId, publishData) => async (dispatch, getState) => {
    const { profile: { data: { role } } } = getState();
    const isProofReader = role === USER_ROLES.PROOF_READER;
    const response = await publishDocument({
      documentId,
      publishLater: publishData.publishLater,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: DOCUMENT.PUBLISH_DOCUMENT,
        payload: {
          isProofReader,
          needPublishDateUpdate: publishData.needPublishDateUpdate,
          publishLater: publishData.publishLater,
        },
      });
      actions.updateDocumentAndBoxModels()(dispatch, getState);
    }
    return response;
  },
  rePublishDocument: (documentId, rePublishData) => async (dispatch, getState) => {
    const response = await rePublishDocument({
      documentId,
      rePublishData,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: DOCUMENT.PUBLISH_DOCUMENT,
        payload: {
          needPublishDateUpdate: rePublishData.needPublishDateUpdate,
          publishLater: rePublishData.publishLater,
        },
      });
      actions.setChangedWhenPublished(false)(dispatch, getState);
      actions.updateDocumentAndBoxModels()(dispatch, getState);
    }
    return response;
  },
  unpublishDocument: (documentId) => async (dispatch, getState) => {
    const response = await unpublishDocument({
      documentId,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: DOCUMENT.UNPUBLISH_DOCUMENT,
      });
      actions.updateDocumentAndBoxModels()(dispatch, getState);
    }
    return response;
  },
  updateDocumentState: (documentId, documentState) => async (dispatch, getState) => {
    const response = await updateDocumentState({
      documentId,
      documentState,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: DOCUMENT.UPDATE_DOCUMENT_STATE,
        payload: documentState,
      });
      actions.setChangedWhenPublished(false)(dispatch, getState);
      actions.updateDocumentAndBoxModels()(dispatch, getState);
    }
    return response;
  },
  fetchBylineAuthors: () => async (dispatch) => {
    const response = await fetchAuthors();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    dispatch({
      type: DOCUMENT.MUTATE_BYLINE_AUTHORS,
      payload: data,
    });
    return response;
  },
  createBylineAuthor: (authorData) => async (dispatch) => {
    const response = await createBylineAuthor({
      authorData,
    });
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    if (data.id) {
      dispatch({
        type: DOCUMENT.CREATE_BYLINE_AUTHOR,
        payload: data,
      });
    }
    return response;
  },
  fetchCategories: () => async (dispatch) => {
    const response = await fetchCategories();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    dispatch({
      type: DOCUMENT.MUTATE_CATEGORIES,
      payload: data,
    });
    return response;
  },
  fetchLabels: () => async (dispatch) => {
    const response = await fetchLabels();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    dispatch({
      type: DOCUMENT.MUTATE_LABELS,
      payload: data,
    });
    return response;
  },
  assignEditor: (documentId, editorId) => async (dispatch, getState) => {
    const response = await assignEditor({
      documentId,
      editorId,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      actions.change('editorId', editorId)(dispatch, getState);
      dispatch({
        type: DOCUMENT.ASSIGN_EDITOR,
        payload: editorId,
      });
    }
    return response;
  },
  assignAuthor: (documentId, authorId) => async (dispatch, getState) => {
    const response = await assignAuthor({
      documentId,
      authorId,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      actions.change('authorId', authorId)(dispatch, getState);
      dispatch({
        type: DOCUMENT.ASSIGN_AUTHOR,
        payload: authorId,
      });
    }
    return response;
  },
  assignProofreader: (documentId, proofreaderId) => async (dispatch, getState) => {
    const response = await assignProofreader({
      documentId,
      proofreaderId,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      actions.change('proofreaderId', proofreaderId)(dispatch, getState);
      dispatch({
        type: DOCUMENT.ASSIGN_PROOFREADER,
        payload: proofreaderId,
      });
    }
    return response;
  },
  finishProofreading: () => async (dispatch, getState) => {
    const { document: { data: { id: documentId } } } = getState();
    const response = await finishProofreading({
      documentId,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: DOCUMENT.FINISH_PROOFREADING,
      });
      dispatch({
        type: DOCUMENT.MUTATE_FIELDS,
        payload: documentModel.get(),
      });
    }
    return response;
  },
  openSocket: () => (dispatch, getState) => {
    const {
      profile: {
        data: {
          id: userId,
        },
      },
      document: {
        data: {
          id: documentId,
        },
        socket: prevSocket,
      },
    } = getState();

    if (prevSocket) return;

    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.FETCHING_BOXES,
    });

    const socket = collabSocket(documentId, userId);

    dispatch({
      type: DOCUMENT.MUTATE_SOCKET,
      payload: socket,
    });

    socket.onError((error) => {
      showError({ errors: error }, dispatch);
    });

    socket.onDocumentUsers((users) => {
      dispatch({
        type: DOCUMENT.MUTATE_WORKING_USERS,
        payload: mapDocumentUsers(users),
      });
    });

    socket.onDocumentUpdated((diff) => {
      const { document: { data } } = getState();

      if (diff.length) {
        const nextData = cloneDepp(data);
        otJson.apply(nextData, diff);
        dispatch({
          type: DOCUMENT.MUTATE_DOCUMENT,
          payload: nextData,
        });
        actions.updateDocumentModels()(dispatch, getState);
      }
    });

    socket.onDocumentBoxOnce((boxes) => {
      dispatch({
        type: DOCUMENT.MUTATE_BOXES,
        payload: mapBoxes(boxes),
      });

      dispatch({
        type: DOCUMENT.MUTATE_BOX_FIELDS,
        payload: boxModel.get(),
      });

      dispatch({
        type: DOCUMENT.MUTATE_READY_STATE,
        payload: DOCUMENT_READY_STATE.READY,
      });

      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });

      actions.updateBoxModel()(dispatch, getState);

      getBoxManager().resetAll(boxes);
    });

    socket.onBoxesReorder((boxesIds) => {
      const nextBoxesOrder = mapBoxesIds(boxesIds);
      dispatch({
        type: DOCUMENT.REORDER_BOXES,
        payload: nextBoxesOrder,
      });
    });

    socket.onBoxCreated((boxData, boxesIds) => {
      const currentSocketId = socket.socket.id;
      const { socketId } = boxData;
      const mappedBox = mapBox(boxData);

      if (getBoxManager().getSocketActionsState()) return;

      if (currentSocketId !== socketId) {
        // box created by another user
        dispatch({
          type: DOCUMENT.CREATE_BOX,
          payload: mappedBox,
        });

        dispatch({
          type: DOCUMENT.REORDER_BOXES,
          payload: boxesIds,
        });

        dispatch({
          type: DOCUMENT.MUTATE_WORDS_COUNT,
        });

        actions.updateBoxModel()(dispatch, getState);

        /**
         * initialize state history
         * for newly created box
         */
        if (socketId) getBoxManager().resetBox(mappedBox);
      }
    });
    socket.onBoxUpdated((boxData) => {
      const currentSocketId = socket.socket.id;
      const { socketId } = boxData;
      const mappedBox = mapBox(boxData);

      if (getBoxManager().getViewModel(mappedBox)?.checkIgnoreSocketUpdate()) return;
      if (getBoxManager().getSocketActionsState()) return;

      // box updated by another user
      if (currentSocketId !== socketId) {
        dispatch({
          type: DOCUMENT.UPDATE_BOX,
          payload: mappedBox,
        });

        actions.updateBoxModel()(dispatch, getState);

        if (socketId) getBoxManager().resetBox(mappedBox);
      }

      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });
    });
    socket.onBoxDeleted((boxData) => {
      const mappedBox = mapBox(boxData);

      dispatch({
        type: DOCUMENT.DELETE_BOX,
        payload: mappedBox,
      });

      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });

      actions.updateBoxModel()(dispatch, getState);

      getBoxManager().forgetBox(mappedBox);
    });
    socket.onBoxUnlocked((boxData) => {
      const mappedBox = mapBox(boxData);

      dispatch({
        type: DOCUMENT.UNLOCK_BOX,
        payload: mappedBox,
      });
    });
  },
  closeSocket: () => (dispatch, getState) => {
    const { document: { socket } } = getState();
    if (!socket) {
      return;
    }
    socket.close();
    dispatch({
      type: DOCUMENT.MUTATE_SOCKET,
      payload: null,
    });
  },
  setBoxNeedsFocus: (boxKey) => (dispatch) => {
    dispatch({
      type: DOCUMENT.MUTATE_BOX_NEEDS_FOCUS,
      payload: boxKey,
    });
  },
  startToCreateBoxes: () => (dispatch) => {
    dispatch({
      type: DOCUMENT.CREATE_BOXES,
      payload: DOCUMENT_READY_STATE.BOXES_CREATING,
    });
  },
  createBoxes: (insertBeforeBoxId, boxesData) => async (dispatch, getState) => {
    const { document: { data: { id: documentId } } } = getState();
    const failedBoxDatas = [];

    dispatch({
      type: DOCUMENT.CREATE_BOXES,
      payload: DOCUMENT_READY_STATE.BOXES_CREATING,
    });

    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVING,
    });

    // close socket to prevent conflicts
    actions.closeSocket()(dispatch, getState);

    const boxesToCreate = boxesData.slice().reverse();

    await promiseReduce(async (nextBoxId, boxData, index) => {
      let currentBoxId = nextBoxId;
      const response = await createBox({
        boxData: {
          ...boxData,
          documentId,
          nextBoxId,
        },
      });
      const { status, data: { id } } = response;
      if (status === 'error') {
        failedBoxDatas.push({ boxData, response });
      }
      else {
        if (nextBoxId) {
          boxData.nextBoxId = nextBoxId;
        }

        dispatch({
          type: DOCUMENT.CREATE_OPTIMISTIC_BOX,
          payload: {
            ...boxData,
            isCreating: true,
          },
        });

        getBoxManager().resetBox(boxData);

        dispatch({
          type: DOCUMENT.REPLACE_OPTIMISTIC_BOX,
          payload: {
            ...boxData,
            id,
            nextBoxKey: undefined,
            nextBoxId,
          },
        });
        currentBoxId = id;

        if (index === boxesData.length - 1) {
          actions.setBoxNeedsFocus(boxData.boxKey)(dispatch, getState);
        }
      }
      return currentBoxId;
    }, insertBeforeBoxId)(boxesToCreate);

    if (failedBoxDatas.length > 0) {
      const error = new Error('Failed to create some boxes in batch');
      Object.assign(error, { failedBoxDatas });

      Promise.resolve().then(() => {
        console.log('boxes:', failedBoxDatas);
        throw error;
      });
    }

    await actions.prepare(documentId, true)(dispatch, getState);
    actions.setChangedWhenPublished(true)(dispatch, getState);
  },
  createBox: (boxData) => async (dispatch, getState) => {
    const { document: { data: { id: documentId } } } = getState();
    const isNecessaryToOptimisticBox = boxData.type !== 'text';

    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVING,
    });

    if (isNecessaryToOptimisticBox) {
      dispatch({
        type: DOCUMENT.CREATE_OPTIMISTIC_BOX,
        payload: {
          ...boxData,
          isCreating: true,
        },
      });

      getBoxManager().resetBox(boxData);
    }

    // Stop to listening socket for excluding duplicate actions about new box
    getBoxManager().stopSocketActions();

    const response = await createBox({
      boxData: {
        ...boxData,
        documentId,
      },
    });

    getBoxManager().resumeSocketActions();

    const { status, data: { id } } = response;
    if (status === 'error') {
      if (isNecessaryToOptimisticBox) {
        dispatch({
          type: DOCUMENT.DELETE_OPTIMISTIC_BOX,
          payload: {
            ...boxData,
            isCreating: false,
          },
        });
      }

      getBoxManager().forgetBox(boxData);

      showError(response, dispatch);
    }
    else {
      if (isNecessaryToOptimisticBox) {
        delete boxData.content;

        dispatch({
          type: DOCUMENT.REPLACE_OPTIMISTIC_BOX,
          payload: {
            ...boxData,
            id,
            isCreating: false,
          },
        });
      } else {
        dispatch({
          type: DOCUMENT.CREATE_BOX,
          payload: {
            ...boxData,
            id,
          },
        });
      }

      actions.setBoxNeedsFocus(boxData.boxKey)(dispatch, getState);
      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });
    }
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVED,
    });
    actions.updateBoxModel()(dispatch, getState);
    actions.setChangedWhenPublished(true)(dispatch, getState);
    return response;
  },
  updateBox: (boxData, applyingSnapshot = false) => async (dispatch, getState) => {
    const { document: { socket, boxes } } = getState();

    if (getBoxManager().getViewModel(boxData)?.checkIgnoreSocketUpdate()) return;

    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVING,
    });
    dispatch({
      type: DOCUMENT.UPDATE_BOX,
      payload: boxData,
    });

    if (!applyingSnapshot) getBoxManager().getViewModel(boxData)?.onUpdateByCurrentSession();

    const prevBoxData = boxData.id
      ? boxes.find((item) => item.id === boxData.id)
      : boxes.find((item) => item.boxKey === boxData.boxKey);
    const mergedBoxData = mergeBoxesData(prevBoxData, boxData);

    if (socket?.socket?.id) {
      mergedBoxData.socketId = socket.socket.id;
    }

    const response = boxData.id
      ? await updateBox({ boxData: mergedBoxData })
      : { errors: '' };
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: DOCUMENT.UPDATE_BOX,
        payload: prevBoxData,
      });
    }
    else {
      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });
    }
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVED,
    });
    actions.updateBoxModel()(dispatch, getState);
    actions.setChangedWhenPublished(true)(dispatch, getState);
    return response;
  },
  deleteBox: (boxData) => async (dispatch, getState) => {
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVING,
    });
    dispatch({
      type: DOCUMENT.DELETE_BOX,
      payload: boxData,
    });
    const response = await deleteBox({
      boxData,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: DOCUMENT.CREATE_BOX,
        payload: boxData,
      });
    }
    else {
      dispatch({
        type: DOCUMENT.MUTATE_WORDS_COUNT,
      });
    }
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVED,
    });
    actions.updateBoxModel()(dispatch, getState);
    actions.setChangedWhenPublished(true)(dispatch, getState);
    return response;
  },
  reorderBoxes: (boxesIds) => async (dispatch, getState) => {
    const { document: { data: { id: documentId } } } = getState();
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVING,
    });
    const { document: { boxes } } = getState();
    const prevBoxesIds = boxes.map((item) => item.id);
    dispatch({
      type: DOCUMENT.REORDER_BOXES,
      payload: boxesIds,
    });
    const response = await reorderBoxes({
      documentId,
      boxesIds,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: DOCUMENT.REORDER_BOXES,
        payload: prevBoxesIds,
      });
    }
    dispatch({
      type: DOCUMENT.MUTATE_READY_STATE,
      payload: DOCUMENT_READY_STATE.AUTO_SAVED,
    });
    actions.setChangedWhenPublished(true)(dispatch, getState);
    return response;
  },
  lockBox: (boxData) => async (dispatch, getState) => {
    const { profile: { data: { id: userId } }, document: { boxes } } = getState();
    dispatch({
      type: DOCUMENT.LOCK_BOX,
      payload: {
        id: boxData.id,
        userId,
      },
    });
    const prevBoxData = boxData.id
      ? boxes.find((item) => item.id === boxData.id)
      : boxes.find((item) => item.boxKey === boxData.boxKey);
    const response = await updateBox({ boxData: prevBoxData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    return response;
  },
  unlockBox: (boxData) => (dispatch, getState) => {
    const { document: { socket } } = getState();
    if (!socket) {
      return;
    }
    socket.unlockBox(boxData);
    dispatch({
      type: DOCUMENT.UNLOCK_BOX,
      payload: boxData,
    });
  },
  fetchBoxTemplates: () => async (dispatch) => {
    const response = await fetchBoxTemplates();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    dispatch({
      type: DOCUMENT.MUTATE_BOX_TEMPLATES,
      payload: data,
    });
    return response;
  },
  setCoverBoxImageUsage: (boxData) => async (dispatch) => {
    const response = await createImageUsage({
      boxData,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    return response;
  },
  setImageBoxImageUsage: (boxData) => async (dispatch, getState) => {
    const { document: { boxes } } = getState();
    const prevBoxData = boxes.find((item) => item.id === boxData.id);
    dispatch({
      type: DOCUMENT.UPDATE_BOX,
      payload: boxData,
    });

    getBoxManager().getViewModel(boxData)?.onUpdateByCurrentSession();

    const response = await createImageUsage({
      boxData,
    });
    const { status, data: { imageId } } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: DOCUMENT.UPDATE_BOX,
        payload: prevBoxData,
      });
    }
    else {
      await actions.updateBox({
        ...boxData,
        content: {
          ...boxData.content,
          imageId,
        },
      })(dispatch, getState);
    }
    return response;
  },
  createImageUsage: (boxData) => (dispatch, getState) => {
    const { document: { data: { id } } } = getState();
    boxData.content.documentId = id;
    if (boxData.type === BOX_TYPES.COVER_IMAGE) {
      return actions.setCoverBoxImageUsage(boxData)(dispatch, getState);
    }
    if (boxData.type === BOX_TYPES.WIDE_IMAGE) {
      return actions.setImageBoxImageUsage(boxData)(dispatch, getState);
    }
    return { errors: '', data: {} };
  },
  checkAdSpace: () => (dispatch) => {
    dispatch({
      type: DOCUMENT.MUTATE_AD_SPACE,
    });
  },
};

import isEqual from 'lodash/isEqual';
import React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { mapUser } from '~/assets/helpers/mappers';
import { permissionsSelector } from '~/assets/helpers/selectors';
import { PROFILE_INNER_STATE, canUser } from '~/assets/helpers/users';

export function usePermissions() {
  return useSelector(permissionsSelector, isEqual);
}

const rawPermissionsSelector = createSelector(
  (state) => state.profile.permissions,
  (permissions) => permissions,
);

export function useRawPermissions() {
  return useSelector(rawPermissionsSelector, isEqual);
}

export function useCanUser(subject, actions) {
  const permissions = usePermissions();

  return React.useMemo(() => {
    return canUser(permissions, subject, actions);
  }, [
    permissions,
    subject,
    actions,
  ]);
}

const isAuthedSelector = createSelector(
  (state) => state.profile.isAuthenticated,
  (isAuthenticated) => {
    return isAuthenticated;
  },
);

export function useIsAuthed() {
  return useSelector(isAuthedSelector);
}

const emptyProfile = mapUser(null);

const profileSelector = createSelector(
  (state) => state.profile.innerState,
  (state) => state.profile.data,
  (readyState, profile) => {
    if (readyState === PROFILE_INNER_STATE.FETCHED) {
      return {
        readyState,
        data: profile,
      };
    }
    return {
      readyState,
      data: emptyProfile,
    };
  },
);

/**
 * Returns user profile
 * @return {ProfileData} profileData
 * */
export function useProfile() {
  return useSelector(profileSelector, isEqual);
}

/***
 * Returns roles with permission to do passed actions
 * @param {string} subject
 * @param {Array<string>} actions
 * @return {Array<UserRole>}
 * */
export function useWhatRolesCan(subject, actions) {
  const rawPermission = useRawPermissions();

  return React.useMemo(() => {
    return Object
      .entries(rawPermission)
      .filter(([, rolePermissions]) => {
        return rolePermissions
          && Array.isArray(rolePermissions[subject])
          && actions.every((action) => rolePermissions[subject].includes(action));
      })
      .map(([role]) => role);
  }, [
    rawPermission,
    subject,
    actions,
  ]);
}

/**
 * @typedef {Object} ProfileData
 * @property {string} readyState
 * @property {UserData} data
 * */

/**
 * @typedef {Object} UserData
 * @property {string} id
 * @property {boolean} isActive
 * @property {boolean} isOnline
 * @property {boolean} isTester
 * @property {UserRole} role
 * @property {string} email
 * @property {string} phone
 * @property {string} socialsFacebook
 * @property {string} socialsTelegram
 * @property {string} socialsTwitter
 * @property {string} createdAt
 * @property {string} lastActivityAt
 * @deprecated {string} dateCreatedAtHuman
 * @property {string} publishPermissions
 * @property {string} userName
 * @property {string} firstName
 * @property {string} middleNames
 * @property {string} lastName
 * @property {string} initials
 * @property {string} color
 * */

/**
 * @typedef {'author'|'editor'|'illustrator'|'editor_in_chief'|'proof_reader'|'tester'} UserRole
 * */

import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import s from './styles.scss';

UserAvatar.propTypes = {
  className: propTypes.string,
  withRing: propTypes.bool,
  userName: propTypes.string,
  initials: propTypes.string,
  color: propTypes.string,
};

UserAvatar.defaultProps = {
  className: '',
  withRing: false,
  userName: '?',
  initials: '?',
  color: '#666666',
};

function UserAvatar(props) {
  const {
    className,
    withRing,
    userName,
    initials,
    color,
  } = props;

  return (
    <span
      className={cn(s.userAvatar, withRing && s.withRing, className)}
      title={userName}
    >
      <span className={s.avatarWrp}>
        <span
          className={s.avatar}
          style={{ backgroundColor: color }}
        >
          {initials}
        </span>
      </span>
    </span>
  );
}

export default UserAvatar;

import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { usePrevious } from '~/assets/hooks/usePrevious';
import { useAction } from '~/assets/hooks/useActions';
import { useViewport } from '~/assets/hooks/useViewport';
import { useLastScroll } from '~/assets/hooks/useLastScroll';
import { useMenu } from '~/components/Shared/Menus/hooks/useMenu';
import { useModals } from '~/components/Shared/Modals/hooks/useModals';
import { useReleaseVersion } from '~/assets/hooks/useReleaseVersion';
import { BREAKPOINTS } from '~/assets/helpers/viewport';
import { actions as profileActions } from '~/store/reducers/profile';
import Spinner from '~/components/Shared/Utils/Spinner';
import Menu from '~/components/Shared/Menus/Menu';
import MenuContainer from '~/components/Shared/Menus/Container';
import ModalContainer from '~/components/Shared/Modals/Container';
import PushNotifications from '~/components/Shared/Notifications/PushNotifications';
import NotificationContainer from '~/components/Shared/Notifications/NotificationContainer';
import s from './styles.scss';

Layout.propTypes = {
  children: propTypes.node,
};

Layout.defaultProps = {
  children: null,
};

function Layout(props) {
  const { children } = props;
  const initializeSession = useAction(profileActions.initializeSession);
  const { breakpoint } = useViewport();
  const desktop = ![BREAKPOINTS.XXS, BREAKPOINTS.XS].includes(breakpoint);
  const [getLastScroll, , resetLastScroll] = useLastScroll();
  const [menuOpened] = useMenu();
  const [{ opened: modalOpened }] = useModals();
  /** @type {{current: HTMLElement}}*/
  const layoutRef = React.useRef(null);
  const locked = modalOpened || menuOpened;
  const prevLocked = usePrevious(locked);

  useReleaseVersion();

  React.useLayoutEffect(() => {
    if (prevLocked === undefined) {
      return;
    }
    const layout = layoutRef.current;
    const [lastScrollLeft, lastScrollTop] = getLastScroll();
    if (locked && locked !== prevLocked) {
      layout.scrollLeft = lastScrollLeft;
      layout.scrollTop = lastScrollTop;
      if (desktop) {
        Array.from(layout.children).forEach((child) => {
          child.style.setProperty('min-height', `${layout.scrollHeight}px`);
        });
      }
    }
    if (!locked && locked !== prevLocked) {
      if (desktop) {
        Array.from(layout.children).forEach((child) => {
          child.style.removeProperty('min-height');
        });
      }
      window.document.documentElement.scrollLeft = lastScrollLeft;
      window.document.documentElement.scrollTop = lastScrollTop;
      resetLastScroll();
    }
  }, [
    locked,
    prevLocked,
    desktop,
    getLastScroll,
    resetLastScroll,
  ]);
  React.useEffect(() => {
    initializeSession();
  }, [
    initializeSession,
  ]);

  return (
    <React.Fragment>
      <div
        className={cn(s.layout, locked && s.layoutLocked)}
        ref={layoutRef}
        inert={locked ? 'inert' : undefined}
      >
        <Menu className={s.menu} />
        <main className={s.main}>
          <React.Suspense fallback={<Spinner className={s.spinner} />}>
            {children}
          </React.Suspense>
        </main>
      </div>
      <MenuContainer />
      <ModalContainer />
      <NotificationContainer />
      <PushNotifications />
    </React.Fragment>
  );
}

export default Layout;

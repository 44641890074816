import { nanoid as getId } from 'nanoid';
import React from 'react';

/**
 * Shared data
 * @typedef {*} sharedData
 * */

/**
 * Shared data setter function
 * @typedef {Function} setSharedData
 * @param {(*|Function)} nextData
 * */

/**
 * @typedef {Function} useSharedData
 * @returns {[sharedData, setSharedData]}
 * */

/**
 * Creates React Hook for using shared state across usage
 * @param {*} initialData - Initial shared data
 * @param {boolean} [keepData=false] - After hook unmounting keep shared data
 * @return useSharedData
 * */
export function createSharedDataHook(initialData, keepData = false) {
  const LISTENERS_MAP = new Map();

  let SHARED_DATA_MAP = initialData;

  function useSharedData() {
    const keyRef = React.useRef(getId());
    const [data, setData] = React.useState(SHARED_DATA_MAP);

    const listener = React.useCallback((nextData) => {
      setData(nextData);
    }, []);
    const handleDataSet = React.useCallback((nextData) => {
      setSharedData(nextData);
    }, []);

    React.useEffect(() => {
      const key = keyRef.current;
      register(key, listener);
      return () => {
        unregister(key);
      };
    }, [
      listener,
    ]);

    return [data, handleDataSet];
  }

  function setSharedData(nextData) {
    if (typeof nextData === 'function') {
      SHARED_DATA_MAP = nextData(SHARED_DATA_MAP);
    }
    else {
      SHARED_DATA_MAP = nextData;
    }
    LISTENERS_MAP.forEach((listener) => {
      listener(SHARED_DATA_MAP);
    });
  }

  function register(key, listener) {
    if (!LISTENERS_MAP.has(key)) {
      LISTENERS_MAP.set(key, listener);
    }
  }

  function unregister(key) {
    if (LISTENERS_MAP.has(key)) {
      LISTENERS_MAP.delete(key);
    }
    if (!keepData && LISTENERS_MAP.size === 0) {
      SHARED_DATA_MAP = initialData;
    }
  }

  return useSharedData;
}

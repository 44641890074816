import { nanoid as getId } from 'nanoid';
import { FIREBASE_API_KEY } from '~/app.config';
import {
  storageGet,
  storageSet,
  storageRemove,
} from '~/assets/libs/localStorage';

const FIREBASE_TOKEN_KEY = 'FIREBASE_TOKEN_SENT';

export const NOTIFICATION_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const NOTIFICATION_TIMEOUT = 4000;

export const PUSH_PERMISSIONS = {
  UNSUPPORTED: 'unsupported',
  DEFAULT: 'default',
  DENIED: 'denied',
  GRANTED: 'granted',
};

export const FIREBASE_CONFIG = {
  apiKey: FIREBASE_API_KEY,
  authDomain: 'cockroach-cms.firebaseapp.com',
  databaseURL: 'https://cockroach-cms.firebaseio.com',
  projectId: 'cockroach-cms',
  storageBucket: '',
  messagingSenderId: '412306643587',
  appId: '1:412306643587:web:915a6f8766503cc9',
};

export function createNotification(options) {
  const key = getId();
  return {
    key: options.key || key,
    id: options.id || key,
    type: options.type || NOTIFICATION_TYPES.INFO,
    title: options.title || '',
    text: options.text || '',
    showCloseButton: typeof options.showCloseButton === 'boolean'
      ? options.showCloseButton
      : true,
    autoClose: typeof options.autoClose === 'boolean'
      ? options.autoClose
      : true,
    renderContent: typeof options.renderContent === 'function'
      ? options.renderContent
      : undefined,
  };
}

export function isBrowserPushCompatible() {
  return 'Notification' in window && 'serviceWorker' in window.navigator;
}

export function getPushPermission() {
  if (!isBrowserPushCompatible()) {
    return PUSH_PERMISSIONS.UNSUPPORTED;
  }
  return window.Notification.permission;
}

export function requestPushPermission() {
  if (!isBrowserPushCompatible()) {
    return PUSH_PERMISSIONS.UNSUPPORTED;
  }
  return window.Notification.requestPermission();
}

export function wasFirebaseTokenAlreadySent() {
  return storageGet(FIREBASE_TOKEN_KEY, '0') === '1';
}

export function setFirebaseTokenAlreadySent(wasSent) {
  storageSet(FIREBASE_TOKEN_KEY, wasSent ? '1' : '0');
}

export function deleteFirebaseTokenAlreadySent() {
  storageRemove(FIREBASE_TOKEN_KEY);
}

import io from 'socket.io-client';
import { COLLABOK_URL } from '~/app.config';
import { getTokens } from '~/assets/helpers/token';
import { getBoxesPayload } from '~/assets/helpers/boxes';

const EVENTS = {
  ON_RECONNECT: 'reconnect',
  ON_CONNECT: 'connect',
  ON_DISCONNECT: 'disconnect',
  ON_ERROR: 'err',
  ON_DOCUMENT: 'document',
  ON_DOCUMENT_UPDATED: 'document.updated',
  EMIT_DOCUMENT_UPDATE: 'document.update',
  ON_DOCUMENT_USERS: 'document.users',
  ON_DOCUMENT_BOX: 'document.box',
  ON_BOXES_REORDER: 'box.updated_position',
  ON_BOX_CREATED: 'box.created',
  ON_BOX_UPDATED: 'box.updated',
  ON_BOX_DELETED: 'box.deleted',
  ON_BOX_UNLOCKED: 'box.unlocked',
  EMIT_BOX_UNLOCK: 'box.unlock',
};

const onReconnect = (socket) => (cb) => {
  socket.on(EVENTS.ON_RECONNECT, cb);
};
const onConnect = (socket) => (cb) => {
  socket.on(EVENTS.ON_CONNECT, cb);
};
const onDisconnect = (socket) => (cb) => {
  socket.on(EVENTS.ON_DISCONNECT, cb);
};
const onError = (socket) => (cb) => {
  socket.on('onError', socket);
  socket.on(EVENTS.ON_ERROR, cb);
};
const onDocument = (socket) => (cb) => {
  socket.on('onDocument', socket);
  socket.on(EVENTS.ON_DOCUMENT, cb);
};
const onDocumentUpdated = (socket) => (cb) => {
  socket.on('onDocumentUpdated', socket);
  socket.on(EVENTS.ON_DOCUMENT_UPDATED, cb);
};
const documentUpdate = (socket) => (payload) => {
  socket.emit(EVENTS.EMIT_DOCUMENT_UPDATE, payload);
};
const onDocumentUsers = (socket) => (cb) => {
  socket.on('onDocumentUsers', socket);
  socket.on(EVENTS.ON_DOCUMENT_USERS, cb);
};
const onDocumentBoxOnce = (socket) => (cb) => {
  socket.on('onDocumentBoxOnce', socket);
  socket.on(EVENTS.ON_DOCUMENT_BOX, cb);
};
const onBoxesReorder = (socket) => (cb) => {
  socket.on('onBoxesReorder');
  socket.on(EVENTS.ON_BOXES_REORDER, cb);
};
const onBoxCreated = (socket) => (cb) => {
  socket.on('onBoxCreated');
  socket.on(EVENTS.ON_BOX_CREATED, cb);
};
const onBoxUpdated = (socket) => (cb) => {
  socket.on(EVENTS.ON_BOX_UPDATED, cb);
};
const onBoxDeleted = (socket) => (cb) => {
  socket.on(EVENTS.ON_BOX_DELETED, cb);
};
const onBoxUnlocked = (socket) => (cb) => {
  socket.on(EVENTS.ON_BOX_UNLOCKED, cb);
};
const unlockBox = (socket) => (payload) => {
  socket.emit(EVENTS.EMIT_BOX_UNLOCK, getBoxesPayload(payload));
};
const open = (socket) => (token) => {
  socket.io.opts.query.userToken = token;
  socket.io.open();
};
const close = (socket) => () => {
  socket.close();
};

export default (documentId, userId) => {
  const { accessToken } = getTokens();
  const socket = io(COLLABOK_URL, {
    query: {
      documentId,
      userId,
      userToken: accessToken,
    },
    transports: ['websocket'],
  });

  return {
    socket,
    onReconnect: onReconnect(socket),
    onConnect: onConnect(socket),
    onDisconnect: onDisconnect(socket),
    onError: onError(socket),
    onDocument: onDocument(socket),
    documentUpdate: documentUpdate(socket),
    onDocumentUpdated: onDocumentUpdated(socket),
    onDocumentUsers: onDocumentUsers(socket),
    onDocumentBoxOnce: onDocumentBoxOnce(socket),
    onBoxesReorder: onBoxesReorder(socket),
    onBoxCreated: onBoxCreated(socket),
    onBoxUpdated: onBoxUpdated(socket),
    onBoxDeleted: onBoxDeleted(socket),
    onBoxUnlocked: onBoxUnlocked(socket),
    unlockBox: unlockBox(socket),
    close: close(socket),
    open: open(socket),
  };
};

function setValueByType(key, value, formData) {
  switch (typeof value) {
    case 'undefined':
      break;
    case 'boolean':
      formData.append(key, value ? 1 : 0);
      break;
    case 'object': {
      if (value instanceof File || Array.isArray(value)) {
        formData.append(key, value);
        break;
      }
      Object.entries(value || {}).forEach(([innerKey, innerValue]) => {
        setValueByType(`${key}[${innerKey}]`, innerValue, formData);
      });
      break;
    }
    default:
      formData.append(key, value);
      break;
  }
}

export default (payload = {}) => {
  const formData = new FormData();
  Object.entries(payload || {})
    .forEach(([key, value]) => {
      setValueByType(key, value, formData);
    });
  return formData;
};

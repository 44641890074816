/* eslint-disable consistent-return, no-restricted-syntax, no-continue */

/**
 * Like HashMap, but handles objects with multiple identifiers
 *
 * @example
 * ```
 * const map = new CompositeKeyMap(['optimisticId', 'serverId']);
 *
 * class ViewModel {
 *  constructor(num) { this.num = num; }
 *
 *  getNum() { return this.num }
 * }
 *
 * let value = map.get({ optimisticId: null, serverId: 10 }); // undefined
 *
 * map.set({ optimisticId: null, serverId: 10 }, new ViewModel(1)
 *
 * value = map.get({ optimisticId: null, serverId: 10 }); // view model
 * let num = value.getNum(); // 1
 * ```
 *
 * T is object that should be stored
 * @template T
 */
export class CompositeKeyMap {
    /**
     * @type {Record<string, Map<*, T>>}
     * @private
     * @readonly
     */
    _maps;

    /**
     * @type {string[]}
     * @private
     * @readonly
     */
    _props;

    /**
     * @param {string[]} props Key properties
     */
    constructor(props) {
      this._maps = {};
      this._props = props;

      for (const prop of props) {
        this._maps[prop] = new Map();
      }
    }

    /**
     * @param {object} object
     * @return {T|undefined}
     */
    get(object) {
      for (const { key, map } of this._iterateMapsFor(object)) {
        const value = map.get(key);

        if (typeof value === 'undefined') continue;

        // update references
        this.set(object, value);

        return value;
      }
    }

    set(object, value) {
      for (const { key, map } of this._iterateMapsFor(object)) map.set(key, value);
    }

    /**
     * @return {void}
     */
    clear() {
      for (const prop of this._props) this._maps[prop].clear();
    }

    /**
     * @param object
     * @return {void}
     */
    delete(object) {
      for (const { key, map } of this._iterateMapsFor(object)) map.delete(key);
    }

    /**
     * Iterates over maps and valid map keys from object
     *
     * @param {object} object
     * @return {Iterable<{ key: *, map: Map<*, T> }>}
     * @private
     */
    * _iterateMapsFor(object) {
      for (const prop of this._props) {
        let key = object[prop];
        const map = this._maps[prop];

        if (!key) continue;

        key = String(key);

        yield { key, map };
      }
    }
}

import { stringify } from 'query-string';
import { API_URL, ERROR_MESSAGE } from '~/app.config';
import createFetch from '~/assets/libs/createFetch';
import getFormData from '~/assets/libs/getFormData';
import getProperty from '~/assets/libs/getProperty';
import { getAccessTokenHeader } from '~/assets/helpers/token';
import { mapComment, mapThreads } from '~/assets/helpers/mappers';
import { getThreadPayload, getCommentPayload } from '~/assets/helpers/comments';

export const fetchThreads = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: [],
  };
  const { documentId } = payload;
  const fetchTransport = createFetch(API_URL);
  const query = stringify({ subject_id: `/documents/${documentId}` });
  try {
    const raw = await fetchTransport(`/threads/?${query}`, {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data = mapThreads(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const addThread = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: {
      threadId: '',
      commentData: mapComment(null),
    },
  };
  const { threadData } = payload;
  const formData = getFormData(getThreadPayload(threadData));
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/threads/', {
      method: 'POST',
      headers: getAccessTokenHeader(),
      body: formData,
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await raw.json();
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data.threadId = raw.headers.get('X-Created-Resource-Id');
    response.data.commentData = mapComment(getProperty(data, 'comment', null));
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const resolveThread = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { threadData: { id: threadId } } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/threads/${threadId}/resolve`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const deleteThread = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { threadData: { id: threadId } } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/threads/${threadId}`, {
      method: 'DELETE',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const updateThreadPosition = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { threadData: { id: threadId, ...threadData } } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/threads/${threadId}/update_position`, {
      method: 'PUT',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getThreadPayload(threadData)),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const addComment = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: {
      commentId: '',
    },
  };
  const { commentData: { threadId, ...commentData } } = payload;
  const formData = getFormData(getCommentPayload(commentData));
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/threads/${threadId}/comments`, {
      method: 'POST',
      headers: getAccessTokenHeader(),
      body: formData,
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    response.data.commentId = raw.headers.get('X-Created-Resource-Id');
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const editComment = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { commentData: { threadId, id: commentId, ...commentData } } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/threads/${threadId}/comments/${commentId}`, {
      method: 'PUT',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getCommentPayload(commentData)),
    });

    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

export const deleteComment = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
  };
  const { commentData: { threadId, id: commentId } } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/threads/${threadId}/comments/${commentId}`, {
      method: 'DELETE',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.status = 'success';
    response.code = raw.status;
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

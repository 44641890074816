import React from 'react';
import propTypes from 'prop-types';
import { useViewport } from '~/assets/hooks/useViewport';
import { BREAKPOINTS } from '~/assets/helpers/viewport';
import { useMenu } from '~/components/Shared/Menus/hooks/useMenu';
import MenuTop from '~/components/Shared/Menus/Top';
import MenuDrawer from '~/components/Shared/Menus/Drawer';

Menu.propTypes = {
  className: propTypes.string,
};

Menu.defaultProps = {
  className: '',
};

function Menu(props) {
  const { className } = props;
  const [, setOpened] = useMenu();
  const { breakpoint } = useViewport();
  const mobile = [BREAKPOINTS.XXS, BREAKPOINTS.XS].includes(breakpoint);

  const handleToggle = React.useCallback(() => {
    setOpened((prevOpened) => !prevOpened);
  }, [
    setOpened,
  ]);

  if (mobile) {
    return (
      <MenuTop
        className={className}
        opened={false}
        onToggle={handleToggle}
      />
    );
  }
  return (
    <MenuDrawer
      className={className}
      opened={false}
      mobile={false}
      onToggle={handleToggle}
    />
  );
}

export default React.memo(Menu);

import { nanoid as getId } from 'nanoid';
import getProperty from '~/assets/libs/getProperty';
import {
  getHumanDate,
  getLocalNowSecondsString,
  convertSecondsToLocalTimeZoneString,
} from '~/assets/libs/getHumanDate';
import normalizeUrl from '~/assets/libs/normalizeUrl';
import {
  DOCUMENT_DEFAULT_TITLE,
  DOCUMENT_STATES,
} from '~/assets/helpers/documents';
import {
  BOX_TYPES,
  HEADER_BOX_TYPES,
  LIST_BOX_TYPES,
  IMAGE_BOX_TYPES,
  getTagStringFromTypedNodes,
  getTextFieldWordsCount,
} from '~/assets/helpers/boxes';
import { USER_COLORS, USER_ROLES } from '~/assets/helpers/users';

export const EMPTY_DOCUMENT = mapDocument(null);

export function mapBoolean(source, path, defaultValue = false) {
  const rawValue = getProperty(source, path, defaultValue);
  return [true, 1, 'true', '1'].includes(rawValue);
}

export function mapBox(boxData) {
  const id = getProperty(boxData, 'id', '').toString();
  const boxKey = id || getId();
  const type = getProperty(boxData, 'type', BOX_TYPES.UNKNOWN);
  const text = getProperty(boxData, 'content.text', []);
  const lockedByUser = getProperty(boxData, 'lockedByUser', '').toString();
  const characters = getProperty(boxData, 'characters', 0);
  const words = getProperty(boxData, 'words', 0);
  const nextBoxId = getProperty(boxData, 'insert_before', '').toString();
  const nonBreakable = getProperty(boxData, 'non_breakable', false);
  const nonBreakableLast = getProperty(boxData, 'non_breakable_last', false);
  const mappedBox = {
    isCreating: false,
    isDeleting: false,
    nonBreakable,
    nonBreakableLast,
    id,
    nextBoxId,
    boxKey,
    type,
    content: {},
    lockedByUser,
    characters,
    words,
  };
  switch (type) {
    case BOX_TYPES.TEXT: {
      const contentText = getTagStringFromTypedNodes(text);
      const contentCount = getTextFieldWordsCount(contentText);
      return {
        ...mappedBox,
        content: {
          text: contentText,
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.HEADER: {
      const defaultLevel = HEADER_BOX_TYPES.LEVEL_2;
      const level = getProperty(boxData, 'content.level', defaultLevel);
      const contentText = getProperty(boxData, 'content.text', '');
      const contentCount = getTextFieldWordsCount(contentText);
      return {
        ...mappedBox,
        content: {
          text: contentText,
          level: parseInt(level) || defaultLevel,
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.LIST: {
      const contentText = getTagStringFromTypedNodes(text);
      const contentCount = getTextFieldWordsCount(contentText);
      return {
        ...mappedBox,
        content: {
          text: contentText,
          listType: getProperty(boxData, 'content.listType', LIST_BOX_TYPES.UNORDERED),
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.QUOTE: {
      const contentText = getTagStringFromTypedNodes(text);
      const contentCount = getTextFieldWordsCount(contentText);
      return {
        ...mappedBox,
        content: {
          text: contentText,
          source: getProperty(boxData, 'content.source', ''),
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.CONTAINER: {
      const contentText = getTagStringFromTypedNodes(text);
      const contentCount = getTextFieldWordsCount(contentText);
      return {
        ...mappedBox,
        content: {
          text: contentText,
          size: getProperty(boxData, 'content.size', 'small'),
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.DISCLAIMER: {
      const contentText = getTagStringFromTypedNodes(text);
      const contentCount = getTextFieldWordsCount(contentText);
      return {
        ...mappedBox,
        content: {
          text: contentText,
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.WIDE_IMAGE: {
      const contentCaption = getProperty(boxData, 'content.image_properties.caption', []);
      const contentHTML = getTagStringFromTypedNodes(contentCaption);
      const contentCount = getTextFieldWordsCount(contentHTML);
      return {
        ...mappedBox,
        content: {
          imageId: getProperty(boxData, 'content.image.id', ''),
          imageType: getProperty(boxData, 'content.image.type', IMAGE_BOX_TYPES.WIDE),
          imageUrl: getProperty(boxData, 'content.image.url', ''),
          imageGalleryId: getProperty(boxData, 'content.image.gallery_image_id', ''),
          imageCaption: contentHTML,
          imageAlt: getProperty(boxData, 'content.image_properties.alt', ''),
          imageTitle: getProperty(boxData, 'content.image_properties.title', ''),
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.EMBED:
    case BOX_TYPES.DOCUMENTCLOUD:
    case BOX_TYPES.BUZZSPROUT:
    case BOX_TYPES.INSTAGRAM:
    case BOX_TYPES.TWITTER:
      return {
        ...mappedBox,
        content: {
          embedId: getProperty(boxData, 'content.id', '').toString(),
        },
      };
    case BOX_TYPES.YOUTUBE:
    case BOX_TYPES.VIMEO: {
      const contentCaption = getProperty(boxData, 'content.caption', []);
      const contentHTML = getTagStringFromTypedNodes(contentCaption);
      const contentCount = getTextFieldWordsCount(contentHTML);
      return {
        ...mappedBox,
        content: {
          embedId: getProperty(boxData, 'content.id', '').toString(),
          embedCaption: contentHTML,
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.AUTHOR_DESC: {
      const contentText = getTagStringFromTypedNodes(text);
      const contentCount = getTextFieldWordsCount(contentText);
      return {
        ...mappedBox,
        content: {
          text: contentText,
        },
        characters: contentCount.characters,
        words: contentCount.words,
      };
    }
    case BOX_TYPES.SUBSCRIPTION_FORM:
      return {
        ...mappedBox,
        content: {
          type: getProperty(boxData, 'content.type', ''),
        },
      };
    case BOX_TYPES.UNKNOWN:
    default:
      return mappedBox;
  }
}

export function mapBoxes(inputData, options = {}) {
  const boxes = Array.isArray(inputData)
    ? inputData
    : [];
  return boxes.map((item) => mapBox(item, options));
}

export function mapBoxTemplate(inputData) {
  const id = getProperty(inputData, 'id', '').toString();
  const label = getProperty(inputData, 'name', '');
  const type = getProperty(inputData, 'type', BOX_TYPES.UNKNOWN);
  const mappedBox = {
    id,
    type,
    label,
  };
  switch (type) {
    case BOX_TYPES.DISCLAIMER:
      return {
        ...mappedBox,
        text: getProperty(inputData, 'content.text', []),
      };
    case BOX_TYPES.UNKNOWN:
    default:
      return mappedBox;
  }
}

export function mapBoxTemplates(inputData) {
  return Array.isArray(inputData)
    ? inputData.map((item) => mapBoxTemplate(item))
    : [];
}

export function mapBoxesIds(boxesIds) {
  const ids = Array.isArray(boxesIds)
    ? boxesIds
    : [];
  return ids.map((item) => item.toString());
}

export function mapCategory(authorData) {
  const id = getProperty(authorData, 'id', '').toString();
  const label = getProperty(authorData, 'title', '');
  const relevant = getProperty(authorData, 'relevant', '');
  return {
    id,
    label,
    relevant,
  };
}

export function mapCategories(inputData, options = {}) {
  return Array.isArray(inputData)
    ? inputData
      .map((item) => mapCategory(item, options))
      .sort((a, b) => {
        if (typeof ('').localeCompare === 'function') {
          return a.label.localeCompare(b.label, 'en-US', {
            ignorePunctuation: true,
          });
        }
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
    : [];
}

export function mapComment(item) {
  const id = getProperty(item, 'id', '').toString();
  const commentKey = id || getId();
  const threadId = getProperty(item, 'thread_id', '').toString();
  const userId = getProperty(item, 'user_id', '').toString();
  const rawCreatedAt = getProperty(item, 'created_at', '');
  const createdAt = convertSecondsToLocalTimeZoneString(rawCreatedAt)
    || getLocalNowSecondsString();
  const text = getProperty(item, 'text', '');
  return {
    id,
    commentKey,
    threadId,
    userId,
    createdAt,
    text,
  };
}

export function mapThread(item) {
  const isResolved = getProperty(item, 'resolved', false);
  const id = getProperty(item, 'id', '').toString();
  const subjectString = getProperty(item, 'subject_id', '').toString();
  const [
    subjectType = 'documents',
    subjectId = '',
  ] = subjectString
    .replace(/^\//, '')
    .split('/');
  const positionString = getProperty(item, 'position', '');
  let boxId;
  let threadKey;
  let selectionState;
  try {
    const position = JSON.parse(positionString);
    boxId = getProperty(position, 'boxId', '').toString();
    threadKey = getProperty(position, 'threadKey', '');
    selectionState = getProperty(position, 'selectionState', '');
  }
  catch (e) {
    const position = positionString.split('/');
    boxId = getProperty(position, '0', '').toString();
    threadKey = getProperty(position, '1', '');
    selectionState = getProperty(position, '2', '');
  }
  threadKey = threadKey || getId();
  const rawCreatedAt = getProperty(item, 'created_at', '');
  const createdAt = convertSecondsToLocalTimeZoneString(rawCreatedAt)
    || getLocalNowSecondsString();
  const userId = getProperty(item, 'user_id', '').toString();
  const comments = getProperty(item, 'comments', []).map(mapComment);
  return {
    isResolved,
    id,
    boxId,
    threadKey,
    selectionState,
    subjectType,
    subjectId,
    createdAt,
    userId,
    comments,
  };
}

export function mapThreads(inputData) {
  return Array.isArray(inputData)
    ? inputData.map(mapThread).filter((item) => !item.isResolved)
    : [];
}

export function mapDocument(documentData) {
  const id = getProperty(documentData, 'id', '')
    .toString();
  const creatorId = getProperty(documentData, 'created_by_id', '')
    .toString();
  const editorId = getProperty(documentData, 'assigned_editor_id', '')
    .toString();
  const authorId = getProperty(documentData, 'assigned_author_id', '')
    .toString();
  const proofreaderId = getProperty(documentData, 'assigned_proof_reader_id', '')
    .toString();
  const coverUpdaterId = getProperty(documentData, 'title_image_updated_by_id', '')
    .toString();
  const url = getProperty(documentData, 'post_url', '');
  const title = getProperty(documentData, 'title', '')
    || DOCUMENT_DEFAULT_TITLE;
  const isCanceled = mapBoolean(documentData, 'canceled', false);
  const needsProofReading = mapBoolean(documentData, 'need_proof_reading', true);
  const needsOriginalArtwork = mapBoolean(documentData, 'need_original_artwork', false);
  const isBreaking = mapBoolean(documentData, 'settings.breaking', false);
  const isPinned = mapBoolean(documentData, 'is_pinned', false);
  const pinnedSlot = getProperty(documentData, 'pinned_slot', 0);
  const leadText = getProperty(documentData, 'lead', '');
  const facebookLeadText = getProperty(documentData, 'facebook_lead', '');
  const youtubeCoverUrl = getProperty(documentData, 'youtube_url', '');
  const twitterLeadText = getProperty(documentData, 'twitter_lead', '');
  const telegramLeadText = getProperty(documentData, 'telegram_lead', '');
  const rawDateUpdatedAt = getProperty(documentData, 'updated_at', '');
  const dateUpdatedAt = convertSecondsToLocalTimeZoneString(rawDateUpdatedAt);
  const rawDateCreatedAt = getProperty(documentData, 'created_at', '');
  const dateCreatedAt = convertSecondsToLocalTimeZoneString(rawDateCreatedAt);
  const rawDatePublishedAt = getProperty(documentData, 'published_at', '');
  const datePublishedAt = convertSecondsToLocalTimeZoneString(rawDatePublishedAt);
  const rawDateDeadlineAt = getProperty(documentData, 'deadline', '');
  const dateDeadlineAt = convertSecondsToLocalTimeZoneString(rawDateDeadlineAt);
  const rawDateEmbargoAt = getProperty(documentData, 'embargo', '');
  const dateEmbargoAt = convertSecondsToLocalTimeZoneString(rawDateEmbargoAt);
  const rawDateStatusUpdatedAt = getProperty(documentData, 'status_updated_at', '');
  const dateStatusUpdatedAt = convertSecondsToLocalTimeZoneString(rawDateStatusUpdatedAt);
  const coverTitle = getProperty(documentData, 'title_image_properties.title', '');
  const coverCaption = getProperty(documentData, 'title_image_properties.caption', '');
  const coverAlt = getProperty(documentData, 'title_image_properties.alt', '');
  const coverExtension = getProperty(documentData, 'title_image.extension', '');
  const coverName = getProperty(documentData, 'title_image.filename', '');
  const coverGalleryId = getProperty(documentData, 'title_image.gallery_image_id', '')
    .toString();
  const coverId = getProperty(documentData, 'title_image.id', '')
    .toString();
  const coverType = getProperty(documentData, 'title_image.type', '');
  const coverUrl = getProperty(documentData, 'title_image.url', '');
  const superTags = getProperty(documentData, 'super_tag_ids', [])
    .map((tagId) => tagId.toString());
  const tags = getProperty(documentData, 'tag_ids', [])
    .map((tagId) => tagId.toString());
  const labelId = getProperty(documentData, 'label_id', '')
    .toString();
  const categoryId = getProperty(documentData, 'category_id', '')
    .toString();
  const authors = getProperty(documentData, 'author_ids', [])
    .map((item) => item.toString());
  const metadataNoIndex = mapBoolean(documentData, 'metadata.noindex', false);
  const metadataKeywords = getProperty(documentData, 'metadata.keywords', '');
  const metadataDescription = getProperty(documentData, 'metadata.description', '');
  const pitch = getProperty(documentData, 'pitch', '');
  const state = getProperty(documentData, 'status', DOCUMENT_STATES.NEW);
  const metadata = getProperty(documentData, 'metadata', {});
  const settings = getProperty(documentData, 'settings', {});
  const flags = Object.entries(getProperty(documentData, 'settings', {}))
    .filter(([, isChecked]) => isChecked)
    .map(([key]) => key);
  const words = getProperty(documentData, 'words', 0);
  const characters = getProperty(documentData, 'characters', 0);
  const hideDisclaimerForPressRelease = getProperty(documentData, 'hide_disclaimer', false);
  const excludeFromFacebookRSS = getProperty(documentData, 'rss.facebook', false);
  const excludeFromTwitterRSS = getProperty(documentData, 'rss.twitter', false);
  const excludeFromTelegramRSS = getProperty(documentData, 'rss.telegram', false);
  const hideFromMainPage = mapBoolean(documentData, 'hide_from_main_page', false);
  const showInMarkets = mapBoolean(documentData, 'is_market', false);
  const isScheduled = mapBoolean(documentData, 'is_scheduled', false);
  const slug = getProperty(documentData, 'slug', '');
  const hideFromHotStories = getProperty(documentData, 'hide_from_hot_stories', '');
  const inRepublishedList = getProperty(documentData, 'in_republished_list', false);

  return {
    id,
    isCanceled,
    isBreaking,
    isPinned,
    pinnedSlot,
    needsProofReading,
    needsOriginalArtwork,
    state,
    pitch,
    title,
    leadText,
    url,
    facebookLeadText,
    youtubeCoverUrl,
    twitterLeadText,
    telegramLeadText,
    creatorId,
    editorId,
    authorId,
    proofreaderId,
    coverUpdaterId,
    authors,
    dateUpdatedAt,
    dateCreatedAt,
    datePublishedAt,
    dateDeadlineAt,
    dateEmbargoAt,
    dateStatusUpdatedAt,
    coverTitle,
    coverCaption,
    coverAlt,
    coverExtension,
    coverName,
    coverGalleryId,
    coverId,
    coverType,
    coverUrl,
    superTags,
    tags,
    categoryId,
    labelId,
    metadataNoIndex,
    metadataKeywords,
    metadataDescription,
    metadata,
    settings,
    flags,
    words,
    characters,
    hideDisclaimerForPressRelease,
    excludeFromFacebookRSS,
    excludeFromTwitterRSS,
    excludeFromTelegramRSS,
    hideFromMainPage,
    showInMarkets,
    isScheduled,
    slug,
    hideFromHotStories,
    inRepublishedList,
  };
}

export function mapDocuments(inputData, options = {}) {
  const list = getProperty(inputData, 'documents', []);
  const total = getProperty(inputData, 'count', 0);
  const embargoTotal = getProperty(inputData, 'embargoCount', 0);
  const breakingTotal = getProperty(inputData, 'breakingCount', 0);
  const delayedTotal = getProperty(inputData, 'deferredCount', 0);
  return {
    list: list.map((item) => mapDocument(item, options)),
    total,
    embargoTotal,
    breakingTotal,
    delayedTotal,
  };
}

export function mapArticlesKanbanColumn(inputData, options = {}) {
  const rawList = getProperty(inputData, 'documents', []);
  const total = getProperty(inputData, 'count', 0);
  const list = Array.isArray(rawList)
    ? rawList.map((article) => mapDocument(article, options))
    : [];
  return {
    list,
    total,
  };
}

export function mapLabel(authorData) {
  const id = getProperty(authorData, 'id', '').toString();
  const label = getProperty(authorData, 'title', '');
  return {
    id,
    label,
  };
}

export function mapLabels(inputData, options = {}) {
  return Array.isArray(inputData)
    ? inputData
      .map((item) => mapLabel(item, options))
      .sort((a, b) => {
        if (typeof ('').localeCompare === 'function') {
          return a.label.localeCompare(b.label, 'en-US', {
            ignorePunctuation: true,
          });
        }
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
    : [];
}

export function mapEditorsChoiceItem(choiceData) {
  const id = getProperty(choiceData, 'document_id', '').toString();
  const priority = getProperty(choiceData, 'priority', 0);
  const title = getProperty(choiceData, 'document.title', '');
  return {
    id,
    priority,
    title,
  };
}

export function mapEditorsChoiceList(inputData) {
  return Array.isArray(inputData)
    ? inputData.map(mapEditorsChoiceItem)
    : [];
}

export function mapRelatedNewsItem(documentData) {
  const id = getProperty(documentData, 'id', '')
    .toString();
  const title = getProperty(documentData, 'title', '');
  const isPinned = getProperty(documentData, 'isPinned', false);
  const pinnedSlot = getProperty(documentData, 'pinnedSlot', 0);
  const categoryId = getProperty(documentData, 'categoryId', '')
    .toString();
  return {
    id,
    title,
    isPinned,
    pinnedSlot,
    categoryId,
  };
}

export function mapRelatedNewsList(inputData) {
  return Array.isArray(inputData)
    ? inputData.map(mapRelatedNewsItem)
    : [];
}

export function mapImage(imageData) {
  const id = getProperty(imageData, 'id', '').toString();
  const url = getProperty(imageData, 'url', '');
  const description = getProperty(imageData, 'description', '');
  const fileName = getProperty(imageData, 'filename', '');
  const uploadedBy = getProperty(imageData, 'uploaded_by', '').toString();
  const rawCreatedAt = getProperty(imageData, 'created_at', '');
  const createdAt = convertSecondsToLocalTimeZoneString(rawCreatedAt);
  const rawLastUsedAt = getProperty(imageData, 'last_used_at', '');
  const lastUsedAt = convertSecondsToLocalTimeZoneString(rawLastUsedAt);
  const rawLastUsedForCoverAt = getProperty(imageData, 'last_used_for_title_at', '');
  const lastUsedForCoverAt = convertSecondsToLocalTimeZoneString(rawLastUsedForCoverAt);
  const rawLastUsedForBoxAt = getProperty(imageData, 'last_used_for_box_at', '');
  const lastUsedForBoxAt = convertSecondsToLocalTimeZoneString(rawLastUsedForBoxAt);
  const publishedCount = getProperty(imageData, 'published_count', 0);
  const rating = getProperty(imageData, 'rating', 0);
  const tags = getProperty(imageData, 'tag_ids', []).map((item) => item.toString());
  return {
    id,
    url,
    description,
    fileName,
    uploadedBy,
    createdAt,
    lastUsedAt,
    lastUsedForCoverAt,
    lastUsedForBoxAt,
    publishedCount,
    rating,
    tags,
  };
}

export function mapImages(inputData) {
  const images = getProperty(inputData, 'gallery_images', []);
  const count = getProperty(inputData, 'total_count', 0);
  return {
    list: images.map(mapImage),
    count,
  };
}

export function mapImageTag(tagData) {
  const id = getProperty(tagData, 'id', '').toString();
  const label = getProperty(tagData, 'name', '');
  return {
    id,
    label,
  };
}

export function mapImageTags(inputData) {
  return Array.isArray(inputData)
    ? inputData
      .map(mapImageTag)
      .sort((a, b) => {
        if (typeof ('').localeCompare === 'function') {
          return a.label.localeCompare(b.label, 'en-US', {
            ignorePunctuation: true,
          });
        }
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
    : [];
}

/**
 * @param {Object} inputData
 * @return {ArticleTagData}
 * */
export function mapTag(inputData) {
  const id = getProperty(inputData, 'id', '').toString();
  const label = getProperty(inputData, 'title', '');
  const isSuper = mapBoolean(inputData, 'super', false);
  const active = mapBoolean(inputData, 'active', true);
  return {
    id,
    label,
    isSuper,
    active,
  };
}

/**
 * @param {Object} inputData
 * @return {Array<ArticleTagData>}
 * */
export function mapTags(inputData) {
  const data = getProperty(inputData, 'data', []);
  return Array.isArray(data) ? data.map(mapTag) : [];
}

export function mapPermissions(permissionsData) {
  const byRole = permissionsData || {};
  const getRolePermissions = (role, prevPermissions) => {
    const rawPermissions = getProperty(byRole, `${role}.permissions`, []);
    const parentRoles = getProperty(byRole, `${role}.parent_roles`, []);
    const permissions = rawPermissions.reduce((result, item) => {
      const [action, subject, isAllowed] = item;
      if (!result[subject]) {
        result[subject] = [];
      }
      if (isAllowed && !result[subject].includes(action)) {
        result[subject] = (result[subject] || []).concat([action]);
      }
      return result;
    }, prevPermissions);
    return parentRoles.reduce((result, parentRole) => {
      return getRolePermissions(parentRole, result);
    }, permissions);
  };
  return Object.keys(byRole).reduce((result, role) => {
    result[role] = getRolePermissions(role, {});
    return result;
  }, {});
}

export function mapUser(userData) {
  const id = getProperty(userData, 'id', '').toString();
  const isActive = getProperty(userData, 'active', false);
  const isOnline = getProperty(userData, 'online', false);
  const rawRole = getProperty(userData, 'role', USER_ROLES.AUTHOR);
  const isTester = rawRole === USER_ROLES.TESTER;
  const role = isTester ? USER_ROLES.EDITOR_IN_CHIEF : rawRole;
  const email = getProperty(userData, 'email', '');
  const phone = getProperty(userData, 'phone', '').toString();
  const socialsFacebook = normalizeUrl(getProperty(userData, 'facebook', ''));
  const socialsTelegram = normalizeUrl(getProperty(userData, 'telegram', ''));
  const socialsTwitter = normalizeUrl(getProperty(userData, 'twitter', ''));
  const rawCreatedAt = getProperty(userData, 'created_at', '');
  const createdAt = convertSecondsToLocalTimeZoneString(rawCreatedAt);
  const dateCreatedAtHuman = getHumanDate(createdAt);
  const lastActivityAt = getProperty(userData, 'last_activity', '').toString();
  const publishPermissions = getProperty(userData, 'publish_permissions', []);
  const userName = getProperty(userData, 'name', '').trim();
  const [firstName, ...middleAndLastNames] = userName.split(' ');
  const lastName = middleAndLastNames.reverse()[0] || '';
  const middleNames = middleAndLastNames;
  const firstNameChar = firstName[0] || '';
  const lastNameChar = (lastName
    ? lastName[0]
    : firstName[1]) || '';
  const initials = (`${firstNameChar}${lastNameChar}`).toUpperCase();
  const color = USER_COLORS[id % 16];
  return {
    id,
    isActive,
    isOnline,
    isTester,
    role,
    email,
    phone,
    socialsFacebook,
    socialsTelegram,
    socialsTwitter,
    createdAt,
    lastActivityAt,
    dateCreatedAtHuman,
    publishPermissions,
    userName,
    firstName,
    middleNames,
    lastName,
    initials,
    color,
  };
}

export function mapUsers(inputData) {
  return Array.isArray(inputData)
    ? inputData
      .map(mapUser)
      .sort((a, b) => {
        if (a.isTester) {
          return 1;
        }
        if (typeof ('').localeCompare === 'function') {
          return a.userName.localeCompare(b.userName, 'en-US', {
            ignorePunctuation: true,
          });
        }
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      })
    : [];
}

export function mapAuthor(authorData) {
  const id = getProperty(authorData, 'id', '').toString();
  const userName = getProperty(authorData, 'title', '').trim();
  const [firstName, ...middleAndLastNames] = userName.split(' ');
  const lastName = middleAndLastNames.pop();
  const middleNames = middleAndLastNames;
  const firstNameChar = firstName[0];
  const lastNameChar = lastName
    ? lastName[0]
    : firstName[1];
  const initials = (`${firstNameChar}${lastNameChar}`).toUpperCase();
  const color = USER_COLORS[id % 16];
  return {
    id,
    userName,
    firstName,
    middleNames,
    lastName,
    initials,
    color,
  };
}

export function mapAuthors(inputData, options = {}) {
  return Array.isArray(inputData)
    ? inputData
      .filter((item) => item.title)
      .map((item) => mapAuthor(item, options))
      .sort((a, b) => {
        if (typeof ('').localeCompare === 'function') {
          return a.userName.localeCompare(b.userName, 'en-US', {
            ignorePunctuation: true,
          });
        }
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      })
    : [];
}

export function mapInvite(inviteData) {
  const id = getProperty(inviteData, 'id', '').toString();
  const role = getProperty(inviteData, 'role', USER_ROLES.AUTHOR);
  const email = getProperty(inviteData, 'email', '');
  const rawExpiresAt = getProperty(inviteData, 'expires_at', '');
  const expiresAt = convertSecondsToLocalTimeZoneString(rawExpiresAt);
  const rawCreatedAt = getProperty(inviteData, 'created_at', '');
  const createdAt = convertSecondsToLocalTimeZoneString(rawCreatedAt);
  const createdBy = getProperty(inviteData, 'created_by', '').toString();
  const dateCreatedAtHuman = getHumanDate(createdAt);
  return {
    id,
    role,
    email,
    expiresAt,
    createdAt,
    createdBy,
    dateCreatedAtHuman,
  };
}

export function mapInvites(inputData) {
  return Array.isArray(inputData)
    ? inputData.map(mapInvite)
    : [];
}

export function mapDocumentUsers(inputData) {
  return Array.isArray(inputData)
    ? inputData.map((item) => item.toString())
    : [];
}

export function mapPushNotification(messageData) {
  const id = getProperty(messageData, 'data.id', '').toString();
  const documentId = getProperty(messageData, 'data.document_id', '').toString();
  const title = getProperty(messageData, 'data.title', '');
  const message = getProperty(messageData, 'data.message', '');
  return {
    id,
    documentId,
    title,
    message,
  };
}

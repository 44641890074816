export const PROFILE = {
  MUTATE_INNER_STATE: 'profile/MUTATE_INNER_STATE',
  MUTATE_IS_AUTHED: 'profile/MUTATE_IS_AUTHED',
  MUTATE_DATA: 'profile/MUTATE_DATA',
  MUTATE_PERMISSIONS: 'profile/MUTATE_PERMISSIONS',
  UPDATE_DATA: 'profile/UPDATE_DATA',
  START_SHIFT: 'profile/START_SHIFT',
  STOP_SHIFT: 'profile/STOP_SHIFT',
};

export const USERS = {
  MUTATE_INNER_STATE: 'users/MUTATE_INNER_STATE',
  MUTATE_INVITES: 'users/MUTATE_INVITES',
  MUTATE_USERS: 'users/MUTATE_USERS',
  MUTATE_USER: 'users/MUTATE_USER',
  CREATE_INVITE: 'users/CREATE_INVITE',
  REVOKE_INVITE: 'users/REVOKE_INVITE',
  CREATE_USER: 'users/CREATE_USER',
};

export const NOTIFICATION = {
  ADD: 'notification/ADD',
  DELETE: 'notification/DELETE',
  SET_PUSH_PERMISSION: 'notification/SET_PUSH_PERMISSION',
};

export const COMMENTS = {
  MUTATE_ACTIVE_THREAD: 'comments/MUTATE_ACTIVE_THREAD',
  MUTATE_THREADS: 'comments/MUTATE_THREADS',
  ADD_THREAD: 'comments/ADD_THREAD',
  UPDATE_THREAD: 'comments/UPDATE_THREAD',
  DELETE_THREAD: 'comments/DELETE_THREAD',
  ADD_COMMENT: 'comments/ADD_COMMENT',
  UPDATE_COMMENT: 'comments/UPDATE_COMMENT',
  DELETE_COMMENT: 'comments/DELETE_COMMENT',
};

export const DOCUMENT = {
  MUTATE_READY_STATE: 'document/MUTATE_READY_STATE',
  MUTATE_TAB: 'document/MUTATE_TAB',
  MUTATE_SELECTION_RANGE: 'document/MUTATE_SELECTION_RANGE',
  MUTATE_CHANGED_WHEN_PUBLISHED: 'document/MUTATE_CHANGED_WHEN_PUBLISHED',
  MUTATE_DOCUMENT: 'document/MUTATE_DOCUMENT',
  MUTATE_BOXES: 'document/MUTATE_BOXES',
  MUTATE_FIELDS: 'document/MUTATE_FIELDS',
  MUTATE_BOX_FIELDS: 'document/MUTATE_BOX_FIELDS',
  MUTATE_WORDS_COUNT: 'document/MUTATE_WORDS_COUNT',
  MUTATE_BOX_TEMPLATES: 'document/MUTATE_BOX_TEMPLATES',
  MUTATE_BYLINE_AUTHORS: 'document/MUTATE_BYLINE_AUTHORS',
  MUTATE_CATEGORIES: 'document/MUTATE_CATEGORIES',
  MUTATE_LABELS: 'document/MUTATE_LABELS',
  MUTATE_SOCKET: 'document/MUTATE_SOCKET',
  MUTATE_WORKING_USERS: 'document/MUTATE_WORKING_USERS',
  FETCH_DOCUMENT: 'document/FETCH_DOCUMENT',
  UPDATE_DOCUMENT: 'document/UPDATE_DOCUMENT',
  PUBLISH_DOCUMENT: 'document/PUBLISH_DOCUMENT',
  UNPUBLISH_DOCUMENT: 'document/UNPUBLISH_DOCUMENT',
  UPDATE_DOCUMENT_STATE: 'document/UPDATE_DOCUMENT_STATE',
  ASSIGN_EDITOR: 'document/ASSIGN_EDITOR',
  ASSIGN_AUTHOR: 'document/ASSIGN_AUTHOR',
  ASSIGN_PROOFREADER: 'document/ASSIGN_PROOFREADER',
  FINISH_PROOFREADING: 'document/FINISH_PROOFREADING',
  FETCH_BOXES: 'document/FETCH_BOXES',
  MUTATE_BOX_NEEDS_FOCUS: 'document/MUTATE_BOX_NEEDS_FOCUS',
  CREATE_OPTIMISTIC_BOX: 'document/CREATE_OPTIMISTIC_BOX',
  REPLACE_OPTIMISTIC_BOX: 'document/REPLACE_OPTIMISTIC_BOX',
  DELETE_OPTIMISTIC_BOX: 'document/DELETE_OPTIMISTIC_BOX',
  CREATE_BOX: 'document/CREATE_BOX',
  UPDATE_BOX: 'document/UPDATE_BOX',
  DELETE_BOX: 'document/DELETE_BOX',
  UNLOCK_BOX: 'document/UNLOCK_BOX',
  LOCK_BOX: 'document/LOCK_BOX',
  REORDER_BOXES: 'document/REORDER_BOXES',
  FETCH_BOX_TEMPLATES: 'document/FETCH_BOX_TEMPLATES',
  CREATE_BOXES: 'document/CREATE_BOXES',
  FETCH_AUTHORS: 'document/FETCH_AUTHORS',
  FETCH_CATEGORIES: 'document/FETCH_CATEGORIES',
  FETCH_LABELS: 'document/FETCH_LABELS',
  CREATE_BYLINE_AUTHOR: 'document/CREATE_BYLINE_AUTHOR',
  MUTATE_AD_SPACE: 'document/MUTATE_AD_SPACE',
};

export const EDITORS_CHOICE = {
  MUTATE_INNER_STATE: 'editorsChoice/MUTATE_INNER_STATE',
  MUTATE_LIST: 'editorsChoice/MUTATE_LIST',
  MUTATE_LOCAL_LIST: 'editorsChoice/MUTATE_LOCAL_LIST',
};

export const RELATED_NEWS = {
  MUTATE_INNER_STATE: 'relatedNews/MUTATE_INNER_STATE',
  MUTATE_LIST: 'relatedNews/MUTATE_LIST',
  UPDATE_DOCUMENT: 'relatedNews/UPDATE_DOCUMENT',
  ADD_DOCUMENT: 'relatedNews/ADD_DOCUMENT',
};

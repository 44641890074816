import { DateTime } from 'luxon';

/**
 * Returns human-readable date/time string
 * @param {string} rawSeconds - Timestamp string in seconds
 * @param {Object} [options] - Options
 * @param {string} [options.format='ff'] - `luxon.DateTime` format string
 * @return {string} - Date/time string
 * */
export function getHumanDate(rawSeconds, options) {
  const {
    format = 'ff',
  } = options || {};
  if (!rawSeconds) {
    return '';
  }
  const date = DateTime.fromSeconds(+rawSeconds);
  if (date.invalid) {
    return '';
  }
  return date.toFormat(format);
}

/**
 * Returns human-readable relative date/time string
 * @param {string} rawSeconds - Timestamp string in seconds
 * @param {Object} [options] - `luxon.DateTime.toRelative` options
 * @return {string} - Date/time string of format `<relative date> at <locale time>`
 * */
export function getHumanRelativeDate(rawSeconds, options) {
  if (!rawSeconds) {
    return '';
  }
  const date = DateTime.fromSeconds(+rawSeconds);
  if (date.invalid) {
    return '';
  }
  const relativeDate = date.toRelative(options);
  const timeDate = date.toFormat('t');
  return `${relativeDate} at ${timeDate}`;
}

/**
 * Returns current timestamp string in seconds
 * @return {string} - Timestamp string in seconds
 * */
export function getLocalNowSecondsString() {
  return DateTime
    .local()
    .toSeconds()
    .toString();
}

/**
 * Converts timestamp string to current user's time zone
 * @param {string} rawSeconds - UTC timestamp string in seconds
 * @return {string} - Local timestamp string in seconds
 * */
export function convertSecondsToLocalTimeZoneString(rawSeconds) {
  if (!rawSeconds) {
    return '';
  }
  const date = DateTime.fromSeconds(+rawSeconds);
  if (date.invalid) {
    return '';
  }
  return date
    .setZone('local')
    .toSeconds()
    .toString();
}

/**
 * @typedef {Object} HumanRelativeDateTimeResult
 * @property {''|'behind'|'behindIn24h'|'aheadIn24h'|'ahead'} relativeness - Relativeness flag
 * @property {string} dateTime - Human-readable date
 * */

/**
 * Returns human-readable date and time relativeness flag
 * @param {string} rawSeconds - Timestamp string in seconds
 * @param {Object} [options] - Options
 * @param {boolean} [options.withTime=true] - Return date with time
 * @return {HumanRelativeDateTimeResult}
 * */
export function getHumanRelativeDateTime(rawSeconds, options) {
  const {
    withTime = true,
  } = options || {};
  const shortFormat = withTime
    ? 'LLL d, t'
    : 'LLL d';
  const longFormat = withTime
    ? 'LLL d, yyyy, t'
    : 'LLL d, yyyy';
  let relativeness = '';
  let dateTime = '';
  if (!rawSeconds) {
    return {
      relativeness,
      dateTime,
    };
  }
  const date = DateTime.fromSeconds(+rawSeconds);
  if (date.invalid) {
    return {
      relativeness,
      dateTime,
    };
  }
  const { days } = date.diffNow('days');
  const year = DateTime.local().year;
  if (days <= -2) {
    relativeness = 'behind';
    dateTime = date.year === year
      ? date.toFormat(shortFormat)
      : date.toFormat(longFormat);
  }
  else if (days <= -1 && days > -2) {
    relativeness = 'behind';
    dateTime = date.toRelativeCalendar();
  }
  else if (days < 0 && days > -1) {
    relativeness = 'behindIn24h';
    dateTime = withTime
      ? date.toRelative()
      : date.toRelativeCalendar();
  }
  else if (days > 0 && days < 1) {
    relativeness = 'aheadIn24h';
    dateTime = withTime
      ? date.toRelative()
      : date.toRelativeCalendar();
  }
  else if (days >= 1 && days < 2) {
    relativeness = 'ahead';
    dateTime = date.toRelativeCalendar();
  }
  else if (days >= 2) {
    relativeness = 'ahead';
    dateTime = date.year === year
      ? date.toFormat(shortFormat)
      : date.toFormat(longFormat);
  }
  return {
    relativeness,
    dateTime,
  };
}

/**
 * Returns human-readable relative date/time
 * @param {string} rawSeconds - Timestamp string in seconds
 * @return {string} - Human-readable time or date string
 * */
export function getHumanRelativeTimeOrDate(rawSeconds) {
  if (!rawSeconds) {
    return '';
  }
  const date = DateTime.fromSeconds(+rawSeconds);
  if (date.invalid) {
    return '';
  }
  if (Math.abs(date.diffNow('minutes').minutes) < 1) {
    return 'Just now';
  }
  if (Math.abs(date.diffNow('minutes').minutes) < 60) {
    return date.toRelative({ unit: 'minutes' });
  }
  if (Math.abs(date.diffNow('hours').hours) < 24) {
    return date.toRelative({ unit: 'hours' });
  }
  if (Math.abs(date.diffNow('days').days) < 2) {
    return date.toRelativeCalendar({ unit: 'days' });
  }
  if (date.year === DateTime.local().year) {
    return date.toFormat('LLL d');
  }
  return date.toFormat('LLL d, yyyy');
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { actions as notificationActions, showError } from '~/store/reducers/notification';

/**
 * React hook, shows error notifications for server responses
 * @return {NotificationsShow}
* */
export function useNotificationsShow() {
  const dispatch = useDispatch();

  const showNotification = React.useCallback((notificationData) => {
    notificationActions.add(notificationData)(dispatch);
  }, [
    dispatch,
  ]);
  const showResponseError = React.useCallback((response) => {
    showError(response, dispatch);
  }, [
    dispatch,
  ]);
  const showInfo = React.useCallback((message) => {
    notificationActions.showInfo(message)(dispatch);
  }, [
    dispatch,
  ]);
  const showWarning = React.useCallback((message) => {
    notificationActions.showWarning(message)(dispatch);
  }, [
    dispatch,
  ]);

  return {
    showNotification,
    showInfo,
    showWarning,
    showResponseError,
  };
}

/**
 * @typedef {Object} NotificationData
 * @property {'info'|'warning'|'error'|'success'} type
 * @property {boolean} [showCloseButton=true]
 * @property {boolean} [autoClose=true]
 * @property {string} [title='']
 * @property {string} [text='']
 * @property {null|Function} [renderContent=null]
 * */

/**
 * Function to show custom notification
 * @typedef {Function} ShowNotification
 * @param {NotificationData} notificationData
 * @return {void}
 * */

/**
 * Function to show information notification
 * @typedef {Function} ShowInfo
 * @param {string} message - Message to show
 * @return {void}
 * */

/**
 * Function to show warning notification
 * @typedef {Function} ShowWarning
 * @param {string} message - Message to show
 * @return {void}
 * */

/**
 * Function to show response error notification
 * @typedef {Function} ShowResponseError
 * @param {Object} response - Server response
 * @return {void}
 * */

/**
 * @typedef {Object} NotificationsShow
 * @property {ShowNotification} showNotification
 * @property {ShowInfo} showInfo
 * @property {ShowWarning} showWarning
 * @property {ShowResponseError} showResponseError
 * */

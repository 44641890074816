import { ARTICLES_LIST_COLUMNS } from '~/assets/helpers/documents';

export const KANBAN_READY_STATE = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  READY: 'READY',
  RE_FETCHING: 'RE_FETCHING',
  FILTERING: 'FILTERING',
  PAGING: 'PAGING',
};

export const KANBAN_COLUMN_LENGTH = 5;

export const KANBAN_COLUMNS = {
  NEWS: 'news',
  NEEDS_ORIGINAL_ARTWORK: 'needOriginalArtwork',
  OTHER: 'other',
  PENDING_APPROVAL: 'pendingApproval',
  ARTWORK_REJECTED: 'artworkRejected',
  ARTWORK_APPROVED: 'artworkApproved',
  PUBLISHED: 'published',
};

export const KANBAN_COLUMNS_ORDER = [
  KANBAN_COLUMNS.NEWS,
  KANBAN_COLUMNS.NEEDS_ORIGINAL_ARTWORK,
  KANBAN_COLUMNS.OTHER,
  KANBAN_COLUMNS.PENDING_APPROVAL,
  KANBAN_COLUMNS.ARTWORK_REJECTED,
  KANBAN_COLUMNS.ARTWORK_APPROVED,
  KANBAN_COLUMNS.PUBLISHED,
];

export const KANBAN_COLUMNS_LABELS = {
  [KANBAN_COLUMNS.NEWS]: 'News',
  [KANBAN_COLUMNS.NEEDS_ORIGINAL_ARTWORK]: 'Needs original artwork',
  [KANBAN_COLUMNS.OTHER]: 'Other',
  [KANBAN_COLUMNS.PENDING_APPROVAL]: 'Pending approval',
  [KANBAN_COLUMNS.ARTWORK_REJECTED]: 'Artwork rejected',
  [KANBAN_COLUMNS.ARTWORK_APPROVED]: 'Artwork approved',
  [KANBAN_COLUMNS.PUBLISHED]: 'Published',
};

export const KANBAN_COLUMNS_SORTABLE_BY = [
  ARTICLES_LIST_COLUMNS.CREATED,
  ARTICLES_LIST_COLUMNS.UPDATED_STATUS,
  ARTICLES_LIST_COLUMNS.DEADLINE,
];

export const KANBAN_FLAGS = {
  NEWS: 'news',
  NEEDS_ORIGINAL_ARTWORK: 'need_original_artwork',
  OTHER: 'other',
};

export const KANBAN_FLAG_LABELS = {
  [KANBAN_FLAGS.NEWS]: 'News',
  [KANBAN_FLAGS.NEEDS_ORIGINAL_ARTWORK]: 'Needs original artwork',
  [KANBAN_FLAGS.OTHER]: 'Other',
};

export const KANBAN_DEFAULT_COLUMNS_FILTERS = {
  [KANBAN_COLUMNS.NEWS]: {
    [ARTICLES_LIST_COLUMNS.EDITOR]: '',
  },
  [KANBAN_COLUMNS.NEEDS_ORIGINAL_ARTWORK]: {
    [ARTICLES_LIST_COLUMNS.EDITOR]: '',
  },
  [KANBAN_COLUMNS.OTHER]: {
    [ARTICLES_LIST_COLUMNS.EDITOR]: '',
  },
  [KANBAN_COLUMNS.PENDING_APPROVAL]: {
    [ARTICLES_LIST_COLUMNS.EDITOR]: '',
    [ARTICLES_LIST_COLUMNS.FLAGS]: '',
    [ARTICLES_LIST_COLUMNS.COVER_UPDATER]: '',
  },
  [KANBAN_COLUMNS.ARTWORK_APPROVED]: {
    [ARTICLES_LIST_COLUMNS.EDITOR]: '',
    [ARTICLES_LIST_COLUMNS.FLAGS]: '',
    [ARTICLES_LIST_COLUMNS.COVER_UPDATER]: '',
  },
  [KANBAN_COLUMNS.ARTWORK_REJECTED]: {
    [ARTICLES_LIST_COLUMNS.EDITOR]: '',
    [ARTICLES_LIST_COLUMNS.FLAGS]: '',
    [ARTICLES_LIST_COLUMNS.COVER_UPDATER]: '',
  },
  [KANBAN_COLUMNS.PUBLISHED]: {
    [ARTICLES_LIST_COLUMNS.EDITOR]: '',
    [ARTICLES_LIST_COLUMNS.FLAGS]: '',
    [ARTICLES_LIST_COLUMNS.COVER_UPDATER]: '',
  },
};

export const KANBAN_FILTERS_ORDER = [
  ARTICLES_LIST_COLUMNS.EDITOR,
  ARTICLES_LIST_COLUMNS.COVER_UPDATER,
  ARTICLES_LIST_COLUMNS.FLAGS,
];

export const KANBAN_DEFAULT_SORTING = {
  sortBy: ARTICLES_LIST_COLUMNS.UPDATED_STATUS,
  sortType: 'DESC',
};

export const KANBAN_DEFAULT_PAGING = {
  offset: 0,
  length: KANBAN_COLUMN_LENGTH,
};

export function getKanbanQueryPayload(query) {
  const payload = {};
  if (query.column) {
    payload.column = query.column;
  }
  if (query.editor) {
    payload.editor = query.editor;
  }
  if (query.flags) {
    payload.flags = query.flags;
  }
  if (query.category) {
    payload.category = query.category;
  }
  if (query.coverUpdater) {
    payload.titleImageUpdatedBy = query.coverUpdater;
  }
  if (query.sortBy) {
    payload.order = query.sortBy;
  }
  if (query.sortType) {
    payload.orderType = query.sortType;
  }
  if (query.length) {
    payload.limit = query.length;
  }
  if (query.offset) {
    payload.skip = query.offset;
  }
  return payload;
}

import * as Sentry from '@sentry/browser';
import {
  ENV_SLUG,
  IS_PRODUCTION,
  SENTRY_DSN,
  SENTRY_RELEASE,
  ERROR_MESSAGE,
} from '~/app.config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV_SLUG,
  release: SENTRY_RELEASE,
  debug: !IS_PRODUCTION,
  beforeSend(event) {
    if (!IS_PRODUCTION) {
      console.warn(event);
      return null;
    }
    return event;
  },
});

export const report = (options = {}) => {
  const {
    tag = 'untagged',
    error = {},
    withScope = null,
  } = options;
  if (!IS_PRODUCTION) {
    return;
  }
  const { message = ERROR_MESSAGE } = error;
  Sentry.withScope((scope) => {
    if (typeof withScope === 'function') {
      withScope(scope);
    }
    scope.setExtra('error', error);
    scope.setTag('tag', tag);
    Sentry.captureMessage(
      `[${tag}]: ${message}`,
      Sentry.Severity.Error,
    );
  });
};

import React from 'react';
import { createSharedDataHook } from '~/assets/hooks/useSharedData';
import { useLastScroll } from '~/assets/hooks/useLastScroll';

const useSharedData = createSharedDataHook(false);

/**
 * @typedef {boolean} isMenuOpened
 * */

/**
 * @typedef {Function} setIsMenuOpened
 * @param {boolean} isMenuOpened
 * @return {void}
 * */

/**
 * Menu hook
 * @return {[isMenuOpened, setIsMenuOpened]}
 * */
export function useMenu() {
  const [opened, setOpened] = useSharedData();
  const [, setLastScroll] = useLastScroll();

  const toggle = React.useCallback((nextValue) => {
    setOpened((prevOpened) => {
      const nextOpened = typeof nextValue === 'function'
        ? nextValue(prevOpened)
        : nextValue;
      if (nextOpened) {
        setLastScroll();
      }
      return nextOpened;
    });
  }, [
    setOpened,
    setLastScroll,
  ]);

  return [
    opened,
    toggle,
  ];
}

import React from 'react';

const DEFAULT_TITLE = 'Editorial.Space';

export function useTitle(options) {
  const {
    title = '',
    replace = false,
  } = options || {};

  React.useEffect(() => {
    let nextTitle = DEFAULT_TITLE;
    if (replace && title) {
      nextTitle = title;
    }
    else if (title) {
      nextTitle = `${title} - ${DEFAULT_TITLE}`;
    }
    window.document.title = nextTitle;
    return () => {
      window.document.title = DEFAULT_TITLE;
    };
  }, [
    title,
    replace,
  ]);
}

import { stringify } from 'query-string';
import { API_URL, COLLABOK_URL, ERROR_MESSAGE } from '~/app.config';
import createFetch from '~/assets/libs/createFetch';
import getFormData from '~/assets/libs/getFormData';
import { getAccessTokenHeader } from '~/assets/helpers/token';
import getResponseJson from '~/assets/libs/getResponseJson';
import {
  getDocumentPayload,
  getDocumentCreatePayload,
  getArticlesQueryPayload,
  getBylineAuthorCreatePayload,
} from '~/assets/helpers/documents';
import {
  getKanbanQueryPayload,
} from '~/assets/helpers/kanban';
import {
  getBoxesPayload,
  getImageUsagePayload,
  getBoxesReorderPayload,
} from '~/assets/helpers/boxes';
import {
  mapAuthor,
  mapAuthors,
  mapCategories,
  mapDocument,
  mapDocuments,
  mapArticlesKanbanColumn,
  mapLabels,
  mapBoxTemplates,
} from '~/assets/helpers/mappers';

export const fetchDocumentsList = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Articles obtaining failed',
    errors: '',
    data: {
      documents: [],
      count: 0,
    },
  };
  const { query: queryData } = payload;
  const queryPayload = getArticlesQueryPayload(queryData);
  const query = stringify(queryPayload, { arrayFormat: 'bracket' });
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents?${query}`, {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    const data = await getResponseJson(raw);
    if (!raw.ok) {
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapDocuments(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const fetchArticlesKanbanColumn = async (payload) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Articles obtaining failed',
    errors: '',
    data: mapArticlesKanbanColumn(null),
  };
  try {
    const queryPayload = getKanbanQueryPayload(payload.query);
    const query = stringify(queryPayload, { arrayFormat: 'bracket' });
    const fetchTransport = createFetch(API_URL);
    const raw = await fetchTransport(`/documents/kanban?${query}`, {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    const data = await getResponseJson(raw);
    if (!raw.ok) {
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errorsTitle = '';
    response.errors = '';
    response.data = mapArticlesKanbanColumn(data);
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const fetchDocument = async (payload = {}, options = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Article obtaining failed',
    errors: '',
    data: null,
  };
  const { documentId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}`, {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    const data = await getResponseJson(raw);
    if (!raw.ok) {
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapDocument(data, options);
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const createDocument = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Article creation failed',
    errors: '',
    data: {
      id: '',
    },
  };

  const { documentData } = payload;
  const formData = getFormData(getDocumentCreatePayload(documentData));
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/documents', {
      method: 'POST',
      headers: getAccessTokenHeader(),
      body: formData,
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data.id = raw.headers.get('X-Created-Resource-Id');
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const updateDocument = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Article update failed',
    errors: 'Your changes weren\'t saved',
    data: null,
  };
  const { documentId, updateData } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}`, {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getDocumentPayload(updateData)),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const publishDocument = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Article publication failed',
    errors: '',
    data: null,
  };
  const { documentId, publishLater } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/publish`, {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ publish_later: publishLater }),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const unpublishDocument = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Article unpublication failed',
    errors: '',
    data: null,
  };
  const { documentId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/unpublish`, {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
      },
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const rePublishDocument = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Article publication update failed',
    errors: '',
    data: null,
  };
  const { documentId, rePublishData } = payload;
  const { needPublishDateUpdate } = rePublishData;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/republish`, {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ update_published_at: needPublishDateUpdate }),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const updateDocumentState = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Article flow update failed',
    errors: '',
    data: null,
  };
  const { documentId, documentState } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/update_status`, {
      method: 'PUT',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: documentState,
      }),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const assignEditor = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Editor assignment failed',
    errors: '',
    data: null,
  };
  const { documentId, editorId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/assign_editor`, {
      method: 'PUT',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        assigned_editor_id: parseInt(editorId) || null,
      }),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const assignAuthor = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Author assignment failed',
    errors: '',
    data: null,
  };
  const { documentId, authorId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/assign_author`, {
      method: 'PUT',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        assigned_author_id: parseInt(authorId) || null,
      }),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const assignProofreader = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Copy editor assignment failed',
    errors: '',
    data: null,
  };
  const { documentId, proofreaderId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/assign_proofreader`, {
      method: 'PUT',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        assigned_proofreader_id: parseInt(proofreaderId) || null,
      }),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const finishProofreading = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Finish proofreading failed',
    errors: '',
    data: null,
  };
  const { documentId } = payload;
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/done_proofreading`, {
      method: 'PUT',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      const data = await getResponseJson(raw);
      response.code = raw.status;
      response.errors = data.message;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const createBox = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Box creation failed',
    errors: '',
    data: {
      id: '',
    },
  };
  const { boxData } = payload;
  const searchParams = JSON.stringify(getBoxesPayload(boxData));
  const fetchTransport = createFetch(COLLABOK_URL);
  try {
    const raw = await fetchTransport('/boxes', {
      method: 'POST',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: searchParams,
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const boxId = raw.headers.get('X-Created-Resource-Id');
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data.id = boxId;
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const updateBox = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Box update failed',
    errors: '',
    data: null,
  };
  const { boxData } = payload;
  const fetchTransport = createFetch(COLLABOK_URL);
  try {
    const raw = await fetchTransport(`/boxes/${boxData.id}`, {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getBoxesPayload(boxData)),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const deleteBox = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Box deletion failed',
    errors: '',
    data: null,
  };
  const { boxData } = payload;
  const fetchTransport = createFetch(COLLABOK_URL);
  try {
    const raw = await fetchTransport(`/boxes/${boxData.id}`, {
      method: 'DELETE',
      headers: {
        ...getAccessTokenHeader(),
      },
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const reorderBoxes = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Box re-ordering failed',
    errors: '',
    data: null,
  };
  const { documentId, boxesIds } = payload;
  const fetchTransport = createFetch(COLLABOK_URL);
  try {
    const raw = await fetchTransport(`/documents/${documentId}/boxes/update_position`, {
      method: 'PATCH',
      headers: {
        ...getAccessTokenHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getBoxesReorderPayload(boxesIds)),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const createImageUsage = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Image insertion failed',
    errors: '',
    data: {
      imageId: '',
    },
  };
  const { boxData } = payload;
  const formData = getFormData(getImageUsagePayload(boxData));
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/images/upload', {
      method: 'POST',
      headers: getAccessTokenHeader(),
      body: formData,
    });
    let imageId = '';
    if (raw.ok) {
      response.status = 'success';
      imageId = raw.headers.get('X-Created-Resource-Id');
    }
    else if (raw.status === 409) {
      response.status = 'success';
      imageId = raw.headers.get('X-Existing-Resource-Id');
    }
    else {
      response.status = 'error';
    }
    response.code = raw.status;
    response.errors = '';
    response.data.imageId = imageId;
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const fetchBoxTemplates = async (options = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Box templates obtaining failed',
    errors: '',
    data: [],
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/box_templates', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const data = await getResponseJson(raw);
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapBoxTemplates(data, options);
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const fetchAuthors = async (options = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Byline obtaining failed',
    errors: '',
    data: [],
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const res = await fetchTransport('/authors', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!res.ok) {
      response.code = res.status;
      return response;
    }
    const data = await res.json();
    response.code = res.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapAuthors(data, options);
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const fetchCategories = async (options = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Categories obtaining failed',
    errors: '',
    data: [],
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const res = await fetchTransport('/categories', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!res.ok) {
      response.code = res.status;
      return response;
    }
    const data = await res.json();
    response.code = res.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapCategories(data, options);
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const fetchLabels = async (options = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errorsTitle: 'Labels obtaining failed',
    errors: '',
    data: [],
  };
  const fetchTransport = createFetch(API_URL);
  try {
    const res = await fetchTransport('/labels', {
      method: 'GET',
      headers: getAccessTokenHeader(),
    });
    if (!res.ok) {
      response.code = res.status;
      return response;
    }
    const data = await res.json();
    response.code = res.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapLabels(data, options);
    return response;
  }
  catch (e) {
    console.warn(e);
    return response;
  }
};

export const createBylineAuthor = async (payload = {}) => {
  const response = {
    status: 'error',
    code: 520,
    errors: ERROR_MESSAGE,
    data: {
      id: '',
    },
  };
  const { authorData } = payload;
  const mappedData = getBylineAuthorCreatePayload(authorData);
  const formData = getFormData(mappedData);
  const fetchTransport = createFetch(API_URL);
  try {
    const raw = await fetchTransport('/authors', {
      method: 'POST',
      headers: getAccessTokenHeader(),
      body: formData,
    });
    if (!raw.ok) {
      response.code = raw.status;
      return response;
    }
    const id = raw.headers.get('X-Created-Resource-Id');
    response.code = raw.status;
    response.status = 'success';
    response.errors = '';
    response.data = mapAuthor({
      ...mappedData,
      id,
    });
    return response;
  }
  catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};

/**
 * @template T
 */
export class UndoRedoHistory {
  /**
   * @type {number}
   * @private
   * @readonly
   */
  _limit;

  /**
   * @type {T[]}
   * @private
   */
  _states = [];

  /**
   * @type {number}
   * @private
   */
  _position = -1;

  /**
   * @param {number} limit Max state count to keep in history
   */
  constructor(limit) {
    this._limit = limit;
  }

  /**
   * History on initial state, nothing pushed
   *
   * @return {boolean}
   */
  hasPushed() {
    return this._states.length > 1;
  }

  /**
   * @return {boolean}
   */
  canUndo() {
    return this._position > 0;
  }

  /**
   * @return {boolean}
   */
  canRedo() {
    return this._position < (this._states.length - 1);
  }

  /**
   * Reset history to single item
   * @param {T} state
   */
  reset(state) {
    this._states = [state];
    this._position = 0;
  }

  /**
   * @return {T}
   */
  current() {
    if (this._states.length === 0) throw new Error('empty undo/redo state');

    const state = this._states[this._position];

    if (typeof state === 'undefined') throw new Error('invalid history state');

    return state;
  }

  /**
   * @param {T} state
   * @return {void}
   */
  push(state) {
    this.forgetRedo();

    // append to history
    this._states.push(state);
    this._position += 1;

    // cut off first items
    const overflow = this._states.length - this._limit;
    if (overflow) this._states = this._states.slice(overflow);
  }

  /**
   * @param {T} state
   * @return {void}
   */
  replace(state) {
    this._states[this._position] = state;
  }

  /**
   * @return {void}
   */
  undo() {
    if (!this.canUndo()) return;

    this._position -= 1;
  }

  /**
   * @return {void}
   */
  redo() {
    if (!this.canRedo()) return;

    this._position += 1;
  }

  forgetRedo() {
    if (this.canRedo()) this._states = this._states.slice(0, this._position + 1);
  }
}

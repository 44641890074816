import isEqual from 'lodash/isEqual';

export function getModel() {
  let state = {};
  return {
    get: () => {
      return get(state);
    },
    getValues: () => {
      return getValues(state);
    },
    getChangedValues: () => {
      return getChangedValues(state);
    },
    set: (input) => {
      state = set(input);
    },
    setValidators: (input) => {
      state = setValidators(state, input);
    },
    setValues: (input) => {
      state = setValues(state, input);
    },
    updateValues: (input) => {
      state = updateValues(state, input);
    },
    deleteValues: (input) => {
      state = deleteValues(state, input);
    },
    touch: (input) => {
      state = touch(state, input);
    },
    untouch: (input) => {
      state = untouch(state, input);
    },
  };
}

export function get(prevState) {
  const nextState = {
    anyInvalid: false,
    anyChanged: false,
    anyDirty: false,
    anyError: false,
  };
  return Object
    .entries(prevState)
    .reduce((state, [name, data]) => {
      const {
        value,
        changed,
        dirty,
        validators,
      } = data;
      const {
        invalid,
        validations,
      } = getValidations(value, validators);
      const error = invalid && dirty;
      state[name] = {
        value,
        invalid,
        changed,
        dirty,
        error,
        validations,
      };
      if (!state.anyInvalid && invalid) {
        state.anyInvalid = true;
      }
      if (!state.anyChanged && changed) {
        state.anyChanged = true;
      }
      if (!state.anyDirty && dirty) {
        state.anyDirty = true;
      }
      if (!state.anyError && error) {
        state.anyError = true;
      }
      return state;
    }, nextState);
}

export function getValues(prevState) {
  return Object
    .entries(prevState)
    .reduce((values, [name, data]) => {
      values[name] = data.value;
      return values;
    }, {});
}

export function getChangedValues(prevState) {
  return Object
    .entries(prevState)
    .reduce((values, [name, data]) => {
      if (data.changed) {
        values[name] = data.value;
      }
      return values;
    }, {});
}

export function set(input) {
  return Object
    .entries(input)
    .reduce((state, [name, data]) => {
      const initialValue = getValueFromInput(data);
      const validators = getValidatorsFromInput(data);
      state[name] = {
        initialValue,
        value: initialValue,
        invalid: false,
        changed: false,
        dirty: false,
        error: false,
        validators,
      };
      return state;
    }, {});
}

export function setValidators(prevState, input) {
  return Object
    .entries(prevState)
    .reduce((state, [name, data]) => {
      const nextData = input[name];
      const nextValidators = getValidatorsFromInput(nextData);
      state[name] = {
        ...data,
        validators: nextValidators,
      };
      return state;
    }, {});
}

export function setValues(prevState, input) {
  const nextState = Object
    .entries(prevState)
    .reduce((state, [name, data]) => {
      if (input[name] !== undefined) {
        const nextData = input[name];
        const nextInitialValue = getValueFromInput(nextData);
        const changed = !isEqual(data.value, nextInitialValue);
        const value = changed
          ? data.value
          : nextInitialValue;
        state[name] = {
          ...data,
          initialValue: nextInitialValue,
          value,
          changed,
        };
      }
      else {
        state[name] = data;
      }
      return state;
    }, {});
  return Object
    .entries(input)
    .filter(([name]) => nextState[name] === undefined)
    .reduce((state, [name, data]) => {
      const initialValue = getValueFromInput(data);
      const validators = getValidatorsFromInput(data);
      state[name] = {
        initialValue,
        value: initialValue,
        invalid: false,
        changed: false,
        dirty: false,
        error: false,
        validators,
      };
      return state;
    }, nextState);
}

export function deleteValues(prevState, input) {
  return Object
    .entries(prevState)
    .reduce((state, [name, data]) => {
      if (input[name] !== undefined) {
        delete state[name];
      }
      else {
        state[name] = data;
      }
      return state;
    }, {});
}

export function updateValues(prevState, input) {
  return Object
    .entries(prevState)
    .reduce((state, [name, data]) => {
      if (input[name] !== undefined) {
        const nextData = input[name];
        const nextValue = getValueFromInput(nextData);
        const changed = !isEqual(data.initialValue, nextValue);
        state[name] = {
          ...data,
          value: nextValue,
          changed,
          dirty: true,
        };
      }
      else {
        state[name] = data;
      }
      return state;
    }, {});
}

export function touch(prevState, input) {
  return Object
    .entries(prevState)
    .reduce((state, [name, data]) => {
      const dirty = input === undefined || input[name] !== undefined;
      state[name] = {
        ...data,
        dirty,
      };
      return state;
    }, {});
}

export function untouch(prevState, input) {
  return Object
    .entries(prevState)
    .reduce((state, [name, data]) => {
      const dirty = !(input === undefined || input[name] !== undefined);
      state[name] = {
        ...data,
        dirty,
      };
      return state;
    }, {});
}

function getValidations(value, validators) {
  const defaultValidations = {
    invalid: false,
    validations: {},
  };
  if (!validators || typeof validators !== 'object') {
    return defaultValidations;
  }
  return Object
    .entries(validators)
    .filter(([, validator]) => typeof validator === 'function')
    .reduce((result, [name, validator]) => {
      const invalid = validator(value);
      result.validations[name] = invalid;
      if (!result.invalid && invalid) {
        result.invalid = true;
      }
      return result;
    }, defaultValidations);
}

function getValueFromInput(input) {
  return input.value !== undefined
    ? input.value
    : '';
}

function getValidatorsFromInput(input) {
  return input.validators && typeof input.validators === 'object'
    ? input.validators
    : {};
}

import React from 'react';
import propTypes from 'prop-types';
import { report } from '~/assets/helpers/sentry';
import s from './styles.scss';

const ERROR_TYPES = {
  OFFLINE: 'OFFLINE',
  CHUNK_LOADING: 'CHUNK_LOADING',
  RUNTIME: 'RUNTIME',
};

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: propTypes.node,
  };
  static getDerivedStateFromError(error) {
    return {
      errorType: ErrorBoundary.getErrorType(error),
    };
  }
  static getErrorType(error) {
    if (!window.navigator.onLine) {
      return ERROR_TYPES.OFFLINE;
    }
    if (error.toString().includes('ChunkLoadError')) {
      return ERROR_TYPES.CHUNK_LOADING;
    }
    return ERROR_TYPES.RUNTIME;
  }
  constructor(props) {
    super(props);
    this.state = {
      errorType: '',
    };
  }
  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    console.warn(error);
    report({
      tag: 'componentDidCatch',
      error,
      withScope: (scope) => {
        scope.setExtras(errorInfo);
      },
    });
  }
  render() {
    const { children } = this.props;
    const { errorType } = this.state;

    if (!errorType) {
      return children;
    }

    let title;
    let message;
    switch (errorType) {
      case ERROR_TYPES.OFFLINE:
        title = 'Your are offline 🔴';
        message = 'Your device seems to be offline or using slow internet connection or "lie-fi".';
        break;
      case ERROR_TYPES.CHUNK_LOADING:
        title = 'Application might be stale 🏚';
        message = 'You tried navigating to/loading a page/resource, that is no longer available.';
        break;
      case ERROR_TYPES.RUNTIME:
      default:
        title = 'Something went wrong 😢';
        break;
    }
    return (
      <div className={s.errorBoundary}>
        <div className={s.wrapper}>
          {title
          && <h1 className={s.title}>
            {title}
          </h1>}
          {message
          && <p className={s.message}>
            {message}
          </p>}
          <a
            className={s.reloadLink}
            href={window.location.href}
          >
            <span className="btn__wrp">
              <span className="btn__label">
                Please, reload the page.
              </span>
            </span>
          </a>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;

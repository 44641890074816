import { matchPath } from 'react-router-dom';

export const ROUTES = {
  dashboard: '/',
  signInPage: '/signin',
  signOutPage: '/logout',
  invitePage: '/invite/:hash',
  resetPasswordPage: '/password_reset/:hash',
  articlesList: '/articles',
  documentPage: '/articles/:id',
  // editorsChoicePage: '/editors-choice',
  // relatedNewsPage: '/related-news',
  media: '/media',
  image: '/media/:id',
  usersPage: '/users',
  userPage: '/users/:id',
  userEditPage: '/users/:id/edit',
  profilePage: '/profile',
  profileEditPage: '/profile/edit',
};

export const ROUTES_PERMISSIONS = {
  [ROUTES.dashboard]: ['dashboard', ['list']],
  [ROUTES.articlesList]: ['tasks', ['list']],
  [ROUTES.documentPage]: ['documents', ['view']],
  [ROUTES.media]: ['gallery_images', ['list']],
  [ROUTES.image]: ['gallery_images', ['view']],
  [ROUTES.usersPage]: ['users', ['list']],
  [ROUTES.userPage]: ['users', ['view']],
  [ROUTES.userEditPage]: ['users', ['update']],
  [ROUTES.profilePage]: ['profile', ['view']],
  [ROUTES.profileEditPage]: ['profile', ['edit']],
  [ROUTES.media]: ['gallery_images', ['list']],
  // [ROUTES.editorsChoicePage]: ['editors_choice', ['list']],
  // [ROUTES.relatedNewsPage]: ['related_news', ['list']],
};

const PUBLIC_ROUTES = [
  ROUTES.signOutPage,
  ROUTES.signInPage,
  ROUTES.invitePage,
  ROUTES.resetPasswordPage,
];

/**
 * @param {string} pathname
 * @return {boolean}
 * */
export function isPathnamePublic(pathname = '') {
  const index = PUBLIC_ROUTES.findIndex((routePath) => matchPath(pathname, {
    path: routePath,
    exact: true,
  }));
  return index !== -1;
}

import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { actions as notificationActions } from '~/store/reducers/notification';
import Notification from '~/components/Shared/Notifications/Notification';
import s from './styles.scss';

const mapStateToProps = (state) => ({
  notificationList: state.notification.list,
});

const mapActionsToProps = {
  deleteNotification: (payload) => notificationActions.delete(payload),
};

NotificationContainer.propTypes = {
  notificationList: propTypes.array,
  deleteNotification: propTypes.func,
};

NotificationContainer.defaultProps = {
  notificationList: [],
  deleteNotification: null,
};

function NotificationContainer(props) {
  const {
    notificationList,
    deleteNotification,
  } = props;
  const anythingToShow = notificationList.length > 0;

  const handleDelete = React.useCallback((key) => {
    if (typeof deleteNotification === 'function') {
      deleteNotification(key);
    }
  }, [
    deleteNotification,
  ]);

  const list = React.useMemo(() => {
    return notificationList.map((item, index, array) => ({
      ...item,
      isLast: index === array.length - 1,
      onDelete: () => handleDelete(item.key),
    }));
  }, [
    notificationList,
    handleDelete,
  ]);

  return (
    <div className={cn(s.notificationContainer, anythingToShow && s.shown)}>
      <ul className={s.list}>
        {list.map((item) => (
          <li
            className={s.item}
            key={item.key}
          >
            <Notification
              isLast={item.isLast}
              showCloseButton={item.showCloseButton}
              autoClose={item.autoClose}
              type={item.type}
              title={item.title}
              text={item.text}
              renderContent={item.renderContent}
              onDelete={item.onDelete}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default connect(mapStateToProps, mapActionsToProps)(NotificationContainer);

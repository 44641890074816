import {
  fetchThreads,
  addThread,
  resolveThread,
  deleteThread,
  updateThreadPosition,
  addComment,
  editComment,
  deleteComment,
} from '~/api/comments';
import { COMMENTS } from '~/store/reducers/types';
import { showError } from '~/store/reducers/notification';

export const initialState = {
  activeThread: null,
  threads: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS.MUTATE_ACTIVE_THREAD:
      return {
        ...state,
        activeThread: action.payload,
      };
    case COMMENTS.MUTATE_THREADS:
      return {
        ...state,
        threads: action.payload,
      };
    case COMMENTS.ADD_THREAD:
      return {
        ...state,
        threads: state.threads.concat([action.payload]),
      };
    case COMMENTS.UPDATE_THREAD:
      return {
        ...state,
        threads: state.threads.map((item) => {
          if (item.threadKey === action.payload.threadKey) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      };
    case COMMENTS.DELETE_THREAD:
      return {
        ...state,
        threads: state.threads.filter((item) => item.threadKey !== action.payload.threadKey),
      };
    case COMMENTS.ADD_COMMENT:
      return {
        ...state,
        threads: state.threads.map((thread) => {
          if (thread.id === action.payload.threadId) {
            return {
              ...thread,
              comments: thread.comments.concat([action.payload]),
            };
          }
          return thread;
        }),
      };
    case COMMENTS.UPDATE_COMMENT:
      return {
        ...state,
        threads: state.threads.map((thread) => {
          if (thread.id === action.payload.threadId) {
            return {
              ...thread,
              comments: thread.comments.map((item) => {
                if (item.commentKey === action.payload.commentKey) {
                  return {
                    ...item,
                    ...action.payload,
                  };
                }
                return item;
              }),
            };
          }
          return thread;
        }),
      };
    case COMMENTS.DELETE_COMMENT:
      return {
        ...state,
        threads: state.threads.map((thread) => {
          if (thread.id === action.payload.threadId) {
            return {
              ...thread,
              comments: thread.comments.filter((item) => item.commentKey !== action.payload.commentKey),
            };
          }
          return thread;
        }),
      };
    default:
      return state;
  }
};

export const actions = {
  clear: () => (dispatch) => {
    dispatch({
      type: COMMENTS.MUTATE_ACTIVE_THREAD,
      payload: null,
    });
    dispatch({
      type: COMMENTS.MUTATE_THREADS,
      payload: [],
    });
  },
  setActiveThread: (activeThread) => (dispatch) => {
    dispatch({
      type: COMMENTS.MUTATE_ACTIVE_THREAD,
      payload: activeThread,
    });
  },
  fetchThreads: (documentId) => async (dispatch) => {
    const response = await fetchThreads({ documentId });
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: COMMENTS.MUTATE_THREADS,
        payload: data,
      });
    }
    return response;
  },
  addThread: (threadData) => async (dispatch) => {
    dispatch({
      type: COMMENTS.ADD_THREAD,
      payload: threadData,
    });
    dispatch({
      type: COMMENTS.MUTATE_ACTIVE_THREAD,
      payload: {
        boxId: threadData.boxId,
        threadKey: threadData.threadKey,
        selectionState: threadData.selectionState,
      },
    });
    const response = await addThread({ threadData });
    const { status, data: { threadId, commentData } } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: COMMENTS.DELETE_THREAD,
        payload: {
          threadKey: threadData.threadKey,
        },
      });
    }
    else if (threadId) {
      dispatch({
        type: COMMENTS.UPDATE_THREAD,
        payload: {
          threadKey: threadData.threadKey,
          id: threadId,
          comments: [{
            ...threadData.comments[0],
            ...commentData,
          }],
        },
      });
    }
    return response;
  },
  resolveThread: (threadData) => async (dispatch) => {
    dispatch({
      type: COMMENTS.UPDATE_THREAD,
      payload: {
        threadKey: threadData.threadKey,
        isResolved: true,
      },
    });
    const response = await resolveThread({ threadData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: COMMENTS.UPDATE_THREAD,
        payload: {
          threadKey: threadData.threadKey,
          isResolved: false,
        },
      });
    }
    return response;
  },
  deleteThread: (threadData) => async (dispatch, getState) => {
    const { comments: { threads } } = getState();
    const prevThreadData = threads.find((item) => item.threadKey === threadData.threadKey);
    const nextThreadData = {
      ...prevThreadData,
      ...threadData,
    };
    dispatch({
      type: COMMENTS.DELETE_THREAD,
      payload: nextThreadData,
    });
    const response = await deleteThread({
      threadData: nextThreadData,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      if (prevThreadData) {
        dispatch({
          type: COMMENTS.ADD_THREAD,
          payload: prevThreadData,
        });
      }
    }
    return response;
  },
  updateThreadPosition: (threadData) => async (dispatch, getState) => {
    const { comments: { threads } } = getState();
    const prevThreadData = threads.find((item) => item.threadKey === threadData.threadKey);
    const nextThreadData = {
      ...prevThreadData,
      ...threadData,
    };
    dispatch({
      type: COMMENTS.UPDATE_THREAD,
      payload: nextThreadData,
    });
    const response = await updateThreadPosition({
      threadData: nextThreadData,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      if (prevThreadData) {
        dispatch({
          type: COMMENTS.UPDATE_THREAD,
          payload: prevThreadData,
        });
      }
    }
    return response;
  },
  addComment: (commentData) => async (dispatch) => {
    dispatch({
      type: COMMENTS.ADD_COMMENT,
      payload: commentData,
    });
    const response = await addComment({ commentData });
    const { status, data: { commentId } } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: COMMENTS.DELETE_COMMENT,
        payload: commentData,
      });
    }
    else if (commentId) {
      dispatch({
        type: COMMENTS.UPDATE_COMMENT,
        payload: {
          ...commentData,
          id: commentId,
        },
      });
    }
    return response;
  },
  editComment: (commentData) => async (dispatch, getState) => {
    const { comments: { threads } } = getState();
    const prevThreadData = threads.find((item) => item.id === commentData.threadId);
    const prevCommentData = prevThreadData && prevThreadData.comments
      ? prevThreadData.comments.find((item) => item.id === commentData.id)
      : null;
    dispatch({
      type: COMMENTS.UPDATE_COMMENT,
      payload: commentData,
    });
    const response = await editComment({ commentData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      if (prevCommentData) {
        dispatch({
          type: COMMENTS.UPDATE_COMMENT,
          payload: prevCommentData,
        });
      }
    }
    return response;
  },
  deleteComment: (commentData) => async (dispatch, getState) => {
    const { comments: { threads } } = getState();
    const prevThreadData = threads.find((item) => item.id === commentData.threadId);
    const prevCommentData = prevThreadData && prevThreadData.comments
      ? prevThreadData.comments.find((item) => item.id === commentData.id)
      : null;
    dispatch({
      type: COMMENTS.DELETE_COMMENT,
      payload: commentData,
    });
    const response = await deleteComment({ commentData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
      dispatch({
        type: COMMENTS.ADD_COMMENT,
        payload: prevCommentData,
      });
    }
    return response;
  },
};

import { buildXPathToTarget, findTargetByXPath } from '~/assets/libs/BoxManager/utils';

export class DOMSelectionRange {
  /**
   * @param {Node} root
   */
  static capture(root) {
    const range = document.getSelection().getRangeAt(0);

    return new this(buildXPathToTarget(root, range.startContainer), range.startOffset, buildXPathToTarget(root, range.endContainer), range.endOffset);
  }

  /**
   * @type {string}
   * @private
   * @readonly
   */
  _startContainerXPath;

  /**
   * @type {number}
   * @private
   * @readonly
   */
  _startOffset;

  /**
   * @type {string}
   * @private
   * @readonly
   */
  _endContainerXPath;

  /**
   * @type {number}
   * @private
   * @readonly
   */
  _endOffset;

  /**
   * Create a new DOMSelectionRange instance.
   *
   * @param {string} startContainerXPath - The XPath of the starting container.
   * @param {number} startOffset - The offset within the starting container.
   * @param {string} endContainerXPath - The XPath of the ending container.
   * @param {number} endOffset - The offset within the ending container.
   */
  constructor(startContainerXPath, startOffset, endContainerXPath, endOffset) {
    this._startContainerXPath = startContainerXPath;
    this._startOffset = startOffset;
    this._endContainerXPath = endContainerXPath;
    this._endOffset = endOffset;
  }

  /**
   * @param {DOMSelectionRange} another
   * @return {boolean}
   */
  equals(another) {
    return (
      this._startOffset === another._startOffset
      && this._endOffset === another._endOffset
      && this._startContainerXPath === another._startContainerXPath
      && this._endContainerXPath === another._endContainerXPath
    );
  }

  /**
   * Apply selection to DOM
   *
   * @param {Node} root
   * @return {void}
   */
  apply(root) {
    const selection = window.getSelection();
    const range = document.createRange();
    const startContainer = findTargetByXPath(root, this._startContainerXPath);
    const endContainer = findTargetByXPath(root, this._endContainerXPath);

    try {
      range.setStart(startContainer, this._startOffset);
      range.setEnd(endContainer, this._endOffset);

      selection.removeAllRanges();
      selection.addRange(range);
    }
    catch (error) {
      console.error('Failed to apply selection range:', error);
    }
  }
}

require('dotenv').config();

const RELEASE_VERSION = process.env.SENTRY_RELEASE;
const ENV_SLUG = process.env.ENV_SLUG;
const NODE_ENV = process.env.NODE_ENV;
const PORT = process.env.PORT;
const HOST = process.env.HOST;
const IS_PRODUCTION = NODE_ENV === 'production';
const API_URL = process.env.API_URL;
const SENTRY_DSN = process.env.SENTRY_DSN;
const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
const ERROR_MESSAGE = 'An unexpected error occurred';
const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY;
const PUSH_VAPID_KEY = process.env.PUSH_VAPID_KEY;
const HERZOG_URL = process.env.HERZOG_URL || 'https://stage.cointelegraph.com';
const COLLABOK_URL = process.env.COLLABOK_URL || 'https://collabok.stage.cointelegraph.com';
const STORAGE_PREFIX = 'COCKROACH';
const DEFAULT_LOCALE = 'en-US';

module.exports = {
  RELEASE_VERSION,
  ENV_SLUG,
  NODE_ENV,
  PORT,
  HOST,
  IS_PRODUCTION,
  API_URL,
  HERZOG_URL,
  COLLABOK_URL,
  SENTRY_DSN,
  SENTRY_RELEASE,
  ERROR_MESSAGE,
  FIREBASE_API_KEY,
  PUSH_VAPID_KEY,
  STORAGE_PREFIX,
  DEFAULT_LOCALE,
};

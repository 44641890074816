import { getHumanDate } from '~/assets/libs/getHumanDate';
import { USERS_INNER_STATE } from '~/assets/helpers/users';
import {
  fetchInvites,
  createInvite,
  revokeInvite,
  fetchUsers,
  fetchUser,
  createUser,
  updateUser,
  enableUser,
  disableUser,
  sendPasswordResetLink,
} from '~/api/users';
import { USERS } from '~/store/reducers/types';
import { showError } from '~/store/reducers/notification';

export const initialState = {
  innerState: USERS_INNER_STATE.INITIAL,
  invites: [],
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS.MUTATE_INNER_STATE:
      return {
        ...state,
        innerState: action.payload,
      };
    case USERS.MUTATE_INVITES:
      return {
        ...state,
        invites: action.payload,
      };
    case USERS.MUTATE_USERS:
      return {
        ...state,
        list: action.payload,
      };
    case USERS.MUTATE_USER: {
      const doesExist = state.list.find((item) => item.id === action.payload.id);
      if (doesExist) {
        return {
          ...state,
          list: state.list.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                ...action.payload,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...state,
        list: state.list.concat([action.payload]),
      };
    }
    case USERS.CREATE_INVITE:
      return {
        ...state,
        invites: state.invites.concat([action.payload]),
      };
    case USERS.REVOKE_INVITE:
      return {
        ...state,
        invites: state.invites.filter((item) => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const actions = {
  fetch: () => async (dispatch, getState) => {
    const { users: { innerState } } = getState();
    const isFetchInitial = innerState === USERS_INNER_STATE.INITIAL;
    dispatch({
      type: USERS.MUTATE_INNER_STATE,
      payload: isFetchInitial
        ? USERS_INNER_STATE.FETCHING
        : USERS_INNER_STATE.RE_FETCHING,
    });
    const response = await fetchUsers();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: USERS.MUTATE_USERS,
        payload: data,
      });
    }
    dispatch({
      type: USERS.MUTATE_INNER_STATE,
      payload: USERS_INNER_STATE.FETCHED,
    });
    return response;
  },
  fetchUser: (userId) => async (dispatch) => {
    const response = await fetchUser({ userId });
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: USERS.MUTATE_USER,
        payload: data,
      });
    }
    return response;
  },
  create: (userData) => async (dispatch) => {
    const response = await createUser({ userData });
    const { status, data: { id } } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    if (id) {
      dispatch({
        type: USERS.MUTATE_USER,
        payload: {
          ...userData,
          id,
        },
      });
    }
    return response;
  },
  update: (userData) => async (dispatch) => {
    const response = await updateUser({ userData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: USERS.MUTATE_USER,
        payload: userData,
      });
    }
    return response;
  },
  enable: (userId) => async (dispatch) => {
    const response = await enableUser({ userId });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: USERS.MUTATE_USER,
        payload: {
          id: userId,
          isActive: true,
        },
      });
    }
    return response;
  },
  disable: (userId) => async (dispatch) => {
    const response = await disableUser({ userId });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: USERS.MUTATE_USER,
        payload: {
          id: userId,
          isActive: false,
        },
      });
    }
    return response;
  },
  sendPasswordResetLink: (userId) => async (dispatch) => {
    const response = await sendPasswordResetLink({ userId });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    return response;
  },
  fetchInvites: () => async (dispatch) => {
    const response = await fetchInvites();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: USERS.MUTATE_INVITES,
        payload: data,
      });
    }
    return response;
  },
  createInvite: (inviteData) => async (dispatch, getState) => {
    const { profile: { data: { id: createdBy } } } = getState();
    const createdAt = Math.round(Date.now() / 1000);
    const expiresAt = createdAt + (60 * 60 * 24);
    const dateCreatedAtHuman = getHumanDate(createdAt);
    const response = await createInvite({ inviteData });
    const { status, data: { id } } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    if (id) {
      dispatch({
        type: USERS.CREATE_INVITE,
        payload: {
          ...inviteData,
          id,
          createdAt,
          expiresAt,
          createdBy,
          dateCreatedAtHuman,
        },
      });
    }
    return response;
  },
  revokeInvite: (inviteId) => async (dispatch) => {
    const response = await revokeInvite({ inviteId });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: USERS.REVOKE_INVITE,
        payload: {
          id: inviteId,
        },
      });
    }
    return response;
  },
};

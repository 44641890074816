import {
  hasTokens,
  saveTokens,
  removeTokens,
} from '~/assets/helpers/token';
import {
  deleteFirebaseTokenAlreadySent,
} from '~/assets/helpers/notification';
import { ROUTES, isPathnamePublic } from '~/assets/helpers/routes';
import { PROFILE_INNER_STATE } from '~/assets/helpers/users';
import { authenticate, refreshTokens } from '~/api/tokens';
import {
  fetchPermissions,
  fetchProfile,
  updateProfile,
  changePassword,
  resetPassword,
  startShift,
  stopShift,
} from '~/api/users';
import { PROFILE } from '~/store/reducers/types';
import { showError, actions as usersNotification } from '~/store/reducers/notification';
import { actions as usersActions } from '~/store/reducers/users';

let refreshTokenTimerId = null;

export const initialState = {
  innerState: PROFILE_INNER_STATE.INITIAL,
  isAuthenticated: hasTokens(),
  data: {},
  permissions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE.MUTATE_INNER_STATE:
      return {
        ...state,
        innerState: action.payload,
      };
    case PROFILE.MUTATE_IS_AUTHED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case PROFILE.MUTATE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case PROFILE.MUTATE_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case PROFILE.UPDATE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case PROFILE.START_SHIFT:
      return {
        ...state,
        data: {
          ...state.data,
          isOnline: true,
        },
      };
    case PROFILE.STOP_SHIFT:
      return {
        ...state,
        data: {
          ...state.data,
          isOnline: false,
        },
      };
    default:
      return state;
  }
};

export const actions = {
  initializeSession: () => async (dispatch, getState) => {
    window.document.addEventListener('visibilitychange', () => {
      const { profile: { isAuthenticated } } = getState();
      if (window.document.hidden) {
        clearTimeout(refreshTokenTimerId);
      }
      else if (isAuthenticated) {
        actions.refreshTokens()(dispatch, getState);
      }
      else {
        actions.signOut(window.location.pathname)(dispatch, getState);
      }
    }, false);
    if (hasTokens() || !isPathnamePublic(window.location.pathname)) {
      const { status } = await actions.refreshTokens()(dispatch, getState);
      if (status === 'success') {
        await actions.fetchInitials()(dispatch, getState);
      }
    }
  },
  refreshTokens: ({ withInfoMessage = false } = {}) => async (dispatch, getState) => {
    const response = await refreshTokens();
    const { status, data: { accessToken, refreshToken, expiresIn } } = response;
    if (status === 'error' || !accessToken || !refreshToken) {
      const { profile: { isAuthenticated } } = getState();
      if (!isAuthenticated) {
        showError(response, dispatch);
      }
      actions.signOut(window.location.pathname)(dispatch, getState);
    }
    else {
      saveTokens({ accessToken, refreshToken });
      actions.autoTokensRefresh(expiresIn)(dispatch, getState);
      dispatch({
        type: PROFILE.MUTATE_IS_AUTHED,
        payload: true,
      });
      // const { document: { socket } } = getState();
      // if (socket) {
      //   socket.close();
      //   socket.open(accessToken);
      // }
      if (withInfoMessage) {
        usersNotification.showInfo('Session restored successfully!')(dispatch);
      }
    }
    return response;
  },
  autoTokensRefresh: (refreshInterval) => (dispatch, getState) => {
    clearTimeout(refreshTokenTimerId);
    refreshTokenTimerId = setTimeout(() => {
      actions.refreshTokens()(dispatch, getState);
    }, refreshInterval * 1000);
  },
  signIn: (authData) => async (dispatch, getState) => {
    const response = await authenticate({ authData });
    const { status, data: { accessToken, refreshToken, expiresIn } } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    if (accessToken && refreshToken) {
      saveTokens({ accessToken, refreshToken });
      actions.autoTokensRefresh(expiresIn)(dispatch, getState);
      await actions.fetchInitials()(dispatch, getState);
      dispatch({
        type: PROFILE.MUTATE_IS_AUTHED,
        payload: true,
      });
    }
    return response;
  },
  signOut: (redirectTo = '') => () => {
    clearTimeout(refreshTokenTimerId);
    removeTokens();
    deleteFirebaseTokenAlreadySent();
    if (isPathnamePublic(window.location.pathname)) {
      return;
    }
    const redirectQuery = window.encodeURIComponent(redirectTo);
    window.location.href = redirectTo && redirectTo !== ROUTES.signInPage
      ? `${ROUTES.signInPage}?redirect=${redirectQuery}`
      : ROUTES.signInPage;
  },
  fetchPermissions: () => async (dispatch) => {
    const response = await fetchPermissions();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: PROFILE.MUTATE_PERMISSIONS,
        payload: data,
      });
    }
    return response;
  },
  fetch: () => async (dispatch) => {
    const response = await fetchProfile();
    const { status, data } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: PROFILE.MUTATE_DATA,
        payload: data,
      });
    }
    return response;
  },
  fetchInitials: () => async (dispatch, getState) => {
    const { profile: { innerState } } = getState();
    dispatch({
      type: PROFILE.MUTATE_INNER_STATE,
      payload: innerState !== PROFILE_INNER_STATE.FETCHED
        ? PROFILE_INNER_STATE.FETCHING
        : innerState,
    });
    await Promise.all([
      actions.fetchPermissions()(dispatch, getState),
      actions.fetch()(dispatch, getState),
      usersActions.fetch()(dispatch, getState),
    ]);
    dispatch({
      type: PROFILE.MUTATE_INNER_STATE,
      payload: PROFILE_INNER_STATE.FETCHED,
    });
  },
  update: (profileData) => async (dispatch) => {
    const response = await updateProfile({ profileData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: PROFILE.UPDATE_DATA,
        payload: profileData,
      });
    }
    return response;
  },
  changePassword: (passwordsData) => async (dispatch) => {
    const response = await changePassword({ passwordsData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    return response;
  },
  resetPassword: (passwordData) => async (dispatch) => {
    const response = await resetPassword({ passwordData });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    return response;
  },
  startShift: () => async (dispatch, getState) => {
    const { profile: { data: { id: userId } } } = getState();
    const response = await startShift({
      userId,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: PROFILE.START_SHIFT,
      });
    }
    return response;
  },
  stopShift: () => async (dispatch, getState) => {
    const { profile: { data: { id: userId } } } = getState();
    const response = await stopShift({
      userId,
    });
    const { status } = response;
    if (status === 'error') {
      showError(response, dispatch);
    }
    else {
      dispatch({
        type: PROFILE.STOP_SHIFT,
      });
    }
    return response;
  },
};

import { ERROR_MESSAGE } from '~/app.config';
import {
  NOTIFICATION_TYPES,
  createNotification,
  getPushPermission,
} from '~/assets/helpers/notification';
import { NOTIFICATION } from '~/store/reducers/types';

export const initialState = {
  pushNotificationPermission: getPushPermission(),
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION.ADD: {
      return {
        ...state,
        list: state.list.concat(createNotification(action.payload)),
      };
    }
    case NOTIFICATION.DELETE:
      return {
        ...state,
        list: state.list.filter((item) => item.key !== action.payload),
      };
    case NOTIFICATION.SET_PUSH_PERMISSION:
      return {
        ...state,
        pushNotificationPermission: action.payload,
      };
    default:
      return state;
  }
};

export const actions = {
  add: (payload) => (dispatch) => {
    dispatch({
      type: NOTIFICATION.ADD,
      payload,
    });
  },
  delete: (payload) => (dispatch) => {
    dispatch({
      type: NOTIFICATION.DELETE,
      payload,
    });
  },
  setPushPermission: (payload) => (dispatch) => {
    dispatch({
      type: NOTIFICATION.SET_PUSH_PERMISSION,
      payload,
    });
  },
  showInfo: (message) => (dispatch) => {
    actions.add({
      type: NOTIFICATION_TYPES.INFO,
      showCloseButton: true,
      autoClose: true,
      title: null,
      text: message,
    })(dispatch);
  },
  showWarning: (message) => (dispatch) => {
    actions.add({
      type: NOTIFICATION_TYPES.WARNING,
      showCloseButton: true,
      autoClose: true,
      title: null,
      text: message,
    })(dispatch);
  },
  showError: (message) => (dispatch) => {
    actions.add({
      type: NOTIFICATION_TYPES.ERROR,
      showCloseButton: true,
      autoClose: true,
      title: null,
      text: message,
    })(dispatch);
  },
};

export function showError(response, dispatch) {
  if (typeof dispatch !== 'function') {
    return;
  }
  const {
    code = 520,
    errorsTitle = 'Network request',
    errors = ERROR_MESSAGE,
  } = response || {};
  let title = errorsTitle;
  let text = errors;
  switch (code) {
    case 403:
      title = title || 'Access denied';
      text = 'Session has expired. We trying to restore...';
      break;
    case 412:
      text = errors || 'Something went wrong, please try later';
      break;
    case 422:
      text = errors || 'Something went wrong, please connect with support';
      break;
    case 500:
      text = errors || 'Internal server error';
      break;
    case 502:
    case 503:
    case 504:
      text = errors || 'Server or network is unavailable';
      break;
    case 304:
    case 520:
      text = errors || 'Possible reason: network is unavailable; misconfigured CORS on the server; browser extensions are blocking the request; there is a bug inside request code';
      break;
    default:
      break;
  }
  actions.add({
    type: NOTIFICATION_TYPES.ERROR,
    showCloseButton: true,
    autoClose: true,
    title,
    text,
  })(dispatch);
}

/* eslint-disable import/no-cycle, no-shadow, no-restricted-syntax */

/**
 * @param {Node} root
 * @returns {Iterable<string>}
 */
const traverseTextNodes = function* (root) {
  const stack = [root];

  while (stack.length > 0) {
    const node = stack.shift();

    if (node.nodeType === Node.TEXT_NODE) {
      // If the current node is a text node, add it to the array
      yield node.nodeValue;
    }
    else if (node.nodeType === Node.ELEMENT_NODE) {
      const nodeIsListItem = ['li', 'ol'].includes(node.tagName);

      if (nodeIsListItem) yield '\n';

      for (const childNode of node.childNodes) stack.push(childNode);
    }
  }
};

export class TextCounter {
  /**
     * @param {string|null} text
     * @return {TextCounter}
     */
  static count(text) {
    let words = 0;
    let chars = 0;

    const parser = new DOMParser();
    const root = parser.parseFromString(text || '', 'text/html');

    for (const text of traverseTextNodes(root.body)) {
      words += text.split(/\s+/).length;
      chars += text.length;
    }

    return new this(words, chars);
  }

    /**
     * @type {number}
     * @private
     * @readonly
     */
    _words;

    /**
     * @type {number}
     * @private
     * @readonly
     */
    _chars;

    constructor(words, chars) {
      this._words = words;
      this._chars = chars;
    }

    /**
     * @return {boolean}
     */
    isEmpty() {
      return this._words === 0 && this._chars === 0 && this._sequences === 0;
    }

    /**
     * @return {number}
     */
    getWords() {
      return this._words;
    }

    /**
     * @return {number}
     */
    getChars() {
      return this._chars;
    }
}

import React from 'react';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useIsAuthed } from '~/assets/hooks/useProfile';
import { ROUTES } from '~/assets/helpers/routes';
import { IconMenuHamburger } from '~/components/Icons';
import s from './styles.scss';

MenuTop.propTypes = {
  className: propTypes.string,
  opened: propTypes.bool,
  onToggle: propTypes.func,
};

MenuTop.defaultProps = {
  className: '',
  opened: false,
  onToggle: null,
};

function MenuTop(props) {
  const {
    className,
    opened,
    onToggle,
  } = props;
  const isAuthed = useIsAuthed();

  return (
    <div className={cn(s.menuMobile, opened && s.opened, className)}>
      <div className={s.wrapper}>
        {isAuthed
        && <button
          className={cn('btn', s.toggleBtn)}
          type="button"
          onClick={onToggle}
        >
          <span className="btn__wrp">
            <IconMenuHamburger className={cn('btn__icon', s.toggleIcon)} />
          </span>
        </button>}
        <NavLink
          className={cn('btn', s.logoLink, !isAuthed && s.logoDisabled)}
          to={ROUTES.dashboard}
          tabIndex={!isAuthed ? '-1' : '0'}
        >
          <span className="btn__wrp">
            <span className="btn__label">
              Editorial.Space
            </span>
          </span>
        </NavLink>
      </div>
    </div>
  );
}

export default MenuTop;
